import React, { useEffect, useRef, useState } from "react";
import "./ModifyPlan.scss";
// import { CgClose } from "react-icons/cg";
// import { FaTrash, FaPlus } from "react-icons/fa"; // Import FaTrash for delete button
import { getExamPlanDetails, updateExamPlanDetails } from "../../../../../../APIServices/QP/ExamPlans";
import { Switch } from 'antd';
import { Button } from "../../../../../../../components/Generic/Button/Button";
import TextInput from "../../../../../../../components/Generic/TextInput/TextInput";
import ObjectsDropdown from "../../../../../../../components/Generic/ObjectsDropdown/ObjectsDropdown";
import { addTopic, getTopicById, updateTopic } from "../../../../../../APIServices/QP/Topics";
import { getSubjects } from "../../../../../../APIServices/QP/Subjects";
import { toast } from "sonner";

function ModifyTopic(props: {
  examId: any, 
  subject_id: any, 
  topic_id: any,
  setShowModifyPopup: any,
}) {



  const form = {
    subject_id: null,
    topic_name: "",
    topic_image: "",
    is_unlocked: false
  }

  interface Item {
    id: number;      // or string, depending on your needs
    option: string;    // or any other property you want
  }


  const [formData, setFormData] = useState<any>(form);

  const [subjects, setSubjects] = useState([]);

  const [items, setItems] = useState<Item[]>([]);

  

  const [invalidFields, setInvalidFields] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);

  const validateFields = React.useCallback(async (valid: boolean, field: string) => {
    if (valid) {
      if (invalidFields.includes(field))
        setInvalidFields(Fields => {
          let filtered = Fields.filter(value => value !== field);
          return filtered;
        });
    }
    else {
      if (!invalidFields.includes(field))
        setInvalidFields(Fields => {
          Fields.push(field);
          return Fields;
        });
    }
  }, []);



  const saveChanges = () => {

    if (invalidFields.length) {
      toast.warning('Please fill all the required fields!');
      setSubmitClicked(true);
      return;
  }
    const payload = {
      topic_name: formData.topic_name,
      topic_image: "https://www.freepik.com/free-vector/classic-variety-colorful-molecules_1312422.htm#fromView=search&page=1&position=0&uuid=1bd513df-3e4b-42df-a61e-c1d9e8f607b9",
      is_unlocked: false
    }
  
    updateTopic(formData.subject_id, props.topic_id, payload).then(res=>{
      console.log(res)
      props.setShowModifyPopup()
    })
  }


  useEffect(() => {
    getSubjects(props.examId, null).then(resp => resp.results).then(result=>{
      setSubjects(result)
    })

    getTopicById(props.subject_id, props.topic_id).then(res=>{
      setFormData(res)
    })


  }, [])


  useEffect(() => {
    const items: Item[] = subjects?.map(subject => ({
      id: subject.id,
      option: subject.title
    }));
    setItems(items)
  }, [subjects])



  return (
    <div className="add-plan-container">
      <div className="heading">
        <h3>Update Topic</h3>
        <div className="close" onClick={() => props.setShowModifyPopup()} > X </div>
      </div>
          
      <div className="add-camera-form-row-2">
          <ObjectsDropdown
            label={'Select exam'}
            placeholder={'Select Exam'}
            width={'80%'}
            options={items}
            onSelect={(selection) => setFormData({...formData, subject_id: selection})}
            selection={formData.subject_id}
            // isRequired={true}
            // isValid={validateFields}
            // showWarning={submitClicked}
          />
      </div>

   

      <div className="add-camera-form-row-2">
        <TextInput
              label={'Topic '}
              placeholder={'Enter topic title'}
              width={'90%'}
              onChange={(value) => setFormData({ ...formData, topic_name: value })}
              value={formData.topic_name}
              isRequired={true}
              isValid={validateFields}
              showWarning={submitClicked}
            />
      </div>

      <div className="add-camera-form-row-3">
          <Button
                class='btn-container'
                bgcolor={'#02720d'}
                text={'Update Topic'}
                textcolor={'white'}
                width={''}
                fontSize={'1rem'}
                onClick={() => {
                    saveChanges()
                }}
          
          ></Button>
      </div>

    
     
    </div>
  );
}

export default ModifyTopic;
