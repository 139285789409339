
// @ts-nocheck

import * as Congs from "../Configurations"

export function getDashboardStats() {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    //   headers: Congs.getHeaders()
  
    };
  
    return fetch(`https://bytecoder.in/back-end/api/v2/admin/dashboard-stats`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.error(error));
  }
  
  
  