import * as React from 'react';
import { useState } from 'react';
import { Button } from '../../../../components/Generic/Button/Button';
import Dropdown, { DropDownVariant } from '../../../../components/Generic/Dropdown/Dropdown';
import TextInput from '../../../../components/Generic/TextInput/TextInput';
import './add-camera.scss';
import { toast } from 'sonner';
import { useContext } from 'react';
import { GlobalStateContext } from '../../../../GlobalContext/GlobalContextProvider';
import { LatLong, Resolution } from '../../../../components/Generic/LatLong/LatLong';
import { ROUTES } from '../../../../components/Routes/SideBarRoutes';
import { useNavigate } from 'react-router-dom';
import { algo_settings, algo_settings_columns } from '../../../../Constants/Camera';
import BasicTimePicker, { BasicTimePickerMMSS } from '../../../../components/Generic/TimePicker/TimerPicker';
import { Switch } from 'antd';
import { addExam } from '../../../APIServices/QP/Exams';
import { FileUpload, UploadType } from '../../../../components/Generic/FileUpload/FileUpload';

export interface IAddCameraProps {
  moveToListPage: () => void;
}

export const IpV4RegEx = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const IpV6RegEx = /((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}/;

export const AddQPExam = (props: IAddCameraProps) => {
  const { globalState: { user, gates } } = useContext(GlobalStateContext);
  const [META_DATA, setMETA_DATA] = useState(user?.application_configs);
  const navigate = useNavigate();
  const [showSettings, setShowSettings] = useState(false);

  const [formData, setFormData] = useState({  
      title: "",
      code: "",
      sub_heading: "",
      image_url: "",
      icon_url: "",
      is_published: false
    
  });

  const [settingsFormData, setSettingsFormData] = useState({

  });

  const [settingsSwitchData, setSettingsSwitchData] = useState({
    virtual_fence: false,
    is_enabled: false
  });

  const [invalidFields, setInvalidFields] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);



  const validateFields = React.useCallback(async (valid: boolean, field: string) => {
    if (valid) {
      if (invalidFields.includes(field))
        setInvalidFields(Fields => {
          let filtered = Fields.filter(value => value !== field);
          return filtered;
        });
    }
    else {
      if (!invalidFields.includes(field))
        setInvalidFields(Fields => {
          Fields.push(field);
          return Fields;
        });
    }
  }, []);

  const handleClick = async () => {
    try {
      if (invalidFields.length) {
        toast.warning('Please fill all the required fields!');
        setSubmitClicked(true);
        return;
      }

  
      const payload = {
        ...formData 
           };

      const response = await addExam(payload);
          
      if (response && response.status ==200) {
        props.moveToListPage();
        toast.success("Success: Exam added!");
        navigate(ROUTES.qp);
      }
      else throw new Error("Error");
    }
    catch (err) {
      toast.error("Error: failed to add camera!");
    }
  }





  return (
    <div className={'add-camera-form-container'}>
      <div className={'title-left'}>
        Add New Exam
      </div>
        <div>
          <div className={'add-camera-form-row-1'}>
            <TextInput
              label={'Exam Name'}
              placeholder={'Enter Exam Title'}
              width={'30%'}
              onChange={(value) => setFormData({ ...formData, title: value })}
              value={formData.title}
              isRequired={true}
              isValid={validateFields}
              showWarning={submitClicked}
            />

            <TextInput
              label={'Code'}
              placeholder={'Enter camera specs'}
              width={'30%'}
              onChange={(value) => setFormData({ ...formData, code: value })}
              value={formData.code}
              isRequired={true}
              isValid={validateFields}
              showWarning={submitClicked}
            />


            <TextInput
              label={'Sub-Heading'}
              placeholder={'Enter Sub-Heading'}
              width={'30%'}
              onChange={(value) => setFormData({ ...formData, sub_heading: value })}
              value={formData.sub_heading}
              isRequired={true}
              isValid={validateFields}
              showWarning={submitClicked}
            />

          </div>
          <div className={'add-camera-form-row-1'}>
        

   
          <FileUpload
                label={'Upload Image'}
                width={'30rem'}
                accept={UploadType.file}
                isRequired={true}
                onUpload={(url) => setFormData({...formData, image_url:url})}
                url={formData.image_url}
                isValid={validateFields}
                showWarning={submitClicked}
            />

            <FileUpload
                label={'Upload Icon'}
                width={'30rem'}
                accept={UploadType.file}
                isRequired={true}
                onUpload={(url) => setFormData({...formData, icon_url:url})}
                url={formData.icon_url}
                isValid={validateFields}
                showWarning={submitClicked}
            />
        
          
          </div>
         


          <div className={'btn-position'}>
            <Button
              class='btn-container'
              bgcolor={'#4D4D33'}
              text={'Save'}
              textcolor={'white'}
              width={''}
              onClick={() => {
                if (invalidFields.length) {
                  toast.warning('Please fill all the required fields!');
                  setSubmitClicked(true);
                  return;
                }
                else
                  handleClick()
                  setShowSettings(true)
              }}
            />
          </div>
        </div>
      
      

    </div>
  );
};
