import * as Congs from "../Configurations"



export function addSubject(exam_id, payload) {
    const requestOptions = {
      method: "POST",
      redirect: "follow",
      body: JSON.stringify(payload),
      headers: Congs.getHeaders()
  
    };
  
    
    return fetch(`${Congs.BASE_URL}/api/v2/exams/${exam_id}/subjects`, requestOptions)
      .then((response) => response)
      .catch((error) => console.error(error));
  }
  


export function updateSubject(exam_id, subject_id, payload) {
  const requestOptions = {
    method: "PUT",
    redirect: "follow",
    body: JSON.stringify(payload),
    headers: Congs.getHeaders()

  };

  
  return fetch(`${Congs.BASE_URL}/api/v2/exams/${exam_id}/subjects/${subject_id}`, requestOptions)
    .then((response) => response)
    .catch((error) => console.error(error));
}



  export function getSubjects(exam_id, fetchOptions) {

    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: Congs.getHeaders()
  
    };
    const url =`${Congs.BASE_URL}/api/v2/exams/${exam_id}/subjects?page=1`
  const fetchUrl = Congs.prepareUrl(url, fetchOptions);
   
    return fetch(fetchUrl, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.error(error));
  
  }
  
   
  export function getExamSubjectById(exam_id,subject_id) {

    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: Congs.getHeaders()
  
    };
  
   
    return fetch(`${Congs.BASE_URL}/api/v2/exams/${exam_id}/subjects/${subject_id}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.error(error));
  
  }
  
  
