import * as React from 'react';
import { SearchBar } from '../../../components/Generic/SearchBar/SearchBar';
import { GenericTable } from '../../../components/Generic/Table';
import { ProfileDetailsSideCard } from './ProfileDetails/ProfileDetailsSideCard';
import './visitors-list.scss';
import { ROUTES } from '../../../components/Routes/SideBarRoutes';
import { useEffect } from 'react';
import { Filters } from '../../../components/Generic/Filters/Filters';
import { Button } from '../../../components/Generic/Button/Button';
import autoTable from 'jspdf-autotable';
import { jsPDF } from "jspdf";
import { useRef } from 'react';
import { useState } from 'react';
import { GlobalStateContext } from '../../../GlobalContext/GlobalContextProvider';
import { useNavigate } from 'react-router-dom';
import { getCandidates } from '../../APIServices/UsersApi';



export const CandidatesList = () => {

  const outRef = useRef(null);
  const [openPop, setOpenPop] = useState(false);
  const { globalState: { user, permissions } } = React.useContext(GlobalStateContext);

  const [isAutoPullEnabled, setIsAutoPullEnabled] = useState(true);
  
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(10);

  const [filters, setFilters] = useState(null);
  const [query, setQuery] = useState(null);


  const handlePageChange = (newPage) => {
    setPage(newPage);
    console.log("newPage")
    console.log(newPage)

  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    console.log("newLimit")
    console.log(newLimit)
  };


  const [total_visitors, setTotalVisitors] = useState(0)
  const navigate = useNavigate()
  const [pageVariables, setPageVariables] = useState({
    selectedProfile: null,
    tableData: [],
    loading: false
  });

  const tableDemoData = {
    columnDetails: [
      {
        title: 'Full Name',
        key: 'fullname'
      },
      {
        title: 'Phone Number',
        key: 'phone'
      },
      {
        title: 'Email Id',
        key: 'email'
      },
      {
        title: 'Preparing For',
        key: 'exam_preference'
      },
      {
        title: 'Action',
        key: "View_details",
        route: ROUTES.candidateDetails,
        route_key: 'id'
      },
    ]
  }
  


  const fetchVisitorsList = async (f, p, q) => {
    let options = {
    }
    if (filters) {
      options = {
        ...options,
        start_date: filters.start_date,
        end_date: filters.end_date,
        unit: filters.unit,
        category: filters.category,
      }
    }

    options = {
      ...options,
      page: page,
      limit: limit,
      query: query
    }

    setPageVariables({ ...pageVariables, loading: true });
    try {
      const response = await getCandidates(options);
 
      
      let dataRows = [];
      setTotalItems(response.result.total_results)
      response.result.results.forEach(data => {
        const { id, name, phone, role, email, exam_preference} = data;
        console.log(data)
        
        dataRows = [...dataRows,
        {
          "id": id,
          "fullname": name,
          "phone": phone,
          "role": role,
          "exam_preference": exam_preference,
          "email": email,
          "View_details": 'View Details',

        }
        ];

      })
      
      setPageVariables({ ...pageVariables, tableData: dataRows, loading: false });
    }
    catch (err) {
      setPageVariables({ ...pageVariables, loading: false });
    }
  }


  useEffect(()=>{
    fetchVisitorsList(null, 1, null)
  },[])

  useEffect(() => {
    fetchVisitorsList(page, filters, query);
  }, [page, limit, filters, query]);

  return (
    <div className={'visitors-list-container'} >
      <div className={'visitors-list-header'}>
        <div className={'visitors-list-header-left'}>
          <div className={'title'}>
            Candidates Profiles
          </div>
          {/* <div className={'title'}>
            <span style={{ margin: '0 1em' }}>

              Total Registered : {pageVariables.tableData.length}
            </span>
          </div> */}

        </div>
        <div style={{ display: 'flex', marginRight: '2rem' }}>
          <div>
            <SearchBar
              placeholder={'Search candidate by name or phone number'}
              width={'20rem'}
              onSearch={query => setQuery(query)}
            />
          </div>
          {/* <Filters
            unitFilter={false}
            categoryFilter={true}
            gateFilter={false}
            dateFilter={false}
            apply={(filters) => setFilters(filters)}
          /> */}
        </div>
      </div>
   
      <GenericTable

        columnDetails={tableDemoData.columnDetails}
        dataRows={pageVariables.tableData}
        selectedRow={(value) => {
          setPageVariables(
            {
              ...pageVariables,
              selectedProfile: value,
            });
        }
        }
        loading={pageVariables.loading}
        id="visitors-list-table"
        pagination={true}
        currentPage={page}
        totalItems={totalItems}
        initialLimit={limit}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />

      {/* <div ref={outRef}>
        <ProfileDetailsSideCard
          open={openPop}
          close={() => setOpenPop(false)}
          profile={pageVariables.selectedProfile}
          makeEntry={() => console.log("")}
          makeExit={() => console.log("dd")}
        />
      </div> */}
    </div>
  );
}