import React from "react"
import { Link, useParams } from "react-router-dom"
import "./SubjectCard.scss"
import { Button } from "../../../../../../../components/Generic/Button/Button";
import { toast } from "sonner";
import { MdEdit } from "react-icons/md";
export function SubjectPaperCard({ subject , selectSubject}) {
    const { examId } = useParams();
    return (
        <div>
                <div
                    className="subject-card"
                    // key={index}
                >

                    <div className="subject-image">
                        <img src={subject.image_url}  alt='https://img.freepik.com/free-vector/gradient-english-school-illustration_23-2149477711.jpg?size=338&ext=jpg&ga=GA1.1.2116175301.1718582400&semt=ais_user' />
                    </div>
                    <div>
                        <h3>{subject.title}</h3> 
                        <p>
                            {subject.code}
                        </p>
                        <MdEdit onClick={()=>selectSubject(subject.id)}/>

                    </div>
                  
                </div>
        </div>
    )
}