import React, {useEffect, useState} from 'react'
import { getExams, getExamStats } from '../../../../../APIServices/QP/Exams';
import { useParams } from 'react-router-dom';

function SubscriptionInfo() {
  const { exam_id } = useParams();

  const [exams, setExams] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState([
    {
      "id": 6,
      "subscriptionCardName": "Free Plan",
      "subscriptionDescription": "Subscribers",
      "totalCount": 0,
      "subscriptionCardlogo": "https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/QP/assets/free.svg"
  },
  {
      "id": 1,
      "subscriptionCardName": "Premium Plan",
      "subscriptionDescription": "Subscribers",
      "totalCount": 0,
      "subscriptionCardlogo": "http://example.com/image.jpg"
  },
  {
      "id": 2,
      "subscriptionCardName": "Spring Plan",
      "subscriptionDescription": "Subscribers",
      "totalCount": 0,
      "subscriptionCardlogo": "https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/QP/assets/sprint.svg"
  }

  ]);


  useEffect(()=>{
    let options = {
      page: 1,
      limit: 100,
      query: ''
    }
    getExams(options).then(result=> setExams(result['results']))
  },[])

  useEffect(()=>{
    if(exam_id != null)
      getExamStats(exam_id).then(result=> setSubscriptionData(result)).catch(e=>{
        setSubscriptionData([])
      })

  }, [exam_id])
  
  return (
    <div>
     
      <div className="subscription-card-container">
        {subscriptionData.map((cardData: any) => {
          return (
            <div className="cards" key={cardData.id}>
              <div className="highlighter"></div>
              <div>
                <div className="title">
                  <h2>{cardData.subscriptionCardName}</h2>
                  <p>{cardData.subscriptionDescription}</p>
                </div>

                <div className="count">
                  <h1>{cardData.totalCount}</h1>
                </div>
              </div>
              <div>
                <img src={cardData.subscriptionCardlogo} alt="" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SubscriptionInfo