import * as React from 'react';
import { SearchBar } from '../../../../components/Generic/SearchBar/SearchBar';
import { GenericTable } from '../../../../components/Generic/Table';
import { Button } from '../../../../components/Generic/Button/Button';
import './camera-list.scss';
import { useEffect } from 'react';
import { useState } from 'react';
import { CameraDetails } from './CameraDetailsSidePop/CameraDetailsPopUp';
import { toast } from 'sonner';
import { Filters } from '../../../../components/Generic/Filters/Filters';
import { ROUTES } from '../../../../components/Routes/SideBarRoutes';
import { getExams } from '../../../APIServices/QP/Exams';
import { deleteCamera } from '../../../../GPI/API/Camera';

export interface IExamListProps {
}

export const ExamsList = (props: IExamListProps) => {
  const [pageVars, setPageVars] = useState({
    tableData: [],
    selectedCamera: null,
    openPopUp: false,
    loading: false
  });

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(10);
  const [filters, setFilters] = useState({});
  const [query, setQuery] = useState(null);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    console.log("newPage")
    console.log(newPage)

  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    console.log("newLimit")
    console.log(newLimit)
  };

  const tableDemoData = {
    columnDetails: [
      {
        title: 'Name',
        key: 'name'
      },
      {
        title: 'Code',
        key: 'code'
      },
      {
        title: 'Published',
        key: 'is_published'
      },
      {
        title: 'Action',
        key: "View_details",
        route: ROUTES.qpDetails,
        route_key: 'id'
      },
    ]
  }

  const fetchCameras = async (p, filters, q) => {
    try {
      setPageVars({ ...pageVars, loading: true, openPopUp: false });
      let options = {
      }

      if (filters) {
        options = {
          ...options,
          start_date: filters.start_date,
          end_date: filters.end_date,
          unit: filters.unit,
          category: filters.category,
          gate_number: filters.gate_number
        }
      }

      options = {
        ...options,
        page: page,
        limit: limit,
        query: query
      }

      const response = await getExams(options)

      console.log(response)
      setTotalItems(response.result.total_results)
      
      let dataRows = [];
      response.result.results.forEach(data => {
        const {id, title, code , is_published} = data;
        dataRows = [...dataRows,
        {
          "id": id,
          "name": title,
          "code": code,
          "is_published": is_published ? "True" : "False",
          "View_details": 'View Details',
        }];
      });
      console.log("tableDemoData")
      console.log(tableDemoData)
      setPageVars({ ...pageVars, tableData: dataRows, loading: false, openPopUp: false });
    }
    catch (err) {
      setPageVars({ ...pageVars, loading: false, openPopUp: false });
    }
  }

  const handleDelete = async (camera_id) => {
    try {
      const response = await deleteCamera(camera_id);
      if (response && response.statusCode === 200) {
        toast.success("Success: camera deleted!");
        fetchCameras(page, filters, query);
      }
      else throw new Error(response.error);

    }
    catch (err) {
      toast.error('Error: failed to delete camera!');
    }
  }
  useEffect(() => {
    fetchCameras(page, filters, query);
  }, []);

  useEffect(() => {
    fetchCameras(page, filters, query);
  }, [page, limit, filters, query]);



  return (
    <div className={'camera-list-container'}>
      <div className={'visitors-list-header'}>
        <div className={'visitors-list-header-left'}>
          <div className={'title'}>
            Exams List
          </div>
          <div style={{ display: 'flex', marginRight: '2rem' }}>
            <div>
              <SearchBar
                placeholder={'Search exam by name'}
                width={'20rem'}
                onSearch={query => setQuery(query)}
              />
            </div>
            {/* <Filters
            unitFilter={false}
            categoryFilter={false}
            gateFilter={true}
            dateFilter={true}
            apply={(filters)=>fetchCameras(1, filters)}
          /> */}
          </div>
       
        </div>
       
      </div>
      <GenericTable
        columnDetails={tableDemoData.columnDetails}
        dataRows={pageVars.tableData}
        selectedRow={(row) => setPageVars({ ...pageVars, selectedCamera: row, openPopUp: true })}
        loading={pageVars.loading}
        currentPage={page}
        totalItems={totalItems}
        initialLimit={limit}
        pagination={true}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />

      {/* {pageVars.selectedCamera &&
        <CameraDetails
          camera={pageVars.selectedCamera}
          open={pageVars.openPopUp}
          close={() => setPageVars({ ...pageVars, openPopUp: false })}
          onDelete={handleDelete}
        />} */}
    </div>
  );
}
