import * as React from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../../components/Generic/Button/Button';
import { ROUTES } from '../../../../components/Routes/SideBarRoutes';
import './camera-details.scss';
import { toast } from 'sonner';
import { Preview } from '../../../../components/Generic/ImagePreviewer/Preview';
import DetailsTabs from '../../../../components/Generic/DetailsTab/DetailsTab';
import QPExamDetailsTabs from './QPExamDetailsTab/QPExamDetailsManagement';
import { getExamsById } from '../../../APIServices/QP/Exams';
import { disableVisitor } from '../../../../GPI/API/Visitors';

export interface IVisitorDetailsProps {
}

export function QPExamDetails(props: IVisitorDetailsProps) {
  const navigate = useNavigate();
  const { exam_id } = useParams();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(10);

  const handlePageChange = (newPage) => {
    setPage(newPage);

  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };



  const [pageVars, setPageVars] = useState({
    id: null,
    title: null,
    code: null ,
    sub_heading: null,
    image_url: null,
    icon_url: null,
    is_published: null,
    openPopUp: false,
    openBlockPopUp: false,
  });
  const [tableVars, setTableVars] = useState({
    tableData: [],
    totalIn: 0,
    totalOut: 0,
    loading: false
  });
  const [loaders, setLoaders] = useState({
    updatingVisitors: false
  });


  const [view, setView] = React.useState({
    acknowledgement: null,
    showImage: false
  })

  const [viewIdCard, setViewIdCard] = React.useState({
    acknowledgement: null,
    showImage: false
  })

  const navigateBack = () => {
    navigate(ROUTES.qp)
  }

  const getVisitorDetails = async () => {
    try {
      const response = await getExamsById(exam_id)


      if (response ) {
        const {
          id,
          title,
          code,
          sub_heading,
          image_url,
          icon_url,
          is_published
        } = response;
        setPageVars({
          ...pageVars,
          id,
          title,
          code,
          sub_heading,
          image_url,
          icon_url,
          is_published,
          openBlockPopUp: false,
        });
      }
      else throw new Error("Error");

    }
    catch (err) {

    }
  }

  const blockVisitor = async () => {
    let is_active = pageVars.is_published;
    try {
      setLoaders({ ...loaders, updatingVisitors: true });
      const response = await disableVisitor(exam_id, pageVars.is_published);
      if (response && response.statusCode === 200) {
        toast.success(`Success: visitor ${is_active ? 'blocked' : 'unblocked'}!`)
        setLoaders({ ...loaders, updatingVisitors: false });
        setPageVars({ ...pageVars, openBlockPopUp: false });
        getVisitorDetails();
      }
      else throw new Error("Error");
    }
    catch (err) {
      setLoaders({ ...loaders, updatingVisitors: false });
      toast.success(`Error: failed to  ${is_active ? 'blocked' : 'unblocked'} visitor!`)
    }
  }



  React.useEffect(() => {
    getVisitorDetails();
    setTableVars({ ...tableVars, loading: true });
  }, []);




  return (
    <div>
      <div className={'visitor-details-container'}>
        <div className={'visitor-details-left-panel'}>
   
          <div className={'visitor-details-left-panel-back-btn'}>
            <Button
              class='medium-btn-container'
              text={'< Back to Exams list'}
              textcolor={'dark-green'}
              bgcolor={'#09784433'}
              width={'fit-content'}
              onClick={navigateBack}
            />
          </div>
          <div className={'profile-image-name-section'}>
            <div
              className={'profile-image'}
            >
              <img style={{ width: '10rem', height: '10rem', objectFit: 'cover', borderRadius: '1rem' }}
                onClick={() => {
                  setView({ ...view, showImage: true })
                }}
                src={pageVars.image_url}

              />
            </div>

            <div>

              <div className={'visitor-name'}>
                {pageVars.title}
              </div>
              <div className={'visitor-status'}>
                <span className={pageVars.is_published ? 'status-publish' : 'status-blocked'}> {pageVars.is_published ? 'Published' : 'Not Published'} </span>
              </div>
            </div>
          </div>
          <div className={'pop-up-file'}>
            <div className={'pop-up-file-title'}>
              Exam Icon
            </div>

            <div>
              {pageVars.icon_url ?
                <div className={'pop-up-file-container'}>
                  <div className={'pop-up-file-details'}>
                    <div className={'pop-up-file-img'}>
                      <img
                        src={pageVars.icon_url }
                        alt={'pdf.svg'}
                      />
                    </div>

                  </div>
                  <div className={'pop-up-file-name-download'}>
                    <a href={'#'}
                      onClick={() => {
                        setViewIdCard({ ...viewIdCard, showImage: true })
                      }}
                      style={{ textDecoration: 'none' }}>
                      View Icon
                    </a>
                  </div>
                </div> : 'N/A'}
            </div>

          </div>

          <div className={'personal-details-section'}>
            <div className={'personal-details-section-header'}>
              <div>Exam Details</div>
              <div className={'personal-details-section-header-btns'}>
                <Button
                  class='medium-btn-container'
                  text={'Edit'}
                  textcolor={'white'}
                  bgcolor={'#4D4D33'}
                  width={'fil-content'}
                  onClick={() => navigate(ROUTES.updateQPExam + '/' + exam_id)}
                />
              </div>
            </div>

            <div className='personal-details'>
              <div className='personal-details-div'>
                <span>Title</span> : <span>{pageVars.title}</span>
              </div>
              <div className='personal-details-div'>
                <span>Sub-Heading</span> : <span>{pageVars.sub_heading}</span>
              </div>
              <div className='personal-details-div'>
                <span>Code</span> : <span>{pageVars.code}</span>
              </div>
              <div className='personal-details-div'>
                <span>Published</span> : <span>{pageVars.is_published ? "True" : "False"}</span>
              </div>

        


            </div>
          </div>


          <div>

          </div>

        </div>
        <div className={'overview-container'}>
        <QPExamDetailsTabs />
       



          {/* <Preview
            previewImage={`${pageVars?.image_url}`}
            isOpen={view.showImage}
            onClose={() => setView({ ...view, showImage: false })}
          />
          <Preview
            previewImage={`${pageVars?.icon_url}`}
            isOpen={viewIdCard.showImage}
            onClose={() => setViewIdCard({ ...view, showImage: false })}
          /> */}

        </div>


      </div>

    </div>
  );
}
