import * as React from 'react';
import { useState } from 'react';
import { Button } from '../../../../components/Generic/Button/Button';
import TextInput from '../../../../components/Generic/TextInput/TextInput';
import './edit-camera.scss';
import { toast } from 'sonner';
import { useContext } from 'react';
import { GlobalStateContext } from '../../../../GlobalContext/GlobalContextProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../../components/Routes/SideBarRoutes';
import { getExamsById, updateExam } from '../../../APIServices/QP/Exams';
import { Switch } from 'antd';
import { FileUpload, UploadType } from '../../../../components/Generic/FileUpload/FileUpload';


export const IpV4RegEx = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const IpV6RegEx = /((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}/;

export const EditQPExam = () => {
  const { globalState: { user, gates } } = useContext(GlobalStateContext);
  const [META_DATA, setMETA_DATA] = useState(user?.application_configs);
  const navigate = useNavigate();
  const [showSettings, setShowSettings] = useState(false);
  const { exam_id } = useParams();

  const [formData, setFormData] = useState({
    id: exam_id,
    title: "",
    code: "",
    sub_heading: "",
    image_url: "",
    icon_url: "",
    is_published: null

  });



  const [invalidFields, setInvalidFields] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);



  const validateFields = React.useCallback(async (valid: boolean, field: string) => {
    if (valid) {
      if (invalidFields.includes(field))
        setInvalidFields(Fields => {
          let filtered = Fields.filter(value => value !== field);
          return filtered;
        });
    }
    else {
      if (!invalidFields.includes(field))
        setInvalidFields(Fields => {
          Fields.push(field);
          return Fields;
        });
    }
  }, []);

  const handleClick = async () => {
    console.log(invalidFields)
    try {
      if (invalidFields.length) {
        toast.warning('Please fill all the required fields!');
        setSubmitClicked(true);
        return;
      }


      const payload = {
        ...formData
      };

      const response = await updateExam(exam_id, payload);

      if (response && response.status == 200) {
        toast.success("Success: Exam updated!");
        navigate(ROUTES.qpDetails + `/${exam_id}`);
      }
      else throw new Error("Error");
    }
    catch (err) {
      toast.error("Error: failed to add camera!");
    }
  }

  const getVisitorDetails = async () => {
    try {
      const response = await getExamsById(exam_id)


      if (response) {
        const {
          id,
          title,
          code,
          sub_heading,
          image_url,
          icon_url,
          is_published
        } = response;
        setFormData({
          ...formData,
          id,
          title,
          code,
          sub_heading,
          image_url,
          icon_url,
          is_published,
        });
      }
      else throw new Error("Error");

    }
    catch (err) {

    }
  }

  React.useEffect(() => {
    getVisitorDetails();
  }, []);

  return (
    <div className={'edit-camera-form-container'}>
      <div className={'add-camera-form-title'}>
        Edit Exam
      </div>
      <div>
        <div className={'add-camera-form-row-1'}>
          <TextInput
            label={'Exam Name'}
            placeholder={'Enter Exam Title'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, title: value })}
            value={formData.title}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          />

          <TextInput
            label={'Code'}
            placeholder={'Enter camera specs'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, code: value })}
            value={formData.code}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          />


          <TextInput
            label={'Sub-Heading'}
            placeholder={'Enter Sub-Heading'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, sub_heading: value })}
            value={formData.sub_heading}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          />

        </div>
        <div className={'add-camera-form-row-2'}>

          <FileUpload
                label={'Upload Image'}
                width={'30rem'}
                accept={UploadType.file}
                isRequired={false}
                onUpload={(url) => setFormData({...formData, image_url:url})}
                url={formData.image_url}
                isValid={validateFields}
                showWarning={submitClicked}
            />

            <FileUpload
                label={'Upload Icon'}
                width={'30rem'}
                accept={UploadType.file}
                isRequired={false}
                onUpload={(url) => setFormData({...formData, icon_url:url})}
                url={formData.icon_url}
                isValid={validateFields}
                showWarning={submitClicked}
            />
        </div>

        <div className={'add-camera-form-row-3'}>

          <div>
            <Switch
              style={{ margin: '1rem' }}
              value={formData.is_published}
              onChange={(value) => {
                setFormData({
                  ...formData,
                  is_published: value
                });
              }}
            />
            Publish
          </div>

        </div>



        <div className={'btn-position'}>
          <Button
            class='btn-container'
            bgcolor={'#4D4D33'}
            text={'Save'}
            textcolor={'white'}
            width={''}
            onClick={() => {
              console.log(invalidFields)
              if (invalidFields.length) {
                toast.warning('Please fill all the required fields!');
                setSubmitClicked(true);
                return;
              }
              else
                handleClick()
              setShowSettings(true)
            }}
          />
        </div>
      </div>
    </div>
  );
};
