import * as React from 'react';
import { useEffect, useState } from 'react';
import './add-camera.scss';

import { getDashboardStats } from '../../../GPI/API/DashboardApi';
export interface IAddCameraProps {
  moveToListPage: () => void;
}

export const IpV4RegEx = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const IpV6RegEx = /((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}/;

export const DashboardStats = (props: IAddCameraProps) => {
  const [dashboardCards, setDashboardCards] = useState([])

  useEffect(() => {
    getDashboardStats().then(res => {
      setDashboardCards(res)
    })
  }, [])




  return (
    <div >
      <h3 className="dashboard__title">Dashboard</h3>

      <div
        style={{
          width: "80vw ",
          display: "flex",
          gap: ".4rem",
          padding: "0 1rem",
        }}
      >
        {dashboardCards.slice(0,4).map((cardData) => {
          return (
            <div className="dashboard__cards">

              <div className='dashboard__card-highlighter'></div>

              <div className="dashboard__card-data">
                <div>
                  <h2 className='dashboard__cardData-tile'>{cardData.dashboardCardName}</h2>
                  <p className="dashboard__cardData-description">
                    {cardData.dashboardDescription}
                  </p>
                </div>

                <div>
                  <h1 className="dashboard__cardData-number-count">
                    {cardData.totalCount}
                  </h1>
                </div>
              </div>
              <div>
                <img className='dashboard__cardData-image' src={cardData.dashboardCardlogo} alt="" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};