import React, { useState } from "react";
import "./DetailsTab.scss"; // Optional CSS for styling

export default  function DetailsTabs() {
 // State to track the active tab
 const [activeTab, setActiveTab] = useState("home");

 // Handler to change the active tab
 const handleTabClick = (tab) => {
   setActiveTab(tab);
 };

 return (
   <div className="tabs-container">
     {/* Header Tab Bar */}
     <div className="tabs-header">
       <div
         className={`tab-item ${activeTab === "home" ? "active" : ""}`}
         onClick={() => handleTabClick("home")}
       >
         Table
       </div>
       <div
         className={`tab-item ${activeTab === "about" ? "active" : ""}`}
         onClick={() => handleTabClick("about")}
       >
         Graphs
       </div>
       <div
         className={`tab-item ${activeTab === "contact" ? "active" : ""}`}
         onClick={() => handleTabClick("contact")}
       >
         Reports
       </div>
     </div>

 
   </div>
 );
}


