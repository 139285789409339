import * as React from 'react';
import { Button } from '../Generic/Button/Button';
import './AddSuccess.scss'

interface IAddCamSuccessProps {
    open: boolean;
    message: string;
    close: ()=>void
}

export const AddSuccess = (props: IAddCamSuccessProps) => {
  return (
      <div>
        {props.open &&
        <div className={'success-pop-up'}>
            <div className={'camera-success-pop-up-content'}>
                <div className={'message'}> 
                    {props.message}
                </div>
                <div className={'btn'}>
                    <Button
                        class='medium-btn-container'
                        textcolor={'white'}
                        bgcolor={'#097844'}
                        text={'OK'}
                        width={'10rem'}
                        onClick={()=>{
                            props.close()
                        }}
                    />
                </div>
            
            </div>
        </div>
        }
      </div>
  );
};
