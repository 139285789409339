import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalStateContext } from '../../../../../GlobalContext/GlobalContextProvider';
import './unit-details.scss';
import { ROUTES } from '../../../../../components/Routes/SideBarRoutes';
import { Button } from '../../../../../components/Generic/Button/Button';

{/*
{
    "name": name,
    "model": model,
    "ai_algorithm": ai_algorithm,
    "ip_address": ip_address,
    "streaming_url": streaming_url,
    "unit": unit,
    "location": location,
    "status": status,
    "coordinates": coordinates,
    "created_at": created_at,
}
*/}

interface ICameraDetailsProps {
    adminUser: any;
    open: boolean;
    close: ()=>void;
    onDelete: (user_id: string) => void;
}

export const UserDetails = (props: ICameraDetailsProps) => {
    const [show, setShow] = useState(false);
    const {globalState:{permissions}} = React.useContext(GlobalStateContext);
    const navigate = useNavigate();

    useEffect(()=>{
        if(props.open) setShow(true);
        else setShow(false);
    },[props.open]);

    const handleDelete = () => {
      try {
        props.onDelete(props.adminUser.id);
      }
      catch(err) {

      }
    }
    

  return (
    <div>{show &&
      <div className={'unit-details-pop-up-container'}>
          <div className={'close-btn'}>
              <img
                src={'/images/notifications/close.svg'}
                alt={'close.svg'}
                onClick={()=>props.close()}
              />
            </div>
          <div className={'title'}>
            {'User Details'}
          </div>
          <div className={'camera-overview'}>
              <div style={{display:'flex', justifyContent:'space-between'}}>
                <div style={{fontSize:'1.3rem', fontWeight:'bold'}}> {props.adminUser.name} </div>
              
              </div>
   
          </div>
          <div className={'pop-up-overview'}>
            {/* <div className={'pop-up-overview-title'}>
                Overview
            </div> */}
            <div className={'pop-up-details'}>
                <span>Role:</span>
                <span>{props.adminUser?.role}</span>
            </div>
            <div className={'pop-up-details'}>
                <span>Contact number:</span>
                <span>{props.adminUser?.phone}</span>
            </div>
            <div className={'pop-up-details'}>
                <span>Email:</span>
                <span>{props.adminUser?.email}</span>
            </div>
          
          
          </div>
          <div style={{ justifyContent: 'center', display: 'flex', marginTop: '5rem', gap: '1rem' }}>
          {/* {[...permissions].includes("CONFIGS_UPDATE") &&

            <Button
              class='medium-btn-container'
              text={'Edit'}
              textcolor={'white'}
              bgcolor={'#4D4D33'}
              width={'30%'}
              onClick={() => {
                navigate(`${ROUTES.updateUser}/${props.adminUser.id}`)
              }}
            />
          }
          {[...permissions].includes("CONFIGS_DELETE") &&

            <Button
              class='medium-btn-container'
              text={'Delete'}
              textcolor={'white'}
              bgcolor={'#7B0323'}
              width={'30%'}
              onClick={handleDelete}
            />
          } */}
        </div>
      </div>
      }
      </div>
  );
};
