import * as React from 'react';
import { ExamsList } from './ExamsManagement/QPExamList/ExamList';
import './camera-management.scss';
import { AddQPExam } from './ExamsManagement/AddQPExam/AddQPExam';
import { useEffect } from 'react';
import { useContext } from 'react';
import { GlobalStateContext } from '../../GlobalContext/GlobalContextProvider';
import CompanyComponent from '../../components/Generic/CompanyLogo/CompanyComponents';
import { AddQPPaper } from './QPPaperManagement/AddQPPaper/AddQPPaper';
import { QPPaperList } from './QPPaperManagement/QPPaperList/CameraList';

export interface ICameraManagementProps {
}



export const QPManagement = (props: ICameraManagementProps) => {
  const { globalState: { permissions } } = useContext(GlobalStateContext);

  const sidePanelRoutes = [
    {
      id: '#1',
      title: 'Exams list',
      component: <ExamsList />,
      accessPermission: 'CAMERA_ALL'
    },
    {
      id: '#2',
      title: '+ Add new Exam',
      component: <AddQPExam moveToListPage={() => setPageVariables({ ...pageVariables, selectedRoute: sidePanelRoutes[0] })} />,
      accessPermission: 'CAMERA_ADD'
    },
    {
      id: '#3',
      title: 'Papers list',
      component: <QPPaperList moveToListPage={
        () => setPageVariables({ ...pageVariables, selectedRoute: sidePanelRoutes[2] })
       }  />,
      accessPermission: 'CAMERA_ALL'
    },
    {
      id: '#4',
      title: '+ Add new paper',
      component: <AddQPPaper moveToListPage={() => setPageVariables({ ...pageVariables, selectedRoute: sidePanelRoutes[2] })} />,
      accessPermission: 'CAMERA_ADD'
    }
  ];
  const [pageVariables, setPageVariables] = React.useState({
    selectedRoute: sidePanelRoutes[0]
  })

  useEffect(() => {
    document.title = 'Cameras'
  }, []);

  return (
    <div className={'camera-management-container'}>
      <div className={'visitor-side-panel-container'}>
        <div className={'visitor-side-panel-routes-container'}>
          {
            sidePanelRoutes.map((route, index) => {
              return [...permissions].includes(route.accessPermission) && (
                <div
                  className={`visitor-side-panel-route-box 
                      ${pageVariables.selectedRoute.id === route.id ?
                      'selected-visitor-side-panel-route-box' : ''}`}
                  onClick={() => setPageVariables({ ...pageVariables, selectedRoute: route })}
                >
                  <div
                    className={pageVariables.selectedRoute.id === route.id ? 'selected-side-route' : 'not-selected-side-route'}
                  >
                  </div>
                  <div className={pageVariables.selectedRoute.id === route.id ? 'selected-visitor-side-panel-route' : 'visitor-side-panel-route'}>
                    {route.title}
                  </div>

                </div>)
            })
          }

        </div>
        <div className={'critical-ai-log-bottom'}>
          <CompanyComponent />
        </div>
      </div>
      <div className={'visitor-content-container'}>
        {pageVariables.selectedRoute.component}
      </div>
    </div>);
}
