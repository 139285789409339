// import { ActivityDetailsSideCard } from '../VisitorsActivities/ActivityDetailsSideCard/ActivityDetailsSideCard';
import { useEffect, useState } from 'react';
import './camera-details.scss';
import { SubjectPaperCard } from './SubjectCard/SubjectPaperCard';
import { useParams } from 'react-router-dom';
import AddSubject from './AddSubject/AddSubject';
import ModifySubject from './EditSubject/ModifySubject';
import { Button } from '../../../../../../components/Generic/Button/Button';
import { getSubjects } from '../../../../../APIServices/QP/Subjects';

export interface IVisitorDetailsProps {
}

export function QPExamSubjectsDetails(props: IVisitorDetailsProps) {

  const form = {
    id: null,
    plan_name: "",
    plan_image_url: "",
    plan_features: [""],
    price: 0,
    save_upto_price: "",
    validity_in_days: 1,
    discount_percent: 0,
    selected_subject_id: 0,
    showModifyPopup: false,
    showAddPopup: false,
  }

  const [formData, setFormData] = useState<any>(form);


  const { exam_id } = useParams();

  const [subjects, setSubjects] = useState([])
  

  useEffect(()=>{
    getSubjects(exam_id, null).then((resp)=> setSubjects(resp.results))
  },[formData.showAddPopup, formData.showModifyPopup])


  useEffect(()=>{
    getSubjects(exam_id, null).then((resp)=> setSubjects(resp.results))
  },[])

  return (
    <div>
      <div>

        <div className={'overview-container'}>

        <div className={'visitors-list-header'}>
            <div className={'visitors-list-header-left'}>
              <div className={'title'}>
                Subjects 
              </div>
              <div style={{ display: 'flex', marginRight: '2rem' }}>
              
              <Button
                class='btn-container'
                bgcolor={'#02720d'}
                text={'Add Subject'}
                textcolor={'white'}
                width={''}
                fontSize={'1rem'}
                onClick={() => setFormData({ ...formData, showModifyPopup: false, showAddPopup: true })}
          ></Button>
              </div>
          
            </div>
        
    </div>

        

          <div className="subjectContainer">
              {subjects?.map((subject, index) => (
                <SubjectPaperCard subject={subject}  selectSubject = {(id)=> setFormData({...formData, selected_subject_id: id, showModifyPopup: true})} />
              ))}
          </div>



      {formData.showModifyPopup && (
        <ModifySubject
          setShowModifyPopup={() => setFormData({ ...formData, showModifyPopup: false })}
          examId={exam_id}
          subjectId={formData.selected_subject_id}
        />
      )}

      {formData.showAddPopup && (
        <AddSubject
          setShowAddPopup={() => setFormData({ ...formData, showModifyPopup: false, showAddPopup: false })}
          examId={exam_id}
        />
      )}



        </div>



      </div>

    </div>
  );
}
