import * as React from 'react';
import { useContext, useState } from 'react';
import './edit-gate.scss';
import { addGate, getGateById, updateGate } from '../../API/Gates';
import { toast } from 'sonner';
import { GlobalStateContext } from '../../../GlobalContext/GlobalContextProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { ROUTES } from '../../../components/Routes/SideBarRoutes';
import TextInput from '../../../components/Generic/TextInput/TextInput';
import { LatLong } from '../../../components/Generic/LatLong/LatLong';
import Dropdown, { DropDownVariant } from '../../../components/Generic/Dropdown/Dropdown';
import { FileUpload, UploadType } from '../../../components/Generic/FileUpload/FileUpload';
import { Button } from '../../../components/Generic/Button/Button';



export interface IAddNewGateProps {
}

export function EditGate(props: IAddNewGateProps) {

  const { globalState: { user } } = useContext(GlobalStateContext);
  const [META_DATA, setMETA_DATA] = useState(user?.application_configs);
  const { gate_id } = useParams();
  const navigate = useNavigate();

  const form = {
    gate_number: null,
    gate_name: '',
    gate_location: '',
    cdr: '',
    unit: null,
    coordinates: '',
    gate_photo: '',
    type: '',
    allowed_categories: null,
    is_active: false
  };
  const [formData, setFormData] = useState(form);
  const [invalidFields, setInvalidFields] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);

  const validateFields = React.useCallback(async (valid: boolean, field: string) => {

    if (valid) {
      if (invalidFields.includes(field))
        setInvalidFields(Fields => {
          let filtered = Fields.filter(value => value !== field);
          return filtered;
        });
    }
    else {
      if (!invalidFields.includes(field))
        setInvalidFields(Fields => {
          Fields.push(field);
          return Fields;
        });
    }
  }, []);

  const submitForm = async () => {
    try {
      if (invalidFields.length) {
        toast.warning('Please fill all the required fields!');
        setSubmitClicked(true);
        return;
      }
      const payload = {
        name: formData.gate_name,
        location: formData.gate_location,
        commander: formData.cdr,
        unit: formData.unit,
        allowed_categories: formData.allowed_categories,
        coordinates: formData.coordinates,
        photo: formData.gate_photo,
        gate_type: formData.type,
        is_active: formData.is_active
      }
      console.log(payload)
      const response = await updateGate(gate_id, payload);
      if (response && response.statusCode === 200) {
        toast.success("Success: gate updated!");
        navigate(ROUTES.gateDetails + '/' + gate_id);
      }
      else throw new Error("Error");

    }
    catch (err) {
      toast.error("Error: Failed to add gate!")
    }
  }

  const fetchGateDetails = async () => {
    try {
      const response = await getGateById(gate_id);

      if (response && response.statusCode === 200) {
        const {
          location,
          name,
          photo,
          commander,
          coordinates,
          gate_type,
          unit,
          allowed_categories,
          is_active

        } = response.response;
        setFormData({
          ...formData,
          gate_name: name,
          gate_location: location,
          cdr: commander,
          unit: unit,
          coordinates: coordinates,
          gate_photo: photo,
          allowed_categories: allowed_categories,
          type: gate_type,
          is_active: is_active
        });
      }
      else throw new Error("Error");

    }
    catch (err) {
      toast.error("Error: failed to fetch gate details");
    }
  }

  useEffect(() => {
    fetchGateDetails();
  }, []);

  return (
    <div className={'edit-gate-form-container'}>
      <div className={'title-left'}>
        Update Gate [{gate_id}]
      </div>
      <div className={'edit-gate-form-container'}>
        <div style={{ display: 'flex', gap: '2rem' }}>
          <TextInput
            label={'Gate Name'}
            placeholder={'Enter gate name'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, gate_name: value })}
            value={formData.gate_name}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          />
          <TextInput
            label={'Gate Location'}
            placeholder={'Enter gate location'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, gate_location: value })}
            value={formData.gate_location}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          />
          <LatLong
            label={'Lat, Long'}
            placeholder={'Enter latitude, longitude'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, coordinates: value })}
            value={formData.coordinates}
            isRequired={false}
            // isValid={validateFields}
            // showWarning={submitClicked}
          />
        </div>
        <div style={{ display: 'flex', gap: '2rem', marginTop: '2em' }}>


          {/* <TextInput
            label={'Unit'}
            placeholder={'Enter Unit'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, unit: value })}
            value={formData.unit}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          /> */}

          <Dropdown
            label={'Unit'}
            placeholder={'Select unit'}
            width={'30%'}
            options={META_DATA.Unit}
            onSelect={(selection) => setFormData({ ...formData, unit: selection })}
            selection={formData.unit}
            isRequired={false}
          // isValid={validateFields}
          // showWarning={submitClicked}
          />


          <TextInput
            label={'Cdr'}
            placeholder={'Enter Cdr'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, cdr: value })}
            value={formData.cdr}
            isRequired={false}
          // isValid={validateFields}
          // showWarning={submitClicked}
          />
          <Dropdown
            label={'Gate type'}
            placeholder={'Select type'}
            width={'30%'}
            options={META_DATA.ActivityType}
            onSelect={(selection) => setFormData({ ...formData, type: selection })}
            selection={formData.type}
            isRequired={false}
            // isValid={validateFields}
            // showWarning={submitClicked}
            variant={DropDownVariant.multiValue}

          />
        </div>




        <div style={{ display: 'flex', gap: '2rem', marginTop: '2em' }}>
          <Dropdown
            label={'Allowed Category'}
            placeholder={'Select category'}
            width={'30% '}
            key={'RegisterNewVisitor-category'}
            options={META_DATA.Category}
            onSelect={(selection) => setFormData({ ...formData, allowed_categories: selection })}
            selection={formData.allowed_categories}
            isRequired={false}
            // isValid={validateFields}
            // showWarning={submitClicked}
            variant={DropDownVariant.multiValue}
          />

          <FileUpload
            label={'Gate Picture'}
            width={'120%'}
            accept={UploadType.file}
            onUpload={(file_url) => setFormData({ ...formData, gate_photo: file_url })}
            url={formData.gate_photo}
          />
        </div>
      </div>
      <div className={'new-gate-btn'}>
        <Button
          class='btn-container'
          text={'Update Gate'}
          bgcolor={'#4D4D33'}
          textcolor={'white'}
          width={'fit-content'}
          onClick={submitForm}
        />
      </div>
    </div>
  );
}
