import React, { useState } from "react";
import "./AddPlan.scss";
import { addExamPlan } from "../../../../../../APIServices/QP/ExamPlans";
import TextInput from "../../../../../../../components/Generic/TextInput/TextInput";
import { Button } from "../../../../../../../components/Generic/Button/Button";
import { toast } from "sonner";
import { FileUpload, UploadType } from "../../../../../../../components/Generic/FileUpload/FileUpload";
import { addSubject } from "../../../../../../APIServices/QP/Subjects";






function AddSubject(props: { examId: any, setShowAddPopup: any }) {


  const form = {
    exam_id: props.examId,
    title: "",
    image_url: "",
    code: "",
  }
  const [formData, setFormData] = useState<any>(form);

  const [invalidFields, setInvalidFields] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);

  const validateFields = React.useCallback(async (valid: boolean, field: string) => {
    if (valid) {
      if (invalidFields.includes(field))
        setInvalidFields(Fields => {
          let filtered = Fields.filter(value => value !== field);
          return filtered;
        });
    }
    else {
      if (!invalidFields.includes(field))
        setInvalidFields(Fields => {
          Fields.push(field);
          return Fields;
        });
    }
  }, []);



  const saveChanges = () => {
    if (invalidFields.length) {
      toast.warning('Please fill all the required fields!');
      setSubmitClicked(true);
      return;
  }

    addSubject(props.examId, formData).then(res=>{
      console.log(res)
      props.setShowAddPopup()
    })
  }



  return (
    <div className="add-plan-container">
      <div className="heading">
        <h3>Add Subject</h3>
        <div className="close" onClick={() => props.setShowAddPopup()} > X </div>
      </div>
      

          <div className={'add-camera-form-row-1'}>
         
          <FileUpload
                label={'Upload Icon'}
                width={'30rem'}
                accept={UploadType.file}
                isRequired={true}
                onUpload={(url) => setFormData({...formData, image_url:url})}
                url={formData.image_url}
                isValid={validateFields}
                showWarning={submitClicked}
            />

        
        
        
          </div>

    

      <div className="add-camera-form-row-2">
        <TextInput
              label={'Title'}
              placeholder={'Enter subject title'}
              width={'90%'}
              onChange={(value) => setFormData({ ...formData, title: value })}
              value={formData.title}
              isRequired={true}
              isValid={validateFields}
              showWarning={submitClicked}
            />
      </div>

   

      <div className="add-camera-form-row-2">
        <TextInput
              label={'Code'}
              placeholder={'Enter unique code'}
              width={'90%'}
              onChange={(value) => setFormData({ ...formData, code: value })}
              value={formData.code}
              isRequired={true}
              isValid={validateFields}
              showWarning={submitClicked}
            />
      </div>

      <div className="add-camera-form-row-3">
          <Button
                class='btn-container'
                bgcolor={'#02720d'}
                text={'Add Subject'}
                textcolor={'white'}
                width={''}
                fontSize={'1rem'}
                onClick={() => {
                    saveChanges()
                }
              }
          
          ></Button>
      </div>

    
     
    </div>
  );
}

export default AddSubject;
