import * as React from 'react';
import { useState } from 'react';
import { Button } from '../../../Generic/Button/Button';
import Dropdown, { DropDownVariant } from '../../../Generic/Dropdown/Dropdown';
import TextInput from '../../../Generic/TextInput/TextInput';
import './edit-unit.scss';
import { toast } from 'sonner';
import { useContext } from 'react';
import { GlobalStateContext } from '../../../../GlobalContext/GlobalContextProvider';
import { ROUTES } from '../../../Routes/SideBarRoutes';
import { useNavigate, useParams } from 'react-router-dom';
import { getUnit, updateUnit } from '../../../../GPI/API/Configs';


export const IpV4RegEx = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const IpV6RegEx = /((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}/;

export const EditUnit = () => {
  const { globalState: { user, gates } } = useContext(GlobalStateContext);
  const [META_DATA, setMETA_DATA] = useState(user?.application_configs);
  const navigate = useNavigate();

  const { id } = useParams();


  const [formData, setFormData] = useState({
    title: null,
    description: null,
    intime: null,
    outtime: null,
  });

  const [invalidFields, setInvalidFields] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);

  const validateFields = React.useCallback(async (valid: boolean, field: string) => {
    if (valid) {
      if (invalidFields.includes(field))
        setInvalidFields(Fields => {
          let filtered = Fields.filter(value => value !== field);
          return filtered;
        });
    }
    else {
      if (!invalidFields.includes(field))
        setInvalidFields(Fields => {
          Fields.push(field);
          return Fields;
        });
    }
  }, []);

  const handleClick = async () => {
    try {
      if (invalidFields.length) {
        toast.warning('Please fill all the required fields!');
        setSubmitClicked(true);
        return;
      }
      const payload = { ...formData };
      const response = await updateUnit(id,payload);

      if (response && response.statusCode === 200) {
        toast.success("Success: Unit added!");
        navigate(ROUTES.configs);
      }
      else throw new Error("Error");
    }
    catch (err) {
      toast.error("Error: failed to add Unit!");
    }
  }

  React.useEffect(()=>{
    getUnit(id).then(res=> setFormData({
      title: res.response.title,
      description: res.response.description,
      intime: res.response.cross_duration,
      outtime: res.response.exit_time,
    }))
  },[]);


  return (
    <div className={'edit-unit-form-container'}>
      <div className={'title-left'}>
        Add Unit
      </div>
      <div className={'add-unit-form-row-1'}>
        <TextInput
          label={'Unit title'}
          placeholder={'Enter unit title'}
          width={'30%'}
          onChange={(value) => setFormData({ ...formData, title: value })}
          value={formData.title}
          isRequired={true}
          isValid={validateFields}
          showWarning={submitClicked}
          disabled={true}
        />

        <TextInput
          label={'Description'}
          placeholder={'Enter description'}
          width={'30%'}
          onChange={(value) => setFormData({ ...formData, description: value })}
          value={formData.description}
          // isRequired={false}
          // isValid={validateFields}
          // showWarning={submitClicked}
        />



      </div>
      <div className={'add-unit-form-row-2'}>

{/* 
        <BasicTimePicker label="select the cross duration" setValue={(selection) => setFormData({ ...formData, intime: selection })} />
        <BasicTimePicker label="select the exit time" setValue={(selection) => setFormData({ ...formData, outtime: selection })} /> */}

      </div>

      <div className={'btn-position'}>
        <Button
        class='btn-container'
          bgcolor={'#4D4D33'}
          text={'Update unit'}
          textcolor={'white'}
          width={'10rem'}
          onClick={handleClick}
        />

      </div>
    
    </div>
  );
};
