import * as React from 'react';
import { CameraList } from './CameraList/CameraList';
import './dashboard-management.scss';
import { DashboardStats } from './DashboardStats/AddBranch';
import { useEffect } from 'react';
import { useContext } from 'react';
import { GlobalStateContext } from '../../GlobalContext/GlobalContextProvider';
import CompanyComponent from '../../components/Generic/CompanyLogo/CompanyComponents';

export interface ICameraManagementProps {
}



export const EdtechDashboardManagement = (props: ICameraManagementProps) => {
  const { globalState: { permissions } } = useContext(GlobalStateContext);

  const sidePanelRoutes = [
    {
      id: '#1',
      title: 'Dashboard Stats',
      component: <DashboardStats moveToListPage={() => setPageVariables({ ...pageVariables, selectedRoute: sidePanelRoutes[0] })} />,
      accessPermission: 'CAMERA_ADD'
    },
    // {
    //   id: '#2',
    //   title: 'Branches list',
    //   component: <CameraList />,
    //   accessPermission: 'CAMERA_ALL'
    // },
    
  ];
  const [pageVariables, setPageVariables] = React.useState({
    selectedRoute: sidePanelRoutes[0]
  })

  useEffect(() => {
    document.title = 'Cameras'
  }, []);

  return (
    <div className={'camera-management-container'}>
      <div className={'dashboard-side-panel-container'}>
        <div className={'dashboard-side-panel-routes-container'}>
          {
            sidePanelRoutes.map((route, index) => {
              return [...permissions].includes(route.accessPermission) && (
                <div
                  className={`dashboard-side-panel-route-box 
                      ${pageVariables.selectedRoute.id === route.id ?
                      'selected-dashboard-side-panel-route-box' : ''}`}
                  onClick={() => setPageVariables({ ...pageVariables, selectedRoute: route })}
                >
                  <div
                    className={pageVariables.selectedRoute.id === route.id ? 'selected-side-route' : 'not-selected-side-route'}
                  >
                  </div>
                  <div className={pageVariables.selectedRoute.id === route.id ? 'selected-dashboard-side-panel-route' : 'dashboard-side-panel-route'}>
                    {route.title}
                  </div>

                </div>)
            })
          }

        </div>
        <div className={'critical-ai-log-bottom'}>
          <CompanyComponent />
        </div>
      </div>
      <div className={'dashboard-content-container'}>
        {pageVariables.selectedRoute.component}
      </div>
    </div>);
}
