import * as React from 'react';

import './user-list.scss';
import { useEffect } from 'react';
import { useState } from 'react';
import { UserDetails } from './UserDetailsSidePop/UnitDetailsPopUp';
import { toast } from 'sonner';
import { deleteUnit, getunits, getusers } from '../../../../GPI/API/Configs';
import { GenericTable } from '../../../../components/Generic/Table';
import { deleteUser, getAdminUsers, getCandidates } from '../../../APIServices/UsersApi';

export interface ICameraListProps {
}

export const UserList = (props: ICameraListProps) => {
  const [pageVars, setPageVars] = useState({
    tableData: [],
    selectedUser: null,
    openPopUp: false,
    loading: false
  });

    const tableDemoData = {
        columnDetails: [
          {
            title: 'Fullname',
            key: 'name'
          },
          {
            title: 'Role',
            key: 'role'
          },
          {
            title: 'Email ID',
            key: 'email'
          },
          {
            title: 'Phone number',
            key: 'phone'
          }
        ]
      }
      
    const fetchUsers = async () => {
        try {
            setPageVars({...pageVars, loading: true, openPopUp: false});
            let options = {
              page: 1
            }
            const response = await getAdminUsers(options);
            let dataRows = [];
            response?.forEach( data => {
              const {

                id, name, phone, role, email,} = data;
    
              dataRows = [...dataRows, 
                {
                  "id": id,
                  "name": name,
                  "role": role,
                  "email": email,
                  "phone": phone
                }];
            });
            setPageVars({...pageVars, tableData: dataRows, loading: false, openPopUp: false});
        }
        catch(err) {
          setPageVars({...pageVars, loading: false, openPopUp: false});
        }
      }

      const handleDelete = async (user_id) => {
        try {
          const response = await deleteUser(user_id);
          if(response ) {
            toast.success("Success: Unit deleted!");
            fetchUsers();
          }
          else throw new Error("Error");
          
        }
        catch(err) {
          toast.error('Error: failed to delete Unit!');
        }
      }
    useEffect(()=>{
      fetchUsers();
    },[]);


    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const totalItems = 100; 
  
    const handlePageChange = (newPage) => {
      setPage(newPage);
     
    };
  
    const handleLimitChange = (newLimit) => {
      setLimit(newLimit);
    };
    
  return (
    <div className={'camera-list-container'}>
      <div className={'visitors-list-header'}>
        <div className={'visitors-list-header-left'}>
          <div className={'title'}>
            Admin Users List
          </div>
      
        </div>
  
      </div>
      <GenericTable
        columnDetails={tableDemoData.columnDetails}
        dataRows={pageVars.tableData}
        selectedRow={(row)=>setPageVars({...pageVars, selectedUser: row, openPopUp: true})}
        loading={pageVars.loading}
        totalItems={totalItems}
        initialLimit={limit}
        pagination={true}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />
      
      {pageVars.selectedUser && 
      <UserDetails
        adminUser={pageVars.selectedUser}
        open={pageVars.openPopUp}
        close={()=>setPageVars({...pageVars, openPopUp: false})}
        onDelete={handleDelete}
      />}
    </div>
  );
}
