import { useState, useEffect } from "react";
import "./SubscriptionManagement.scss";
import ModifyPlan from "./ModifyPlanPopup/ModifyPlan";
import AddPlan from "./AddPlanPopup/AddPlan";
import { getExams } from "../../../../../APIServices/QP/Exams";
import { getExamPlans } from "../../../../../APIServices/QP/ExamPlans";
import { useParams } from "react-router-dom";
import SubscriptionInfo from "./SubscriptionInfo";
import { Button } from "../../../../../../components/Generic/Button/Button";

function SubscriptionManagement() {

  const { exam_id } = useParams();


  const form = {
    showModifyPopup: false,
    showAddPopup: false,
    selectedExamId: exam_id,
    selectedPlanId: null
  }
  const [formData, setFormData] = useState(form);

  const [exams, setExams] = useState<any>([]);
  const [examPlans, setExamPlans] = useState([]);


  const modifyPlan = (id: any) => {
    console.log(id)
    setFormData({ ...formData, selectedPlanId: id, showModifyPopup: true })
  }

  useEffect(() => {
   let options = {
      page: 1,
      limit: 100,
      query: ''
    }
    getExams(options).then(result => setExams(result['results']))
  }, [])


  useEffect(() => {
    if (formData.selectedExamId != null)
      getExamPlans(formData.selectedExamId).then(result => setExamPlans(result))
  }, [formData.selectedExamId, formData.showModifyPopup, formData.showAddPopup])


  return (
    <>
      <div className={`subscription-management ${formData.showAddPopup || formData.showModifyPopup ? "blur-bg" : ""}`}>

        <SubscriptionInfo />

        <div className={'visitors-list-header'}>
            <div className={'visitors-list-header-left'}>
              <div className={'title'}>
                Subscriptions List
              </div>
              <div style={{ display: 'flex', marginRight: '2rem' }}>
              
                <Button
                    class='btn-container'
                    bgcolor={'#02720d'}
                    text={'Add Subscription'}
                    textcolor={'white'}
                    width={''}
                    fontSize={'1rem'}
                    onClick={() => setFormData({ ...formData, showModifyPopup: false, showAddPopup: true })}
                  />
              </div>
          
            </div>
        
    </div>

     
        <div className="plans-container">
          {examPlans.map((plan:any) => (
            <div key={plan.id} className="card-container">
              <div className="content">
                <div className="image">
                  <img src={plan.plan_image_url} alt={plan.plan_name} />
                </div>
                <h3>{plan.plan_name}</h3>
              </div>
              <ul className="access-content">
                {plan.plan_features.map((access: any, index: number) => (
                  <li
                    key={index}
                    className=""
                  >

                    <>
                      - {access}
                    </>

                  </li>
                ))}
              </ul>
              <div className="plan-pricing">
                {plan.plan_price === 0 ? (
                  <h2>Free</h2>
                ) : (
                  <h2>₹ {plan.price}</h2>
                )}
                <span>
                  /{plan.validity_in_days + "Days"}
                </span>
              </div>
              <div className="plan-btn" onClick={() => modifyPlan(plan.id)}>
                Modify Plan
              </div>
            </div>
          ))}
        </div>
      </div>



      {formData.showModifyPopup && (
        <ModifyPlan
          setShowModifyPopup={() => setFormData({ ...formData, showModifyPopup: false })}
          examId={formData.selectedExamId}
          planId={formData.selectedPlanId}
        />
      )}

      {formData.showAddPopup && (
        <AddPlan
          setShowAddPopup={() => setFormData({ ...formData, showModifyPopup: false, showAddPopup: false })}
          examId={formData.selectedExamId}
        />
      )}
    </>
  );
}

export default SubscriptionManagement;
