import * as React from 'react';
import { useContext, useState } from 'react';
import './add-new-gate.scss';
import { addGate } from '../../API/Gates';
import { toast } from 'sonner';
import { GlobalStateContext } from '../../../GlobalContext/GlobalContextProvider';
import TextInput from '../../../components/Generic/TextInput/TextInput';
import { LatLong } from '../../../components/Generic/LatLong/LatLong';
import Dropdown, { DropDownVariant } from '../../../components/Generic/Dropdown/Dropdown';
import { FileUpload, UploadType } from '../../../components/Generic/FileUpload/FileUpload';
import { Button } from '../../../components/Generic/Button/Button';


export interface IAddNewGateProps {
  moveToListPage: ()=>void;
}

export function AddNewGate(props: IAddNewGateProps) {

  const { globalState: { user } } = useContext(GlobalStateContext);
  const [META_DATA, setMETA_DATA] = useState(user?.application_configs);

  const form = {
    gate_number: null,
    gate_name: '',
    gate_location: '',
    cdr: '',
    unit: null,
    coordinates: '',
    gate_photo: '',
    type: '',
    categories: null
  };
  const [formData, setFormData] = useState(form);
  const [invalidFields, setInvalidFields] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);

  const validateFields = React.useCallback(async (valid: boolean, field: string) => {

    if (valid) {
      if (invalidFields.includes(field))
        setInvalidFields(Fields => {
          let filtered = Fields.filter(value => value !== field);
          return filtered;
        });
    }
    else {
      if (!invalidFields.includes(field))
        setInvalidFields(Fields => {
          Fields.push(field);
          return Fields;
        });
    }
  }, []);

  const submitForm = async () => {
    try {
      if (invalidFields.length) {
        toast.warning('Please fill all the required fields!');
        setSubmitClicked(true);
        return;
      }
      const payload = {
        gate_number: formData.gate_number,
        name: formData.gate_name,
        location: formData.gate_location,
        commander: formData.cdr,
        unit: formData.unit,
        coordinates: formData.coordinates,
        photo: formData.gate_photo,
        gate_type: formData.type,
        is_active: true,
        allowed_categories: formData.categories
      }
      console.log(payload)
      const response = await addGate(payload);
      if (response && response.statusCode === 200) {
        toast.success("Success: New gate added!");
        props.moveToListPage();
      }
      else throw new Error( response.message);

    }
    catch (err) {
      toast.error(err.message)
    }
  }

  return (
    <div className={'new-gate-form-container'}>
      <div className={'title-left'}>
        Add Gate
      </div>
      <div className={'new-gate-form-container'}>
        <div style={{ display: 'flex', gap: '2rem' }}>
          <TextInput
            label={'Gate Number'}
            placeholder={'Enter gate number'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, gate_number: value })}
            value={formData.gate_number}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
            isNumber
          />
          <TextInput
            label={'Gate Name'}
            placeholder={'Enter gate name'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, gate_name: value })}
            value={formData.gate_name}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          />
          <TextInput
            label={'Gate Location'}
            placeholder={'Enter gate location'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, gate_location: value })}
            value={formData.gate_location}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          />
        </div>
        <div style={{ display: 'flex', gap: '2rem', marginTop: '2em' }}>
          <LatLong
            label={'Lat, Long'}
            placeholder={'Enter latitude, longitude'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, coordinates: value })}
            value={formData.coordinates}
            isRequired={false}
            isValid={validateFields}
            showWarning={submitClicked}
          />

          {/* <TextInput
            label={'Unit'}
            placeholder={'Enter Unit'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, unit: value })}
            value={formData.unit}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          /> */}

          <Dropdown
            label={'Unit'}
            placeholder={'Select unit'}
            width={'30%'}
            options={META_DATA.Unit}
            onSelect={(selection) => setFormData({ ...formData, unit: selection })}
            selection={formData.unit}
            isRequired={false}
            isValid={validateFields}
            showWarning={submitClicked}
          />

            <Dropdown
                label={'Allowed Category'}
                placeholder={'Select category'}
                width={'25rem'}
                key={'RegisterNewVisitor-category'}
                options={META_DATA.Category}
                onSelect={(selection) => {
                  setFormData({...formData, categories: selection})
                  console.log(formData)
                }}
                selection={formData.categories}
                isRequired={false}
                // isValid={validateFields}
                // showWarning={submitClicked}
                variant={DropDownVariant.multiValue}
            />


         
        </div>

        <div style={{ display: 'flex', gap: '2rem', marginTop: '2em' }}>
        <TextInput
            label={'Cdr'}
            placeholder={'Enter Cdr'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, cdr: value })}
            value={formData.cdr}
            isRequired={false}
          />
          <Dropdown
            label={'Gate type'}
            placeholder={'Select type'}
            width={'30%'}
            options={META_DATA.ActivityType}
            onSelect={(selection) => setFormData({ ...formData, type: selection })}
            selection={formData.type}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
            variant={DropDownVariant.multiValue}
          />
          <FileUpload
            label={'Gate Picture'}
            width={'120%'}
            accept={UploadType.file}
            onUpload={(file_url) => setFormData({ ...formData, gate_photo: file_url })}
            url={formData.gate_photo}
          />
        </div>
      </div>
      <div className={'new-gate-btn'}>
        <Button
        class='btn-container'
          text={'Add Gate'}
          bgcolor={'#4D4D33'}
          textcolor={'white'}
          width={'fit-content'}
          onClick={submitForm}
        />
      </div>
    </div>
  );
}
