import * as React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'sonner';
import { MASTER_BASE_URL } from '../../../GPI/API/FetchMaster';
import { acknowledgeNotifications, fetchAcknowledgements, seenNotification } from '../../../GPI/API/Notifications';
import { GlobalStateContext } from '../../../GlobalContext/GlobalContextProvider';
import { Button } from '../../Generic/Button/Button';
import { Preview } from '../../Generic/ImagePreviewer/Preview';
import { TextArea } from '../../Generic/TextArea/TextArea';
import './notifications-details.scss';

{/*
{
  "title": title,
  "group": group,
  "type": type,
  "message": message,
  "file": file,
  "created_by": created_by,
  "created_at": created_at,
}
*/}


interface INotificationDetailsProps {
    notification: any;
    open: boolean;
    close: () => void;
}

export const NotificationDetails = (props: INotificationDetailsProps) => {
    const [show, setShow] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [ack, setAck] = useState('');
    const [pageVars, setPageVars] = useState({
        acknowledgement: null,
        showImage: false
    })

    const { globalState: { user } } = useContext(GlobalStateContext);

    const acknowledge = async () => {
        try {
            if (!ack) return;
            const payload = {
                notification_id: props.notification.id,
                gate_number: user.gateNumber,
                comment: ack
            }
            const response = await acknowledgeNotifications(payload, props.notification.id);
            if (response && response.statusCode === 200) {
                toast.success("Success: acknowledge sent!");
                setAck('');
                getAllAcknowledgements();
            }

        }
        catch (err) {
            toast.error("Error: failed to acknowledge!");
        }
    }

    const getAllAcknowledgements = async () => {
        try {
            const response = await fetchAcknowledgements(props.notification.id);
            if (response && response.statusCode === 200) {
                setPageVars({
                    ...pageVars,
                    acknowledgement: response.response
                })
            }
            else {
                setPageVars({
                    ...pageVars,
                    acknowledgement: null
                })
            }
        }
        catch (err) {

        }
    }

    const updateUnseenNotification = async () => {
        try {
            const response = await seenNotification(props.notification.id, props.notification.gate_number);
        }
        catch (err) {

        }
    }

    useEffect(() => {
        if (props.open) {
            setShow(true);
            setShowImage(true);

        }
        else {
            setShow(false);
            setShowImage(false);
        }
    }, [props.open]);

    useEffect(() => {
        getAllAcknowledgements();
    }, [props.notification]);




    return (
        <div>{show &&
            <div className={'notification-details-pop-up-container'}>
                <div className={'close-btn'}>
                    <img
                        src={'/images/notifications/close.svg'}
                        alt={'close.svg'}
                        onClick={() => {
                            setPageVars({ ...pageVars, showImage: false })
                            props.close()
                        }
                        }
                    />
                </div>
                <div className={'title'}>
                    {'Notifications Details'}
                </div>
                <div className={'pop-up-overview'}>
                    {/* <div className={'pop-up-overview-title'}>
                Overview
            </div> */}
                    <div className={'pop-up-overview-message'}>
                        <p>Message:</p>
                        <p>{props.notification?.message}</p>
                    </div>
                    <div className={'pop-up-details'}>
                        <span> Title:</span>
                        <span>{props.notification.title}</span>
                    </div>
                    <div className={'pop-up-details'}>
                        <span>Type:</span>
                        <span>{props.notification.type}</span>
                    </div>
                    <div className={'pop-up-details'}>
                        <span>Gates:</span>
                        <span>{props.notification.gate_numbers}</span>
                    </div>
                    <div className={'pop-up-details'}>
                        <span>Created by:</span>
                        <span>{props.notification.created_by}</span>
                    </div>
                    <div className={'pop-up-details'}>
                        <span>Created on:</span>
                        <span>{props.notification.created_at}</span>
                    </div>
                </div>
                <div className={'pop-up-file'}>
                    <div className={'pop-up-file-title'}>
                        File
                    </div>
                    {props.notification.file ?
                        <div className={'pop-up-file-container'}>
                            <div className={'pop-up-file-details'}>
                                <div className={'pop-up-file-img'}>
                                    <img
                                        src={`${MASTER_BASE_URL}${props.notification?.file}`}
                                        alt={'pdf.svg'}
                                    />
                                </div>
                                {/* <div className={'pop-up-file-name-size'}>
                        <div>
                            file
                        </div>
                        <div>
                            25KB
                        </div>
                    </div> */}
                            </div>
                            <div className={'pop-up-file-name-download'}>
                                <a href={'#'}
                                    onClick={() => {
                                        setShowImage(true)
                                    }}
                                    style={{ textDecoration: 'none' }}>
                                    view
                                </a>
                            </div>
                        </div> : 'N/A'}
                </div>
                {
                    pageVars.acknowledgement &&
                        pageVars.acknowledgement.length ?
                        <div style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                            Acknowledgments
                        </div>
                        : ''
                }
                {
                    pageVars.acknowledgement &&
                        pageVars.acknowledgement.length ?
                        pageVars.acknowledgement.map(acknowledgement => {
                            return (
                                <div className={'pop-up-file'}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className={'pop-up-file-title'}>
                                            {
                                                acknowledgement.gate_number ? `Gate - ${acknowledgement.gate_number}` : `${acknowledgement.username}`
                                            }
                                        </div>
                                        <div style={{ fontSize: '0.8rem', color: 'gray' }}>
                                            {acknowledgement.created_at}
                                        </div>
                                    </div>
                                    <div>
                                        {acknowledgement.comment}
                                    </div>
                                </div>
                            )
                        }) : ''
                }
                {user && user.role === "Test" &&
                    <div className={'pop-up-file'}>
                        <div className={'pop-up-file-title'}>
                            Acknowledge
                        </div>
                        <div>
                            <TextArea
                                height="7rem"
                                label=""
                                onChange={(value) => setAck(value)}
                                placeholder={'Enter message..'}
                                width={"95%"}
                                value={ack}
                            />
                            <div style={{ justifyContent: 'center', display: 'flex', marginTop: '1rem' }}>
                                <Button
                                    class='medium-btn-container'
                                    bgcolor="#FD7E17"
                                    text="Save"
                                    textcolor="white"
                                    width="20%"
                                    onClick={acknowledge}
                                />
                            </div>
                        </div>
                    </div>}
            </div>
        }
    

            <>{showImage &&
                <div className={'preview-container'}>
                    <div style={{ color: 'white', display: 'flex', justifyContent: 'flex-end' }}>
                        <img
                            src={'/images/notifications/closewhite.svg'}
                            alt={'close.svg'}
                            onClick={() => setShowImage(false)}
                        />
                    </div>
                    <div className={'preview-image-container'}>
                        <img
                            src={props.notification.file != null ? (MASTER_BASE_URL + props.notification.file) : '/images/login/profileLogo.svg'}
                            alt={'No Preview'}
                        />
                    </div>
                </div>
            }
            </>
        </div>
    );
};
