export const ROUTES = {
  home: '/',
  visitor: '/visitors',
  candidate: '/candidates',
  candidateDetails: '/candidate-details',

  gates: '/gates',
  cameras: '/cameras',
  enquiryForms: '/enquiry-forms',
  qp: '/qp',
  configs: '/configs',
  notifications: '/notifications',
  visitorDetails: '/visitor-details',
  gateDetails: '/gate-details',
  login: '/log-in',
  user: '/user-company',
  updateVisitor: '/update-visitor',
  updatePaper: '/update-paper',
  updateGate: '/update-gate',
  updateCamera: '/update-camera',
  cameraDetails: '/camera-details',
  qpDetails: '/qp-details',
  qpPaperDetails: '/papers',
  updateCategory: '/update-category',
  updateUnit: '/update-unit',
  updateUser: '/update-user',

  updateQPExam: '/update-qpexam',
  questionDetails: '/question-details',
  edtechConfigs: '/edtech-configs',
  edtechDashboard: '/dashboard',
  blogs: '/blogs',
  questionTagging: '/questions-tagging',



}


export const kaleidoViewSideNavBarRoutes = [
  {
    routeImage: '/images/edtech/home.svg',
    routeName: "Dashboard",
    routePath : ROUTES.edtechDashboard,
    accessName: 'VISITOR_TAB'
  },
    // {
    //   routeImage: '/images/edtech/mentors.svg',
    //   routeName: "Mentors",
    //   routePath : ROUTES.visitor,
    //   accessName: 'VISITOR_TAB'
    // },
    {
      routeImage: '/images/edtech/candidates.svg',
      routeName: "Customers",
      routePath : ROUTES.candidate,
      accessName: 'GATE_TAB'
    },
     
    {
      routeImage: '/images/edtech/quill_paper.svg',
      routeName: "Kiview",
      routePath : ROUTES.qp,
      accessName: 'CAMERA_TAB'
    },
     

     {
      routeImage: '/images/side_bar/configs.svg',
      routeName: "Configs",
      routePath : ROUTES.edtechConfigs,
      accessName: 'CONFIGS_TAB'
    },
];


export const edtechSideNavBarRoutes = [
  {
    routeImage: '/images/edtech/home.svg',
    routeName: "Dashboard",
    routePath : ROUTES.edtechDashboard,
    accessName: 'VISITOR_TAB'
  },
    // {
    //   routeImage: '/images/edtech/mentors.svg',
    //   routeName: "Mentors",
    //   routePath : ROUTES.visitor,
    //   accessName: 'VISITOR_TAB'
    // },
    {
      routeImage: '/images/edtech/candidates.svg',
      routeName: "Candidates",
      routePath : ROUTES.candidate,
      accessName: 'GATE_TAB'
    },
    
    // {
    //   routeImage: '/images/edtech/courses.svg',
    //   routeName: "Courses",
    //   routePath : ROUTES.cameras,
    //   accessName: 'CAMERA_TAB'
    // },
     
    // {
    //   routeImage: '/images/edtech/suitcase.svg',
    //   routeName: "Jobs",
    //   routePath : ROUTES.cameras,
    //   accessName: 'CAMERA_TAB'
    // },
     
   
     
    {
      routeImage: '/images/edtech/quill_paper.svg',
      routeName: "Exams",
      routePath : ROUTES.qp,
      accessName: 'CAMERA_TAB'
    },
     
    // {
    //   routeImage: '/images/edtech/bac.svg',
    //   routeName: "Byte Connect",
    //   routePath : ROUTES.cameras,
    //   accessName: 'CAMERA_TAB'
    // },
     
    // {
    //   routeImage: '/images/edtech/courses.svg',
    //   routeName: "Tagging",
    //   routePath : ROUTES.questionTagging,
    //   accessName: 'CAMERA_TAB'
    // },
    // {
    //   routeImage: '/images/edtech/blogs.svg',
    //   routeName: "Blogs",
    //   routePath : ROUTES.blogs,
    //   accessName: 'CAMERA_TAB'
    // },
     {
      routeImage: '/images/side_bar/configs.svg',
      routeName: "Configs",
      routePath : ROUTES.edtechConfigs,
      accessName: 'CONFIGS_TAB'
    },
    // {
    //   routeImage: '/images/side_bar/bell.svg',
    //   routeName: "Notification",
    //   routePath : ROUTES.notifications,
    //   accessName: 'NOTIFICATION_TAB'
    // },
    //    {
    //   routeImage: '/images/edtech/courses.svg',
    //   routeName: "form-detail",
    //   routePath : ROUTES.enquiryForms,
    //   accessName: 'CAMERA_TAB'
    // },
];


export const sideNavBarRoutes = [
  {
    routeImage: '/images/side_bar/dashboard-svgrepo-com.svg',
    routeName: "Dashboard",
    routePath : ROUTES.home,
    accessName: 'VISITOR_TAB'
  },
    {
      routeImage: '/images/side_bar/visitors-side-bar.svg',
      routeName: "Visitors",
      routePath : ROUTES.visitor,
      accessName: 'VISITOR_TAB'
    },
    {
      routeImage: '/images/side_bar/gate.svg',
      routeName: "Gates",
      routePath : ROUTES.gates,
      accessName: 'GATE_TAB'
    },
    
    {
      routeImage: '/images/side_bar/cam.svg',
      routeName: "Cameras",
      routePath : ROUTES.cameras,
      accessName: 'CAMERA_TAB'
    },
     {
      routeImage: '/images/side_bar/configs.svg',
      routeName: "Configs",
      routePath : ROUTES.configs,
      accessName: 'CONFIGS_TAB'
    },
    {
      routeImage: '/images/side_bar/bell.svg',
      routeName: "Notification",
      routePath : ROUTES.notifications,
      accessName: 'NOTIFICATION_TAB'
    }
];


export const iotSideNavBarRoutes = [
    {
      routeImage: '/images/side_bar/dashboard-svgrepo-com.svg',
      routeName: "Dashboard",
      routePath : ROUTES.home,
      accessName: 'VISITOR_TAB'
    },
    {
      routeImage: '/images/side_bar/visitors-side-bar.svg',
      routeName: "Customers",
      routePath : ROUTES.visitor,
      accessName: 'VISITOR_TAB'
    },
    {
      routeImage: '/images/side_bar/gate.svg',
      routeName: "Devices",
      routePath : ROUTES.gates,
      accessName: 'GATE_TAB'
    },
    
    {
      routeImage: '/images/side_bar/cam.svg',
      routeName: "Cameras",
      routePath : ROUTES.cameras,
      accessName: 'CAMERA_TAB'
    },
     {
      routeImage: '/images/side_bar/configs.svg',
      routeName: "Configs",
      routePath : ROUTES.configs,
      accessName: 'CONFIGS_TAB'
    },
    {
      routeImage: '/images/side_bar/bell.svg',
      routeName: "Notifications",
      routePath : ROUTES.notifications,
      accessName: 'NOTIFICATION_TAB'
    }
];


export const ecommerceSideNavBarRoutes = [
  {
    routeImage: '/images/side_bar/dashboard-svgrepo-com.svg',
    routeName: "Dashboard",
    routePath : ROUTES.home,
    accessName: 'VISITOR_TAB'
  },
    {
      routeImage: '/images/side_bar/visitors-side-bar.svg',
      routeName: "Customers",
      routePath : ROUTES.visitor,
      accessName: 'VISITOR_TAB'
    },
    {
      routeImage: '/images/side_bar/gate.svg',
      routeName: "Products",
      routePath : ROUTES.gates,
      accessName: 'GATE_TAB'
    },
    
    {
      routeImage: '/images/side_bar/cam.svg',
      routeName: "Orders",
      routePath : ROUTES.cameras,
      accessName: 'CAMERA_TAB'
    },
     {
      routeImage: '/images/side_bar/configs.svg',
      routeName: "Configs",
      routePath : ROUTES.configs,
      accessName: 'CONFIGS_TAB'
    },
    {
      routeImage: '/images/side_bar/bell.svg',
      routeName: "Notification",
      routePath : ROUTES.notifications,
      accessName: 'NOTIFICATION_TAB'
    }
];






export const serviceProvidorSideNavBarRoutes = [
  {
    routeImage: '/images/side_bar/visitors-side-bar.svg',
    routeName: "Dashboard",
    routePath : ROUTES.home,
    accessName: 'VISITOR_TAB'
  },
    {
      routeImage: '/images/side_bar/visitors-side-bar.svg',
      routeName: "Visitors",
      routePath : ROUTES.visitor,
      accessName: 'VISITOR_TAB'
    },
    {
      routeImage: '/images/side_bar/gate.svg',
      routeName: "Devices",
      routePath : ROUTES.gates,
      accessName: 'GATE_TAB'
    },
    
    {
      routeImage: '/images/side_bar/cam.svg',
      routeName: "Cameras",
      routePath : ROUTES.cameras,
      accessName: 'CAMERA_TAB'
    },
     {
      routeImage: '/images/side_bar/configs.svg',
      routeName: "Configs",
      routePath : ROUTES.configs,
      accessName: 'CONFIGS_TAB'
    },
    {
      routeImage: '/images/side_bar/bell.svg',
      routeName: "Notification",
      routePath : ROUTES.notifications,
      accessName: 'NOTIFICATION_TAB'
    }
];
