
import * as Congs from "../Configurations"


// @ts-ignore
export function addExamPlan(examId, payload) {
    const requestOptions = {
      method: "POST",
      redirect: "follow",
      body: JSON.stringify(payload),
  
      headers: Congs.getHeaders()
  
    };
  
    // @ts-ignore
    return fetch(`${Congs.BASE_URL}/api/v2/exams/${examId}/plans/?limit=10&page=1`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.error(error));
  }
  
  
  
  
  // @ts-ignore
  export function getExamPlans(examId) {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: Congs.getHeaders()
  
    };
  
    // @ts-ignore
    return fetch(`${Congs.BASE_URL}/api/v2/exams/${examId}/plans?limit=10&page=1`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.error(error));
  }
  
  
  
  // @ts-ignore
  export function getExamPlanDetails(examId, planId) {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: Congs.getHeaders()
  
    };
  
    // @ts-ignore
    return fetch(`${Congs.BASE_URL}/api/v2/exams/${examId}/plans/${planId}?limit=10&page=1`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.error(error));
  }
  
  // @ts-ignore
  export function updateExamPlanDetails(examId, planId, payload) {
    const requestOptions = {
      method: "PUT",
      redirect: "follow",
      body: JSON.stringify(payload),
  
      headers: Congs.getHeaders()
  
    };
  
    // @ts-ignore
    return fetch(`${Congs.BASE_URL}/api/v2/exams/${examId}/plans/${planId}?limit=10&page=1`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.error(error));
  }
  


    
  // @ts-ignore
  export function getTopics(subjectId) {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: Congs.getHeaders()
  
    };
  
    // @ts-ignore
    return fetch(`${Congs.BASE_URL}/api/v2/subjects/${subjectId}/topics?limit=100&page=1`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.error(error));
  }
  