import React, { useRef, useState, useEffect } from "react";
import "./AddPlan.scss";
// import { CgClose } from "react-icons/cg";
// import { FaPlus, FaToggleOff, FaToggleOn, FaTrash } from "react-icons/fa";
import { addExamPlan } from "../../../../../../APIServices/QP/ExamPlans";
import { Switch } from 'antd';
import { stringify } from "querystring";
import TextInput from "../../../../../../../components/Generic/TextInput/TextInput";
import Dropdown from "../../../../../../../components/Generic/Dropdown/Dropdown";
import FeatureInput from "../../../../../../../components/Generic/FeatureInput/FeatureInput";
import { Button } from "../../../../../../../components/Generic/Button/Button";
import { toast } from "sonner";

const plans = [
  {
    id: 1,
    plan_name: "Sprint Plan",
    plan_image_url: "https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/QP/assets/sprint.svg",
    plan_features: [
      "feature1",
    ],
    price: 2000,
    save_upto_price: "500",
    validity_in_days: 90,
    discount_percent: 10,
  },
  {
    id: 2,
    plan_name: "Marathon Plan",
    plan_image_url: "https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/QP/assets/marathon.svg",
    plan_features: [
      "feature",
    ],
    price: 3000,
    save_upto_price: "10%",
    validity_in_days: 180,
    discount_percent: 10,
  }, {
    id: 3,
    plan_name: "Endurance Plan",
    plan_image_url: "https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/QP/assets/endurance.svg",
    plan_features: [
      "add feature",
    ],
    price: 4000,
    save_upto_price: "50%",
    validity_in_days: 270,
    discount_percent: 10,
  }, {
    id: 4,
    plan_name: "Ultimate Plan",
    plan_image_url: "https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/QP/assets/ultimate.png",
    plan_features: [
      "feature",
    ],
    price: 5000,
    save_upto_price: "500",
    validity_in_days: 365,
    discount_percent: 10,
  }, {
    id: 5,
    plan_name: "Free Plan",
    plan_image_url: "https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/QP/assets/free.svg",
    plan_features: [
      "feature",
    ],
    price: 0,
    save_upto_price: "500",
    validity_in_days: 30,
    discount_percent: 10,
  },
]




function AddPlan(props: { examId: any, setShowAddPopup: any }) {


  const form = {
    id: null,
    plan_name: "",
    plan_image_url: "",
    plan_features: [""],
    price: 0,
    save_upto_price: "",
    validity_in_days: 1,
    discount_percent: 0,
  }
  const [formData, setFormData] = useState<any>(form);
  const [validity, setValidity] = useState();



  const [newPermission, setNewPermission] = useState("");


  const handleDeletePermission = (permission: any) => {
    let temp = formData.plan_features.filter((feature: any) => feature != permission)
    setFormData({ ...formData, plan_features: temp })
  }

  const handleAddPermission = () => {
    let temp = [...formData.plan_features, newPermission]
    setFormData({ ...formData, plan_features: temp })
    setNewPermission("")
  };



  const saveChanges = () => {
    addExamPlan(props.examId, formData).then(res=>{
      console.log(res)
      props.setShowAddPopup()
    })
  }




  const changePlan = (plan_name: any) => {
    const temp = plans.filter(plan => plan.plan_name == plan_name)
    console.log(temp[0])
    console.log(plan_name)
    setFormData({ ...temp[0] })
  }

  return (
    <div className="add-plan-container">
      <div className="heading">
        <h3>Add Plan</h3>
        <div className="close" onClick={() => props.setShowAddPopup()} > X </div>
      </div>
    
      <div className={'add-camera-form-row-1'}>

          <div className="image-container">
            <div className="image">
                <img
                  src={formData.plan_image_url}
                  alt="logo192.png"
                  style={{ maxWidth: "4rem", maxHeight: "4rem" }}
                />
              
            </div>
          </div>
          <Dropdown
            label={'Select exam'}
            placeholder={'Select Exam'}
            width={'80%'}
            options={["Marathon Plan", "Marathon Plan", "Endurance Plan", "Ultimate Plan", "Free Plan"]}
            onSelect={(selection) => changePlan(selection)}
            selection={formData.plan_name}
            isRequired={true}
            // isValid={validateFields}
            // showWarning={submitClicked}
          />
          </div>

    

      <div className="add-camera-form-row-2">
        <TextInput
              label={'Price'}
              placeholder={'0'}
              width={'40%'}
              onChange={(value) => setFormData({ ...formData, price: value })}
              value={formData.price}
              isRequired={true}
              // isValid={validateFields}
              // showWarning={submitClicked}
            />
        
    
        <TextInput
              label={'Validity'}
              placeholder={'Validity in days'}
              width={'40%'}
              onChange={(value) => setFormData({ ...formData, code: value })}
              value={formData.validity_in_days}
              isRequired={true}
              disabled

              // isValid={validateFields}
              // showWarning={submitClicked}
            />


      </div>



      <hr />
      <div className="content-access">
        <h3>Plan features</h3>
      
            {formData.plan_features.map( (access: any, index: number) => (
              <FeatureInput 
              label={''}
              placeholder={'Validity in days'}
              width={'90%'}
              onChange={(value) => setNewPermission( value)}
              value={newPermission}
              isRequired={false}
              />
            ))}
      
      </div>
      <Button
            class='btn-container'
            bgcolor={'#02720d'}
            text={'Add Plan'}
            textcolor={'white'}
            width={''}
            onClick={() => {
              if (false) {
                toast.warning('Please fill all the required fields!');
                return;
              }
              else
                saveChanges()
                // setShowSettings(true)
            }}
      
      ></Button>
     
    </div>
  );
}

export default AddPlan;
