import * as React from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../../components/Generic/Button/Button';
import { Filters } from '../../../../components/Generic/Filters/Filters';
import { GenericTable } from '../../../../components/Generic/Table';
import { ROUTES } from '../../../../components/Routes/SideBarRoutes';
import './camera-details.scss';
import { toast } from 'sonner';
import { Preview } from '../../../../components/Generic/ImagePreviewer/Preview';
import QPPaperDetailsTabs from './QPPaperDetailsTab/QPPaperDetailsManagement';
import {  getPaperById } from '../../../APIServices/QP/PapersApi';
import { getExamsById } from '../../../APIServices/QP/Exams';
import { getExamSubjectById } from '../../../APIServices/QP/Subjects';

export interface IVisitorDetailsProps {
}

export function QPPaperDetails(props: IVisitorDetailsProps) {
  const navigate = useNavigate();
  const { paper_id } = useParams();

  const [pageVars, setPageVars] = useState({
    id: null,
    code: null,
    is_published: null,
    exam_id: null,
    year: null,
    total_questions: null,
    solutions_added: null,
    is_unlocked: null,
    subject_id: null,
    subject_image_url: null,
    subject_name: null,

    openPopUp: false,
    openBlockPopUp: false,
   

  });

  const[exam, setExam] = useState({})
  const[subject, setSubject] = useState({image_url:'/images/login/profileLogo.svg'})


  const navigateBack = () => {
    navigate(ROUTES.qp)
  }


  const getPaperDetails = async () => {
    try {
      const response = await getPaperById(paper_id);

      const {
        id,
        code,
        is_published,
        exam_id,
        year,
        total_questions,
        solutions_added,
        is_unlocked,
        subject_id
      } = response;

      setPageVars({
        ...pageVars,
        id,
        code,
        is_published,
        exam_id,
        year,
        total_questions,
        solutions_added,
        is_unlocked,
        subject_id,
        openBlockPopUp: false,
        subject_name: "subject_title",
        subject_image_url: "subject_image_url"
      })
    }
    catch (err) {

    }
  }


  React.useEffect(()=>{
    getExamsById(pageVars.exam_id).then(res=> setExam(res))
    getExamSubjectById(pageVars.exam_id, pageVars.subject_id).then(res=> setSubject(res))
  },[pageVars])

  
  React.useEffect(() => {
    getPaperDetails();
  }, []);








  return (
    <div>
      <div className={'visitor-details-container'}>
        <div className={'visitor-details-left-panel'}>
          <div className={'visitor-details-left-panel-back-btn'}>
            <Button
              class='medium-btn-container'
              text={'< Back to papers list'}
              textcolor={'dark-green'}
              bgcolor={'#09784433'}
              width={'fit-content'}
              onClick={navigateBack}
            />
          </div>
          <div className={'profile-image-name-section'}>
            <div
              className={'profile-image'}
            >
              <img style={{ width: '10rem', height: '10rem', objectFit: 'cover', borderRadius: '1rem' }}
                src={subject.image_url ? subject.image_url : '/images/login/profileLogo.svg'}

              />
            </div>

            <div>

              <div className={'visitor-name'}>
                {pageVars.code}
              </div>
              <div className={'visitor-status'}>
                <span className={pageVars.is_published ? 'status-published' : 'status-blocked'}> {pageVars.is_published ? 'Published' : 'Not Published'} </span>
              </div>
            </div>
          </div>


          <div className={'personal-details-section'}>
            <div className={'personal-details-section-header'}>
              <div>Personal Details</div>
              <div className={'personal-details-section-header-btns'}>
                <Button
                  class='medium-btn-container'
                  text={'Edit'}
                  textcolor={'white'}
                  bgcolor={'#4D4D33'}
                  width={'fil-content'}
                  onClick={() => navigate(ROUTES.updatePaper + '/' + paper_id)}
                />

              </div>
            </div>

            <div className='personal-details'>
              <div className='personal-details-div'>
                <span>Year</span> : <span>{pageVars.year}</span>
              </div>
              <div className='personal-details-div'>
                <span>Code.</span> : <span>{pageVars.code}</span>
              </div>
              <div className='personal-details-div'>
                <span>total_questions</span> : <span>{pageVars.total_questions}</span>
              </div>
              <div className='personal-details-div'>
                <span>Solutions added</span> : <span>{pageVars.solutions_added}</span>
              </div>


            </div>
          </div>


          <div>

          </div>

        </div>
        <div className={'overview-container'}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <QPPaperDetailsTabs />
          </div>
        </div>





      </div>

    </div>
  );
}
