import * as Congs from "./Configurations";

import { toast } from "sonner";

export  function registerNewUser(userData) {
  console.log("fetching shortcuts");

  return fetch(Congs.NEW_REGISTRATION, {
    method: "POST",
    body: JSON.stringify(userData),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response).catch((e) => console.log("Error " + e));
}



export function loginUser(userData) {
  console.log("logging user");

  userData = {
    email: userData.username,
    password: userData.password
  }

  return fetch(Congs.Login_USER, {
    method: "POST",
    body: JSON.stringify(userData),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json()).then((res)=>{
      if(res.user_info.role === 'Candidate' | res.user_info.role === 'User'){
        throw new Error("Error: not allowed");
      }
     const user =  {
        "message": "Successfully fetched ",
        "statusCode": 200,
        "response": {
            "application_configs": {
                "UserRole": [
                    "Guard",
                    "Admin",
                    "Control_Room"
                ],
                "Permissions": [],
                "Gender": [
                    "Male",
                    "Female",
                    "Other"
                ],
                "Unit": [
                    "TB 3",
                    "HQ MEG",
                    "TB 2"
                ],
                "Salutation": [],
                "Category": [
                    "Officer",
                    "JCO",
                    "OR",
                    "MES",
                    "Vendor",
                    "Maids",
                    "test",
                    "ssss",
                    "ssss",
                    "sssss",
                    "sssss",
                    "sssss",
                    "ssss",
                    "ssss",
                    "ssssssaaa",
                    "ssss",
                    "ssss",
                    "ssssss",
                    "ssss",
                    "sssss",
                    "sssss",
                    "sssss"
                ],
                "ActivityType": [
                    "IN",
                    "OUT"
                ],
                "CameraModel": [
                    "CP Plus E35A 3 MP",
                    "CP Plus E35A 16 MP"
                ],
                "CameraAlgorithm": [
                    "Vehicle Voilation",
                    "Crowd Formation",
                    "Human Detection",
                    "Human Crossing Virtual Fence",
                    "Weapon Detection"
                ],
                "CameraStatus": [
                    "Active",
                    "Inactive"
                ],
                "NotificationGroup": [
                    "G1",
                    "G2"
                ],
                "NotificationType": [
                    "Alert",
                    "Notification"
                ]
            },
            "id": 1,
            "username": "admin",
            "token": res.token,
            "gateNumber": null,
            "company_id": 1,
            "is_notification_enabled": null,
            "role": "Admin",
            "permissions": [
                "ANALYTICS_REPORTS",
                "ACTIVITY_READ",
                "ACTIVITY_ALL",
                "CAMERA_ADD",
                "CAMERA_READ",
                "CAMERA_ALL",
                "CAMERA_UPDATE",
                "VISITOR_ALL",
                "VISITOR_UPDATE",
                "VISITOR_DELETE",
                "NOTIFICATION_TAB",
                "NOTIFICATION_ADD",
                "NOTIFICATION_READ",
                "CONFIGS_ADD",
                "CONFIGS_ALL",
                "CONFIGS_TAB",
                "CONFIGS_READ",
                "CONFIGS_UPDATE",
                "GATE_TAB",
                "GATE_ADD",
                "GATE_READ",
                "GATE_ALL",
                "GATE_UPDATE",
                "GATE_DELETE",
                "CAMERA_TAB",
                "CAMERA_DELETE",
                "CONFIGS_DELETE",
                "ACTIVITY_TAB",
                "ACTIVITY_UPDATE",
                "ACTIVITY_DELETE",
                "VISITOR_TAB",
                "NOTIFICATION_ALL",
                "NOTIFICATION_UPDATE",
                "VISITOR_ADD",
                "VISITOR_READ",
                "ACTIVITY_ADD",
                "NOTIFICATION_DELETE",
                "GATE_CREDS"
            ],
            "company_name": "sss"
        }
    }
    
    return user

    })
    .catch((e) => {
      toast.error("Something went wrong. Please try again");
      // console.log("error");
    });
}

export function logoutUser() {
  console.log("logoutUser user");
  const userData = {uniqueUserId : Congs.uniqueUserId, token: Congs.getToken()}
  return fetch(Congs.LOGOUT_USER, {
    method: "POST",
    body: JSON.stringify(userData),
    
    headers: Congs.getHeaders(),
  
  }).catch((e) => {
      // toast.error("Please enter right credentials.");
      console.log("error");
    });
}

export function getUserInfo() {
  console.log("fetching userInfo");
    return fetch(Congs.GET_USER_INFO,{
      method: "GET",
      
      headers: Congs.getHeaders(),

    })
}


export function updateUserInfo(userInfo) {
  console.log("updating userInfo");
  return fetch(Congs.UPDATE_USER_INFO, {
    method: "PUT",
    
    headers: Congs.getHeaders(),
    body: JSON.stringify(userInfo),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
}

