import * as React from 'react';
import { useState } from 'react';
import { Button } from '../../../components/Generic/Button/Button';
import Dropdown, { DropDownVariant } from '../../../components/Generic/Dropdown/Dropdown';
import { FileUpload, UploadType } from '../../../components/Generic/FileUpload/FileUpload';
import { TextArea } from '../../../components/Generic/TextArea/TextArea';
import TextInput from '../../../components/Generic/TextInput/TextInput';
import MultiTextInput from '../../../components/Generic/MultiTextInput/MultiTextInput';
import './register-new-visitor.scss';
import { toast } from 'sonner';
import { GlobalStateContext } from '../../../GlobalContext/GlobalContextProvider';
import { useContext } from 'react';
import { registerNewUser } from '../../APIServices/LoginRegistrationApi';

export interface IRegisterNewVisitorProps {
    showVisitorListPage: () => void
}

export function RegisterNewVisitor(props: IRegisterNewVisitorProps) {
    const { globalState: { user } } = useContext(GlobalStateContext);
    const [META_DATA, setMETA_DATA] = useState(user.application_configs);

    const form = {
        name: '',
        email: '',
        password: '',
        phone: '',
        profile_image_url: null,
        gender: '',
        address: "",
        exam_preference: ''
    };

    const [invalidFields, setInvalidFields] = useState([]);
    const [submitClicked, setSubmitClicked] = useState(false);

    const [formData, setFormData] = useState({ ...form });

    const validateFields = React.useCallback(async (valid: boolean, field: string) => {
        if (valid) {
            if (invalidFields.includes(field))
                setInvalidFields(Fields => {
                    let filtered = Fields.filter(value => value !== field);
                    return filtered;
                });
        }
        else {
            if (!invalidFields.includes(field))
                setInvalidFields(Fields => {
                    Fields.push(field);
                    return Fields;
                });
        }
    }, []);

    const submitForm = async () => {
        try {
            if (invalidFields.length) {
                toast.warning('Please fill all the required fields!');
                setSubmitClicked(true);
                return;
            }
            const response = await registerNewUser(formData).then(response => response)
            if (response && response.status == 200) {
                toast.success("you have successfully registered, please login.");
            }
            else throw new Error("Error");


        }
        catch (err) {
            toast.error('Error: failed to create visitor')
        }
    }

    return (
        <div className={'register-new-visitor-container'}>
            <div className={'register-new-visitor-title'}>
                Register New Visitor
            </div>

            <div className={'register-new-visitor-form-row-1'}>

                <TextInput
                    label={'Full Name'}
                    placeholder={'Enter candidate full name'}
                    width={'25rem'}
                    key={'RegisterNewVisitor-salutation'}
                    onChange={(value) => setFormData({ ...formData, name: value })}
                    value={formData.name}
                    isRequired={true}
                    isValid={validateFields}
                    showWarning={submitClicked}
                />

                <TextInput
                    label={'Phone/ID no.'}
                    placeholder={'Enter visitor phone/id no.'}
                    width={'25rem'}
                    key={'RegisterNewVisitor-Phone'}
                    onChange={(value) => setFormData({ ...formData, phone: value })}
                    value={formData.phone}
                    isRequired={true}
                    isValid={validateFields}
                    showWarning={submitClicked}
                    maxLength={10}
                    isNumber={true}
                />
                <Dropdown
                    label={'Gender'}
                    placeholder={'Select gender'}
                    width={'25rem'}
                    key={'RegisterNewVisitor-Gender'}
                    options={META_DATA.Gender}
                    onSelect={(selection) => setFormData({ ...formData, gender: selection })}
                    selection={formData.gender}
                    isRequired={true}
                    isValid={validateFields}
                    showWarning={submitClicked}
                />




            </div>
            <div className={'register-new-visitor-form-row-2'}>

                <Dropdown
                    label={'Exams'}
                    placeholder={'Select exam'}
                    width={'25rem'}
                    key={'RegisterNewVisitor-category'}
                    options={["IIT", "NEET"]}
                    onSelect={(selection) => setFormData({ ...formData, exam_preference: selection })}
                    selection={formData.exam_preference}
                    isRequired={true}
                    isValid={validateFields}
                    showWarning={submitClicked}
                    variant={DropDownVariant.singleValue}
                />
                <TextInput
                    label={'Email Id'}
                    placeholder={'Enter email id'}
                    width={'25rem'}
                    key={'RegisterNewVisitor-name'}
                    onChange={(value) => setFormData({ ...formData, email: value })}
                    value={formData.email}
                    isRequired={true}
                    isValid={validateFields}
                    showWarning={submitClicked}
                />
                <TextInput
                    label={'Password'}
                    placeholder={'Enter password'}
                    width={'25rem'}
                    key={'RegisterNewVisitor-name'}
                    onChange={(value) => setFormData({ ...formData, password: value })}
                    value={formData.password}
                    isRequired={true}
                    isValid={validateFields}
                    showWarning={submitClicked}
                />

            </div>


            <div className={'register-new-visitor-form-row-4'}>
                <TextArea
                    label={'Address'}
                    placeholder={'Enter candidate address..'}
                    width={'25rem'}
                    height={'6rem'}
                    key={'RegisterNewVisitor-Address'}
                    onChange={(value) => setFormData({ ...formData, address: value })}
                    value={formData.address}
                    isRequired={false}
                    // isValid={validateFields}
                    // showWarning={submitClicked}
                    maxLength={300}
                />

                <FileUpload
                    label={'Upload candidate photo'}
                    width={'25rem'}
                    key={'RegisterNewVisitor-upload-profile'}
                    onUpload={(file_url) => setFormData({ ...formData, profile_image_url: file_url })}
                    showWarning={submitClicked}
                    accept={UploadType.image}
                    url={formData.profile_image_url}
                />

            </div>


            <div className={'register-visitor-btn-place'}>
                <Button
                    class='btn-container'
                    bgcolor={'#4D4D33'}
                    text={'Save the details'}
                    textcolor={'white'}
                    width={'fit-content'}
                    onClick={submitForm}

                />
            </div>
        </div>
    );
}
