import { BASE_URL } from "./Configurations";
// import axios from 'axios';
// import { LOCAL_STORAGE_KEYS } from "../components/Login/Login";


const handleSubmit = async (url, formData) => {
    try {
        var requestOptions = {
            method: 'PUT',
            body: formData,
        };
        const resp = await fetch(url, requestOptions)
            .then(response => response.json())
            .then(result => {
                return result;
            })
            .catch((err) => {
                throw new Error(err);
            });
        console.log(resp)
        return resp;
    }
    catch (err) {
        return { message: 'Failed to upload file!' }
    }
};


export const uploadFile = async (formData, filetype) => {


    const requestOptions = {
        method: "GET",
        redirect: "follow"
    };

    
    const response = await fetch(BASE_URL + "/api/v2/pre-signed-url/" + filetype, requestOptions)
        .then((response) => response.json())
        .then(async response => {
            let res = await handleSubmit(response.presigned_url, formData)
            console.log(res)
            return response
        })
        .catch((error) => console.error(error));


    console.log("response")
    console.log(response)
    return response.s3_key
}




export async function getFileUrl(s3_key) {

    const requestOptions = {
        method: "GET",
        redirect: "follow"
    };

    
    const response = await fetch(BASE_URL + "/api/v2/get-signed-url?" + "s3_key=" + s3_key, requestOptions);
    const result = await response.json();
    const presigned_url = result.presigned_url;
    console.log(presigned_url)

    return "https://bytecoder-uat.s3.amazonaws.com/"+ s3_key;
}





export const uploadFile_v2= async (formData) => {


    const requestOptions = {
        method: "POST",
        redirect: "follow",
        body: formData
    };

    
    return fetch(BASE_URL + "/api/v2/uploadfile/", requestOptions)
        .then((response) => response.json())
        .catch((error) => console.error(error));
}
