import * as Congs from "../Configurations"








export function getTags( subject_id, page = 1, limit = 200) {

  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: Congs.getHeaders()

  };

  
  return fetch(`${Congs.BASE_URL}/api/v2/tags?page=${page}&limit=${limit}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));

}







export function addNewQuestion(examId, payload) {

  console.log(payload)
  const requestOptions = {
    method: "POST",
    redirect: "follow",
    body: JSON.stringify(payload),
    headers: Congs.getHeaders()

  };


  
  return fetch(`${Congs.BASE_URL}/api/v2/exams/${examId}/questions/`, requestOptions)
    .then((response) => response)
    .catch((error) => console.error(error));
}





export function getQuestions(exam_id, fetchOptions) {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: Congs.getHeaders()

  };

  const url = `${Congs.BASE_URL}/api/v2/exams/${exam_id}/questions?page=1`
  const fetchUrl = Congs.prepareUrl(url, fetchOptions);


  
  return fetch(fetchUrl, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}




export function getQuestionById(paper_id, question_id,fetchOptions) {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: Congs.getHeaders()

  };

  const url = `${Congs.BASE_URL}/api/v2/exams/${paper_id}/questions/${question_id}?page=1`
  const fetchUrl = Congs.prepareUrl(url, fetchOptions);


  
  return fetch(fetchUrl, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}





export function updateQuestion(exam_id, question_id, payload) {

  console.log(payload)
  const requestOptions = {
    method: "PUT",
    redirect: "follow",
    body: JSON.stringify(payload),
    headers: Congs.getHeaders()

  };


  
  return fetch(`${Congs.BASE_URL}/api/v2/exams/${exam_id}/questions/${question_id}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}





export function getAllCommentsOfQuestion(questionId) {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: Congs.getHeaders()

  };

  
  return fetch(`${Congs.BASE_URL}/api/v1/exam/question/${questionId}/comments?limit=10&page=1`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}




export function addCommentsOfQuestion(questionId, payload) {
  const requestOptions = {
    method: "POST",
    redirect: "follow",
    body: JSON.stringify(payload),
    headers: Congs.getHeaders()

  };

  
  return fetch(`${Congs.BASE_URL}/api/v1/questions/${questionId}/comments`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}












