import * as React from 'react';
import './gate-list.scss';
import { getAllGates } from '../../API/Gates';
import {toast} from 'sonner';
import { useState } from 'react';
import { useEffect } from 'react';
import { ACTIONS } from '../../../GlobalContext/GlobalReducer';
import { GlobalDispatchContext } from '../../../GlobalContext/GlobalContextProvider';
import { useContext } from 'react';
import { ROUTES } from '../../../components/Routes/SideBarRoutes';
import { SearchBar } from '../../../components/Generic/SearchBar/SearchBar';
import { GenericTable } from '../../../components/Generic/Table';


export interface IGateListProps {
}

export function GateList (props: IGateListProps) {
  const globalDispatcher = useContext(GlobalDispatchContext);

  const [pageVars, setPageVars] = useState({
    tableData: [],
    loading: false
  })



  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(10);

  const handlePageChange = (newPage) => {
    setPage(newPage);
   
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };




  const fetchGateList = async (page, filters, query?) => {
    try {
      setPageVars({...pageVars, loading: true});
      let options = {};
      if(filters) {
        options = {
          ...options,
          start_date: filters.start_date,
          end_date: filters.end_date,
          unit: filters.unit,
          category: filters.category,
          gate_number: filters.gate_number
        }
      }
      options = {
          ...options, 
          page: 1,
          query: query
        };
      
      const response = await getAllGates(options);

      if(response && response.statusCode === 200) {
        let dataRows = [];
        globalDispatcher({type: ACTIONS.setGates, payload: response.response.gateDtos});
        response.response.gateDtos.forEach(gate => {
          const {gate_number, name, permissions, location, photo, unit, password_updated_at } = gate;
          dataRows = [...dataRows, {
            "gate_number": gate_number,
            "name": name,
            "permissions": permissions,
            "location": location,
            "photo": photo,
            "unit": unit,
            "password_updated_at": password_updated_at? password_updated_at : "No User",
            "view_details": "View Details"
          }]
        });

        setPageVars({...pageVars, tableData: dataRows, loading: false});
      }
      else throw new Error("Error");
      
    }
    catch(err) {
      toast.error("Error: something went wrong!");
      setPageVars({...pageVars, loading: false});
    }
  }
  const tableDemoData = {
        columnDetails: [
          {
            title: 'Gate no.',
            sortable: true,
            bold: true,
            key: "gate_number"
          },
          {
            title: 'Gate Name',
            key: "name"
          },
          {
            title: 'Location',
            key: "location"
          },
          {
            title: 'Unit',
            key: "unit"
          },  
          
          {
            title: 'Password Updated',
            key: "password_updated_at"
          },
          {
            title: 'Action',
            route: ROUTES.gateDetails,
            key: "view_details",
            route_key: "gate_number"
          },
        ]
      }
    useEffect(()=>{
      setPageVars({...pageVars, loading: true});
      fetchGateList(1, page);
    },[]);

    useEffect(()=>{
      setPageVars({...pageVars, loading: true});
      fetchGateList(1, page);
    },[page]);




  return (
    <div className={'gate-list-container'}>
      <div className={'visitors-profile-header'}>
        <div className={'visitors-profile-header-left'}>
          <div className={'title'}>
            Gate List
          </div>
          
          <div style={{display:'flex' ,  marginRight: '2rem'}}>
          <div>
            <SearchBar
              placeholder={'Search gate by name'}
              width={'20rem'}
              onSearch={query => fetchGateList(1, null, query)}
            />
          </div>
          {/* <Filters
            unitFilter={true}
            dateFilter={true}
            apply={(filters)=>fetchGateList(1, filters)}
          /> */}
        </div>
          {/* <div className={'visitors-profile-header-update'}>
            Last updated on {new Date().toDateString()}
          </div> */}
        </div>
      </div>
      <GenericTable
        columnDetails={tableDemoData.columnDetails}
        dataRows={pageVars.tableData}
        loading={pageVars.loading}
        totalItems={totalItems}
        initialLimit={limit}
        pagination={true}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />
    </div>
  );
}
