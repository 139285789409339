import * as React from 'react';
import { useState } from 'react';
import { Button } from '../../../../components/Generic/Button/Button';
import Dropdown, { DropDownVariant } from '../../../../components/Generic/Dropdown/Dropdown';
import TextInput from '../../../../components/Generic/TextInput/TextInput';
import './edit-camera.scss';
import { toast } from 'sonner';
import { useContext } from 'react';
import { GlobalStateContext } from '../../../../GlobalContext/GlobalContextProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { getPaperById, updateSubjectPaper } from '../../../APIServices/QP/PapersApi';
import { Switch } from 'antd';
import { ROUTES } from '../../../../components/Routes/SideBarRoutes';



export const IpV4RegEx = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const IpV6RegEx = /((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}/;

export const EditQPPaper = () => {
  const { globalState: { user, gates } } = useContext(GlobalStateContext);
  const [META_DATA, setMETA_DATA] = useState(user?.application_configs);
  const navigate = useNavigate();
  const {paper_id} = useParams()
  const [showSettings, setShowSettings] = useState(false);

  const [formData, setFormData] = useState({
    id: null,
    year: "",
    code: "",
    exam_id: null,
    total_questions: 0,
    solutions_added: 0,
    is_published: false,
    is_unlocked: false,
    subject_id: "subject"
  });



  const [invalidFields, setInvalidFields] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);



  const validateFields = React.useCallback(async (valid: boolean, field: string) => {
    if (valid) {
      if (invalidFields.includes(field))
        setInvalidFields(Fields => {
          let filtered = Fields.filter(value => value !== field);
          return filtered;
        });
    }
    else {
      if (!invalidFields.includes(field))
        setInvalidFields(Fields => {
          Fields.push(field);
          return Fields;
        });
    }
  }, []);


  const handleClick = async () => {
    try {
      if (invalidFields.length) {
        toast.warning('Please fill all the required fields!');
        setSubmitClicked(true);
        return;
      }


      const payload = {
        ...formData
      };

      const response = await updateSubjectPaper(payload.exam_id,paper_id,  payload);
      toast.success("Successfully updated!");
      navigate(ROUTES.qpPaperDetails + `/${paper_id}`);
    }
    catch (err) {
      console.log(err)
      toast.error("Error: failed to update! " + err);
    }
  }

  const getQPPaperDetails = async () => {
    try {
      const response = await getPaperById(paper_id);

      const {
        id,
        code,
        exam_id,
        year,
        total_questions,
        solutions_added,
        is_published,
        is_unlocked,
        subject_id
      } = response;

      setFormData({
        ...formData,
        id,
        code,
        exam_id,
        year,
        total_questions,
        solutions_added,
        is_published,
        is_unlocked,
        subject_id,
      });

    }
    catch (err) {

    }
  }

  React.useEffect(() => {
    getQPPaperDetails();
  }, []);


  return (
    <div className={'edit-camera-form-container'}>
      <div className={'add-camera-form-title'}>
        Edit Exam Paper
      </div>
      <div>
        <div className={'add-camera-form-row-1'}>
          <TextInput
            label={'Month-Year'}
            placeholder={'Enter paper month year'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, year: value })}
            value={formData.year}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          />

          <TextInput
            label={'Code'}
            placeholder={'Enter paper code'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, code: value })}
            value={formData.code}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          />



        </div>
        <div className={'add-camera-form-row-2'}>
          
        <TextInput
            label={'Code'}
            placeholder={'Enter paper code'}
            width={'30%'}
            disabled={true} 
            onChange={(value) => setFormData({ ...formData, code: value })}
            value={formData.subject_id}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          />

        <TextInput
            label={'Code'}
            placeholder={'Enter paper code'}
            width={'30%'}
            disabled={true} 
            onChange={(value) => setFormData({ ...formData, code: value })}
            value={formData.exam_id}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          />

        </div>

        <div className={'add-camera-form-row-3'}>

          <div>
            <Switch
              style={{ margin: '1rem' }}
              value={formData.is_published}
              onChange={(value) => {
                setFormData({
                  ...formData,
                  is_published: value
                });
              }}
            />
            Publish
          </div>

          <div>
            <Switch
              style={{ margin: '1rem' }}
              value={formData.is_unlocked}
              onChange={(value) => {
                setFormData({
                  ...formData,
                  is_unlocked: value
                });
              }}
            />
            Locked
          </div>
        </div>

        <div className={'btn-position'}>
          <Button
            class='btn-container'
            bgcolor={'#4D4D33'}
            text={'Save'}
            textcolor={'white'}
            width={''}
            onClick={() => {
              if (invalidFields.length) {
                toast.warning('Please fill all the required fields!');
                setSubmitClicked(true);
                return;
              }
              else
              handleClick()
                setShowSettings(true)
            }}
          />
        </div>
      </div>
    </div>
  );
};
