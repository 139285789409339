
import React, { useEffect, useRef, useState } from "react";
import { FaPlay, FaRegEdit } from "react-icons/fa";
import { IoIosArrowBack, IoIosRemoveCircle } from "react-icons/io";
import {
  MdCancel,
  MdCoPresent,

} from "react-icons/md";
import "./AddQuestion.scss";
import { HiOutlineLightBulb } from "react-icons/hi";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { BiPlus } from "react-icons/bi";
import { TiDelete } from "react-icons/ti";
import { addNewQuestion, getQuestionById, getTags, updateQuestion } from "../../../../../../APIServices/QP/QuestionPaper";
import RichTextEditor from "../../../../../../../components/TextEditor/RichTextEditor";
import { Button } from "../../../../../../../components/Generic/Button/Button";
import { getPaperById } from "../../../../../../APIServices/QP/PapersApi";
import { getTopics } from "../../../../../../APIServices/QP/Topics";
import { AddSuccess } from "../../../../../../../components/SuccessComponent/AddSuccess";
import { toast } from "sonner";


const defaultQuestion = {
  no_of_times_appeared: 1,
  question_text: "", hint: "", related_tags: [], videoExplanation: [], related_topics: [], solution: "",   
   paper_id : 1
}


function ModifyQuestion() {

  const[pageVar, setPageVar] = useState({
    subject: {exam_id: 0, id: 0, subject_id: 0},
    successMessage: "updated sucessfully",
    open: false
  })

  const { paper_id, question_id } = useParams();
  const navigate = useNavigate();


  const videoRef = useRef(null);

  const [allTopics, setAllTopics] = useState([]);

  const [allTags, setAllTags] = useState([]);

  const [question, setQuestion] = useState(defaultQuestion);


  const uploadQuestion = () => {

    if(question.question_text == "" || question.hint == ""  || question.solution == ""  ){
      toast.error("Error: Add all the details")
      return
    }
   try{
    updateQuestion(paper_id, question_id ,  question).then(res => {
      if (res && res.status ==200) {

      console.log(res)
      window.scrollTo(0,0);
      setPageVar({...pageVar, open: true})
      navigate(`/papers/${paper_id}/question-details/${question_id}`)

      }
      else throw new Error("Error");

    })
  } catch (err) {
      toast.error("Error: failed to update !");
    }


  }


  const uploadVideo = () => {
    if (videoRef.current) {
      videoRef.current.click();
    }
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    handleVideoUpload(file);
  };

  const handleVideoUpload = (file) => {
    const videoUrl = URL.createObjectURL(file);
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      videoExplanation: [...prevQuestion.videoExplanation, videoUrl]
    }));
  }

  const removeVideo = (index) => {
    setQuestion((prevQuestion) => {
      const updatedVideoExplanation = [...prevQuestion.videoExplanation];
      updatedVideoExplanation.splice(index, 1);
      return {
        ...prevQuestion,
        videoExplanation: updatedVideoExplanation,
      };
    });
  };


  const addTag = (tag) => {
    setQuestion({

      ...question,
      related_tags: [...question.related_tags, tag]
    }
    )

  }

  const removeTag = (tagToRemove) => {

    const new_tags = question.related_topics.filter(tag => tag != tagToRemove)
    setQuestion({

      ...question,
      related_tags: [...new_tags]
    })
  };


  const addTopic = (topic) => {
    setQuestion({

      ...question,
      related_topics: [...question.related_topics, topic]
    }
    )
  };

  const removeTopic = (topicToRemove) => {
    const new_topics = question.related_topics.filter(topic => topic != topicToRemove)
    setQuestion({

      ...question,
      related_topics: [...new_topics]
    })
  };


  const updateQuestionContent = (content) => {
    if (content != question.question_text) {
      setQuestion({
        ...question,
        question_text: content
      })
    }

  };


  const updateHint = (content) => {
    if (content != question.hint) {

      setQuestion({
        ...question,
        hint: content
      })
    }
  };

  const updateExplaination = (content) => {
    if (content != question.solution) {
      setQuestion({
        ...question,
        solution: content
      })
    }
  };




  useEffect(() => {
    getPaperById(paper_id).then(res => setPageVar({...pageVar, subject:res}))

  }, [])

  useEffect(() => {
    getTopics(pageVar.subject.subject_id).then(res => {
      setAllTopics(res.results)
    })
    getTags(pageVar.subject.id).then(resp => {
      setAllTags(resp.results)
    })

    getQuestionById(paper_id, question_id, {}).then(res => setQuestion(res))

  }, [pageVar.subject])

  return (
    <div className="question-viewer">
   

      <div className="header">
        <div className="back" onClick={() => navigate(-1)}>
          <IoIosArrowBack /> <span>Back</span>
        </div>
      
      </div>

      <div className="questionViewer-container">

   
        {/* Tags sections */}
         {/* <div className="tags-section">
          <div className="accordion">
            <div className="tags-title">
              Tags
            </div>
            <div className="explanation">
              {question.related_tags.map((tag, index) => (
                <span key={index} onClick={() => removeTag(tag)}>
                  {tag.name}  <MdCancel />

                </span>
              ))}

              {
                allTags
                  .map((tag) => (
                    <div className="all-tags">
                      <span
                        className="unselected"
                        onClick={() => addTag(tag)}
                      >
                        {tag.name} <BiPlus />
                      </span>
                    </div>
                  ))
              }
            </div>
          </div>
        </div>  */}

          {/* Topics secrion */}
          <div className="topics-section">
          <div className="accordion">
            <div className="topic-title" >
              Topics
            </div>
            <div className="explanation">
              {question.related_topics.map((topic, index) => (
                <span key={index} onClick={() => removeTopic(topic)}>
                  {topic.topic_name} <MdCancel />
                </span>
              ))}

              {
                allTopics?.filter((topic) => !question.related_topics.includes(topic))
                  ?.map((topic) => (
                    <div className="all-tags">
                      <span
                        className="unselected"
                        onClick={() => addTopic(topic)}
                      >
                      {topic.topic_name} <BiPlus />
                      </span>
                    </div>
                  ))
              }
            </div>
          </div>
        </div>

        {/* Questions sections */}
        <div className="hint-explanation-section">
          <div className="accordion">
            <div className="hints-title" >
              <HiOutlineLightBulb /> Question
            </div>
            <RichTextEditor
                content={question.question_text ? question.question_text : ""}
                preview={false}
                setContent={updateQuestionContent}
                placeholder ="" 
              />
          </div>

        </div>


        {/* hint sections */}
        <div className="hint-explanation-section">
          <div className="accordion">
            <div className="hints-title" >
              <HiOutlineLightBulb /> Hint for the Solution
            </div>
            <RichTextEditor
                content={question.hint ? question.hint : ""}
                preview={false} placeholder =""
                setContent={updateHint}
              />
          </div>
        </div>

        {/* explaination sections */}

        <div className="hint-explanation-section">

          <div className="accordion">
            <div className="hints-title" >
              <MdCoPresent /> Explanation
            </div>
             <RichTextEditor
                content={question.solution ? question.solution : ""}
                preview={false}
                setContent={updateExplaination} placeholder ="" 
              />
          </div>
        </div>


        {/* video explaination */}
         {/* <div className="video-explanation">
          <div className="accordion">
            <div className="video-title">
              Video Explanation
            </div>
            { (
              <div className="explanation">
                {question.videoExplanation?.map((v, idx) => (
                  <div key={idx} className="video">
                    <video src={v} ></video>
                      <TiDelete
                        style={{ color: "#fff", background: "red" }}
                        onClick={() => removeVideo(idx)}
                      />
                    
                  </div>
                ))}
                {
                  <div className="upload">
                    <div className="upload-button" onClick={uploadVideo}>
                      <BiPlus />
                      <p>Upload a video</p>
                    </div>
                    <input
                      type="file"
                      accept="video/mp4"
                      style={{ display: "none" }}
                      ref={videoRef}
                      onChange={handleFileUpload}
                    />
                  </div>
                }
              </div>
            ) 
            }
          </div>
        </div>  */}

      

        <Button
            class='medium-btn-container'
            text={'Update Question'}
            textcolor={'dark-green'}
            bgcolor={'#09784433'}
            width={'fit-content'}
            onClick={uploadQuestion}
          />
      </div>

      <AddSuccess open={pageVar.open} message={pageVar.successMessage} close={()=> setPageVar({...pageVar, open:false})}/>
    </div>
  );
}

export default ModifyQuestion;
