import * as Congs from "../Configurations"









export function getSubjectPapers(fetchOptions) {

    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: Congs.getHeaders()
  
    };
    const url =`${Congs.BASE_URL}/api/v2/admin/papers?page=${fetchOptions.page}`
  const fetchUrl = Congs.prepareUrl(url, fetchOptions);
   
    return fetch(fetchUrl, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.error(error));
  
  }


 
  export function getExamYears(exam_id,page = 1, limit = 200) {

    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: Congs.getHeaders()
  
    };
  
   
    return fetch(`${Congs.BASE_URL}/api/v2/exams/${exam_id}/years?page=${page}&limit=${limit}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.error(error));
  
  }
  
 
  export function getPaperById( paperId) {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: Congs.getHeaders()
  
    };
  
   
    return fetch(`${Congs.BASE_URL}/api/v2/admin/papers/${paperId}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.error(error));
  }
  
 
  export function addSubjectPaper(examId, payload) {
    const requestOptions = {
      method: "POST",
      redirect: "follow",
      body: JSON.stringify(payload),
      headers: Congs.getHeaders()
  
    };
  
    return fetch(`${Congs.BASE_URL}/api/v2/exams/${examId}/papers`, requestOptions)
      .then((response) => response)
      .catch((error) => console.error(error));
  }
  
  

  export function updateSubjectPaper(exam_id,paper_id, payload) {
    const requestOptions = {
      method: "PUT",
      redirect: "follow",
      body: JSON.stringify(payload),
      headers: Congs.getHeaders()
  
    };
  
    
    return fetch(`${Congs.BASE_URL}/api/v2/exams/${exam_id}/papers/${paper_id}`, requestOptions)
      .then((response) => {
        const result = response.json()
        console.log()
        if (response["ok"]) {
          return result
        }
        else throw new Error("Error " + result.details);
      })
      .catch((error) => console.error(error));
  }
  
  
  

