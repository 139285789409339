import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button } from '../../../../components/Generic/Button/Button';
import Dropdown, { DropDownVariant } from '../../../../components/Generic/Dropdown/Dropdown';
import TextInput from '../../../../components/Generic/TextInput/TextInput';
import './add-camera.scss';
import { toast } from 'sonner';
import { useContext } from 'react';
import { GlobalStateContext } from '../../../../GlobalContext/GlobalContextProvider';
import { TextArea } from '../../../../components/Generic/TextArea/TextArea';
import { LatLong, Resolution } from '../../../../components/Generic/LatLong/LatLong';
import { ROUTES } from '../../../../components/Routes/SideBarRoutes';
import { useNavigate } from 'react-router-dom';
import { algo_settings, algo_settings_columns } from '../../../../Constants/Camera';
import BasicTimePicker, { BasicTimePickerMMSS } from '../../../../components/Generic/TimePicker/TimerPicker';
import { Switch } from 'antd';
import { getExams } from '../../../APIServices/QP/Exams';
import { addSubjectPaper } from '../../../APIServices/QP/PapersApi';
import { getSubjects } from '../../../APIServices/QP/Subjects';

export interface IAddCameraProps {
  moveToListPage: () => void;
}

export const IpV4RegEx = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const IpV6RegEx = /((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}/;

export const AddQPPaper = (props: IAddCameraProps) => {
  const { globalState: { user, gates } } = useContext(GlobalStateContext);
  const [META_DATA, setMETA_DATA] = useState(user?.application_configs);
  const navigate = useNavigate();
  const [showSettings, setShowSettings] = useState(false);

  const [exams,setExams] = useState([{id:0, code:""}])
  const [subjects,setSubjects] = useState([{id:0, code:""}])

  const [formData, setFormData] = useState({
    year: "",
    code: "",
    total_questions: 0,
    solutions_added: 0,
    is_published: false,
    is_unlocked: false,
    exam_code: "code",
    subject_code: "code",
    subject_id: null,
    exam_id: null
  });



  const [invalidFields, setInvalidFields] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);



  const validateFields = React.useCallback(async (valid: boolean, field: string) => {
    if (valid) {
      if (invalidFields.includes(field))
        setInvalidFields(Fields => {
          let filtered = Fields.filter(value => value !== field);
          return filtered;
        });
    }
    else {
      if (!invalidFields.includes(field))
        setInvalidFields(Fields => {
          Fields.push(field);
          return Fields;
        });
    }
  }, []);


  const handleClick = async () => {
    try {
      if (invalidFields.length) {
        toast.warning('Please fill all the required fields!');
        setSubmitClicked(true);
        return;
      }

  
      const payload = {
        ...formData 
           };

      const response = await addSubjectPaper(1 ,payload);
          
      if (response && response.status ==200) {
        props.moveToListPage();
        toast.success("Success: Exam added!");
        navigate(ROUTES.qp);
      }
      else throw new Error("Error");
    }
    catch (err) {
      toast.error("Error: failed to add camera!");
    }
  }



  useEffect(()=>{
    const options = {
      page: 1,
      limit: 100,
    }

    getExams(options).then(res=> setExams(res.result.results))
  },[])



  useEffect(()=>{
    console.log(exams.filter(exam=> exam.code == formData.exam_code)[0])
    const id  = exams.filter(exam=> exam.code == formData.exam_code)[0]?.id
    setFormData({
      ...formData, exam_id: id
    })
    getSubjects(id).then(res=> setSubjects(res.results))

  },[formData.exam_code])

  useEffect(()=>{
    const id  = subjects.filter(subject=> subject.code == formData.subject_code)[0]?.id
    setFormData({
      ...formData, subject_id: id
    })
  },[formData.subject_code])




  return (
    <div className={'add-camera-form-container'}>
      <div className={'title-left'}>
        Add New Paper
      </div>
      <div>
        <div className={'add-camera-form-row-1'}>
          <TextInput
            label={'Month-Year'}
            placeholder={'Enter paper month year'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, year: value })}
            value={formData.year}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          />

          <TextInput
            label={'Code'}
            placeholder={'Enter paper code'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, code: value })}
            value={formData.code}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          />



        </div>
        <div className={'add-camera-form-row-2'}>
          <Dropdown
            label={'Select exam'}
            placeholder={'Select Exam'}
            width={'30%'}
            options={exams.map(exam=> exam.code)}
            onSelect={(selection) => setFormData({ ...formData, exam_code: selection })}
            selection={formData.exam_code}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          />
          <Dropdown
            label={'Select subject'}
            placeholder={'Select Subject'}
            width={'30%'}
            options={subjects==null ? [] : subjects.map(exam=> exam.code)}
            onSelect={(selection) => setFormData({ ...formData, subject_code: selection })}
            selection={formData.subject_code}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          />

        </div>
        <div className={'add-camera-form-row-3'}>

          <div>
            <Switch
              style={{ margin: '1rem' }}
              value={formData.is_published}
              onChange={(value) => {
                setFormData({
                  ...formData,
                  is_published: value
                });
              }}
            />
            Publish
          </div>

          <div>
            <Switch
              style={{ margin: '1rem' }}
              value={formData.is_unlocked}
              onChange={(value) => {
                setFormData({
                  ...formData,
                  is_unlocked: value
                });
              }}
            />
            Locked
          </div>
        </div>

        <div className={'btn-position'}>
          <Button
            class='btn-container'
            bgcolor={'#4D4D33'}
            text={'Save'}
            textcolor={'white'}
            width={''}
            onClick={() => {
              if (invalidFields.length) {
                toast.warning('Please fill all the required fields!');
                setSubmitClicked(true);
                return;
              }
              else

                handleClick()
                setShowSettings(true)
            }}
          />
        </div>
      </div>




    </div>
  );
};


