import * as React from 'react';
import { SearchBar } from '../../../../components/Generic/SearchBar/SearchBar';
import { GenericTable } from '../../../../components/Generic/Table';
import './camera-list.scss';
import { useEffect } from 'react';
import { useState } from 'react';
import { CameraDetails } from './CameraDetailsSidePop/CameraDetailsPopUp';
import { toast } from 'sonner';
import { Filters } from '../../../../components/Generic/Filters/Filters';
import {  getSubjectPapers } from '../../../APIServices/QP/PapersApi';
import { deleteCamera } from '../../../../GPI/API/Camera';
import { ROUTES } from '../../../../components/Routes/SideBarRoutes';

export interface ICameraListProps {
  moveToListPage: () => void;
}

export const QPPaperList = (props: ICameraListProps) => {
  const [pageVars, setPageVars] = useState({
    tableData: [],
    selectedCamera: null,
    openPopUp: false,
    loading: false,
  });


  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(10);
  const [filters, setFilters] = useState({});
  const [query, setQuery] = useState(null);

  const handlePageChange = (newPage) => {
    setPage(newPage);
        console.log(newPage)

  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    console.log("newLimit")
    console.log(newLimit)
  };
  
  const tableDemoData = {
    columnDetails: [
      {
        title: 'Subject Name',
        key: 'title'
      },
      {
        title: 'Exam id',
        key: 'exam_id'
      },
      {
        title: 'Year',
        key: 'year'
      },
      {
        title: 'Paper Code',
        key: 'paper_code'
      },
      {
        title: 'Published',
        key: 'is_published'
      },
      {
        title: 'Action',
        key: "View_details",
        route: ROUTES.qpPaperDetails ,
        route_key: 'id'
      },
    ]
  }

  const fetchPapers = async (p, filters, q) => {
    try {
      setPageVars({ ...pageVars, loading: true, openPopUp: false });
      let options = {
      }

      if (filters) {
        options = {
          ...options,
          start_date: filters.start_date,
          end_date: filters.end_date,
          unit: filters.unit,
          category: filters.category,
          gate_number: filters.gate_number
        }
      }

      options = {
        ...options,
        page: page,
        limit: limit,
        query: query
      }

      const response = await getSubjectPapers(options);

      console.log(response)

      setTotalItems(response.total_results)
      let dataRows = [];
      response.results?.forEach(data => {
        const {id,subject,exam_id, year, code, is_published } = data;
        console.log(dataRows)
        dataRows = [...dataRows,
        {
          "id": id,
          "exam_id": subject.exam_id,
          "title": subject.title,
          "year": year,
          "paper_code": code,
          "is_published": is_published ? "True": "False",
          "View_details": "View Detail"
        }];
      });
      setPageVars({ ...pageVars, tableData: dataRows, loading: false, openPopUp: false });
    }
    catch (err) {
      setPageVars({ ...pageVars, loading: false, openPopUp: false });
    }

  }

  useEffect(() => {
    fetchPapers(page, filters, query);
  }, []);

  useEffect(() => {
    fetchPapers(page, filters, query);
  }, [page, limit, filters, query]);



  return (
    <div className={'camera-list-container'}>
      <div className={'visitors-list-header'}>
        <div className={'visitors-list-header-left'}>
          <div className={'title'}>
            Exams Papers List
          </div>
          <div style={{ display: 'flex', marginRight: '2rem' }}>
            <div>
              <SearchBar
                placeholder={'Search paper by name/code'}
                width={'20rem'}
                onSearch={query => setQuery(query)}
              />
            </div>
            <Filters
            unitFilter={false}
            categoryFilter={false}
            gateFilter={true}
            dateFilter={true}
            apply={(filters)=>fetchPapers(1, filters, null)}
          />
          </div>
       
        </div>
     
      </div>

      <GenericTable
        columnDetails={tableDemoData.columnDetails}
        dataRows={pageVars.tableData}
        selectedRow={(row) => setPageVars({ ...pageVars, selectedCamera: row, openPopUp: true })}
        loading={pageVars.loading}
        currentPage={page}
        totalItems={totalItems}
        initialLimit={limit}
        pagination={true}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />

      {/* {pageVars.selectedCamera &&
        <CameraDetails
          camera={pageVars.selectedCamera}
          open={pageVars.openPopUp}
          close={() => setPageVars({ ...pageVars, openPopUp: false })}
          onDelete={handleDelete}
        />} */}
    </div>
  );
}
