export const BASE_URL = "https://bytecoder.in/back-end"
// export const BASE_URL = "http://localhost:8000"
export const uniqueUserId=  'c1'

export function getHeaders(){
  let token = localStorage.getItem("stored_token");
  console.log(token)
  return  {
    'Content-Type': 'application/json',
    'token': token,
    "Authorization": "Bearer "+ token
  }
}


export const prepareUrl = (url, options) => {
  let preparingUrl = url;
  if(options?.query) preparingUrl = preparingUrl + `&q=${options.query}`;
  if(options?.examId) preparingUrl = preparingUrl + `&exam_id=${options.examId}`;
  if(options?.paperId) preparingUrl = preparingUrl + `&paper_id=${options.paperId}`;
  if(options?.year) preparingUrl = preparingUrl + `&year=${options.year}`;
  if(options?.topicId) preparingUrl = preparingUrl + `&topic_id=${options.topicId}`;
  if(options?.userId) preparingUrl = preparingUrl + `&user_id=${options.userId}`;
  if(options?.limit) preparingUrl = preparingUrl + `&limit=${options.limit}`;
  return preparingUrl;
}

export function getToken(){
  return localStorage.getItem("token")
}


export const GET_ALL_COURSES = BASE_URL+  "/api/v1/courses/"
export const GET_RECOMMENDED_COURSES = BASE_URL+  "/api/v1/upcoming-courses/"
export const GET_ALL_MENTORSHIP_COURSES = BASE_URL+  "/api/v1/courses/"

export const GET_ALL_POPULER_SHORTCUTS = BASE_URL+  "/api/v1/courses/populer-shortcuts"
export const GET_ALL_COURSE_BY_CODE = BASE_URL+  "/api/v1/courses/"
export const GET_ALL_ENROLLED_COURSES = BASE_URL+  "/api/v1/users/c1/enrolled-courses/"
export const GET_ALL_BLOGS = BASE_URL+  "/api/v1/blogs/"
export const GET_ALL_POPULER_BlOGS_SHORTCUTS = BASE_URL+  "/api/v1/blogs/populer-shortcuts"
export const GET_ALL_TESTIMONIALS = BASE_URL+ "/api/v1/placements/testimonials"
export const GET_ALL_PLACEMENT_COMPANIES = BASE_URL+ "/api/v1/placements/companies"
export const GET_ALL_TEAM_MEMBERS = BASE_URL+ "/api/v1/team-members"
export const GET_ALL_STATS = BASE_URL+  "/api/v1/placements/stats"
export const NEW_REGISTRATION = BASE_URL+  "/api/v1/users/register"
export const Login_USER = BASE_URL+  "/api/v1/users/auth/login" 
export const LOGOUT_USER = BASE_URL+  "/api/v1/logout"

export const ENROLL_COURSE_API = BASE_URL+  "/api/v1/users/c1/enrolled-courses/"
export const GET_ENROLLED_COURSE_CHAPTERS_API = BASE_URL+  "/api/v1/course/"
export const GET_ENROLLED_COURSE_PRACTICES_API = BASE_URL+  "/api/v1/course/"
export const GET_ENROLLED_COURSE_ASSIGNMENTS_API = BASE_URL+  '/api/v1/course/'
export const GET_ENROLLED_COURSE_NOTES_API = BASE_URL+  "/api/v1/course/"
export const GET_ENROLLED_COURSE_SESSIONS_API = BASE_URL+  "/api/v1/course/"
export const GET_ENROLLED_COURSE_SESSION_BY_ID_API = BASE_URL+  "/api/v1/course/"


// Header api integaration
export const GET_USER_INFO = BASE_URL + "/api/v1/users/" + uniqueUserId
export const UPDATE_USER_INFO = BASE_URL + "/api/v1/users/" + uniqueUserId

// export const chapterAssignmentEndpoint = (courseCode) => {
//   return `${BASE_URL}/api/v1/course/${courseCode}/practices`;

// job apis
export const GET_ALL_JOBS_OPENING = BASE_URL+  "/api/v1/users/c1/jobs/"
export const GET_ALL_JOBS_FILTERS = BASE_URL+  "/api/v1/users/c1/jobs/filters"
export const GET_ALL_APPLIED_JOBS = BASE_URL+  "/api/v1/users/c1/jobs/?saved=true"

//QuestionPaper Apis
export const GET_ALL_EXAMS = BASE_URL + "/api/v1/exams?page=1&limit=2";
export const GET_ALL_SUBSCRIBED_EXAMS = BASE_URL + "/api/v1/subscribed-exams?page=1&limit=2";