import * as React from 'react';
import { SearchBar } from '../../../components/Generic/SearchBar/SearchBar';
import { GenericTable } from '../../../components/Generic/Table';
import { Button } from '../../../components/Generic/Button/Button';
import './camera-list.scss';
import { deleteCamera, getCameras } from '../../../GPI/API/Camera';
import { useEffect } from 'react';
import { useState } from 'react';
import { CameraDetails } from './CameraDetailsSidePop/CameraDetailsPopUp';
import { toast } from 'sonner';
import { Filters } from '../../../components/Generic/Filters/Filters';
import { ROUTES } from '../../../components/Routes/SideBarRoutes';

export interface ICameraListProps {
}

export const FormList = (props: ICameraListProps) => {
  const [pageVars, setPageVars] = useState({
    tableData: [],
    selectedCamera: null,
    openPopUp: false,
    loading: false
  });

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(10);
  const [filters, setFilters] = useState({});
  const [query, setQuery] = useState(null);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    console.log("newPage")
    console.log(newPage)

  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    console.log("newLimit")
    console.log(newLimit)
  };

  const tableDemoData = {
    columnDetails: [
      {
        title: 'Name',
        key: 'name'
      },
      {
        title: 'Location',
        key: 'location'
      },
      {
        title: 'Model',
        key: 'model'
      },
      {
        title: 'AI Algorithm',
        key: 'ai_algorithm'
      },
      {
        title: 'Units',
        key: 'unit',
      },
      {
        title: 'IP address',
        key: 'ip_address',
        // btnRoute: 'View Live'
      },
      // {
      //   title: 'Action',
      //   key: "View_details",
      //   route: ROUTES.cameraDetails,
      //   route_key: 'id'
      // },
    ]
  }

  const fetchCameras = async (page, filters, query) => {
    try {
      setPageVars({ ...pageVars, loading: true, openPopUp: false });
      let options = {
      }

      if (filters) {
        options = {
          ...options,
          start_date: filters.start_date,
          end_date: filters.end_date,
          unit: filters.unit,
          category: filters.category,
          gate_number: filters.gate_number
        }
      }

      options = {
        ...options,
        page: page,
        limit: limit,
        query: query
      }

      const response = await getCameras(options);
      console.log(response)
      setTotalItems(response.response.totalCounts)
      let dataRows = [];
      response.response.cameraDtos?.forEach(data => {
        const {

          id, name, model, algo_settings, ip_address,
          streaming_url, image_url, unit, gate_numbers, message, location, status,
          coordinates, created_at } = data;
        console.log(dataRows)
        dataRows = [...dataRows,
        {
          "id": id,
          "name": name,
          "model": model,
          "ai_algorithm": algo_settings.is_enabled == true ? "Active" : "Disabled",
          "ip_address": ip_address,
          "streaming_url": streaming_url,
          "unit": unit,
          // "gate_numbers": gate_numbers?.join(','),
          "image_url": image_url,
          "location": location,
          "status": status,
          "coordinates": coordinates,
          "created_at": created_at,
          "View_details": 'View Details',
        }];
      });
      console.log(tableDemoData)
      setPageVars({ ...pageVars, tableData: dataRows, loading: false, openPopUp: false });
    }
    catch (err) {
      setPageVars({ ...pageVars, loading: false, openPopUp: false });
    }
  }

  const handleDelete = async (camera_id) => {
    try {
      const response = await deleteCamera(camera_id);
      if (response && response.statusCode === 200) {
        toast.success("Success: camera deleted!");
        fetchCameras(page, filters, query);
      }
      else throw new Error(response.error);

    }
    catch (err) {
      toast.error('Error: failed to delete camera!');
    }
  }
  useEffect(() => {
    fetchCameras(page, filters, query);
  }, []);

  useEffect(() => {
    fetchCameras(page, filters, query);
  }, [page, limit, filters, query]);



  return (
    <div className={'camera-list-container'}>
      <div className={'visitors-list-header'}>
        <div className={'visitors-list-header-left'}>
          <div className={'title'}>
            Cameras List
          </div>
          <div style={{ display: 'flex', marginRight: '2rem' }}>
            <div>
              <SearchBar
                placeholder={'Search camera by name'}
                width={'20rem'}
                onSearch={query => setQuery(query)}
              />
            </div>
            {/* <Filters
            unitFilter={false}
            categoryFilter={false}
            gateFilter={true}
            dateFilter={true}
            apply={(filters)=>fetchCameras(1, filters)}
          /> */}
          </div>
          {/* <div className={'visitors-list-header-update'}>
            Last updated on {new Date().toDateString()}
          </div> */}
        </div>
        {/* <div style={{display:'flex', gap:'1rem'}}>
          <Button
          class='medium-btn-container'
            bgcolor={'#09784433'} 
            textcolor={'red'}
            width={'fit-content'}
            text={'More'}
          />
        </div> */}
      </div>
      <GenericTable
        columnDetails={tableDemoData.columnDetails}
        dataRows={pageVars.tableData}
        selectedRow={(row) => setPageVars({ ...pageVars, selectedCamera: row, openPopUp: true })}
        loading={pageVars.loading}
        currentPage={page}
        totalItems={totalItems}
        initialLimit={limit}
        pagination={true}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />

      {pageVars.selectedCamera &&
        <CameraDetails
          camera={pageVars.selectedCamera}
          open={pageVars.openPopUp}
          close={() => setPageVars({ ...pageVars, openPopUp: false })}
          onDelete={handleDelete}
        />}
    </div>
  );
}
