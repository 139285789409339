
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getNewNotifications } from "../../GPI/API/Notifications";
import { GlobalDispatchContext, GlobalStateContext } from "../../GlobalContext/GlobalContextProvider";
import { ACTIONS } from "../../GlobalContext/GlobalReducer";
import { LOCAL_STORAGE_KEYS } from "../../Edtech/Login/Login";
import { ROUTES } from "../Routes/SideBarRoutes";
import "./header.scss";
import { jwtDecode } from "jwt-decode";
import { authorise_token } from "../../GPI/API/Auth";
import { toast } from "sonner";

export const Header = (props: {
  networkStatus: boolean
  sidenav: any
  setNetworkStatus: (status) => void,
  profileClicked: () => void
  appsClicked: () => void
  }) => {
  const { globalState: { user, loggedIn } } = useContext(GlobalStateContext);
  const globalDispatcher = useContext(GlobalDispatchContext);
  const [notificationCount, setNotificationCount] = useState(0);
  const navigate = useNavigate();

  const logout = async () => {
    console.log("logging out")
    const decoded = jwtDecode(user.token);
    globalDispatcher({ type: ACTIONS.setLoggedIn, booleanPayload: false });
    localStorage.removeItem(LOCAL_STORAGE_KEYS.is_logged);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.user);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.permissions);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.stored_token);
    navigate('/');
  };

  const checkForNewAlert = async (notifications) => {
    try {

      if (notifications && notifications.length) {

        console.log("new notifications")
        console.log(notifications.length)
        globalDispatcher({ type: ACTIONS.pushNotification, payload: notifications });

        if (notifications.length) {
          globalDispatcher({ type: ACTIONS.popNotification, booleanPayload: true });

          if (user.role === 'Guard')
            globalDispatcher({ type: ACTIONS.setSyncNotifications, booleanPayload: true });
        }
      }
    }
    catch (err) {

    }
  }
  const syncNotifications = async () => {
    try {

      if (!loggedIn) return;

      authorise_token().then(response => {
        if ([200, 201, 204].includes(response.statusCode)) {
          props.setNetworkStatus(true)
        } else {
          props.setNetworkStatus(false)
          // if(response.statusCode ===404){
          //       toast.error("Please login again" )
          //       logout()
          //       return
          //     }
        }
      }).catch((err) => {
        console.log(err)
        props.setNetworkStatus(false)

      })


      if (user && user.role != "Admin") {
        let options = {
          page: 1,
          gate_number: user.role === "Guard" ? user.gateNumber : null,
          user_id: user.id,
          limit: 1000
        }
        const response = await getNewNotifications(options);
        if (response && response.statusCode === 200) {

          const notifications = response.response.notificationDtos;
          console.log("new notifications")
          console.log(notifications.length)
          if (notifications) {
            checkForNewAlert(notifications);
          }
        }

      }

    }
    catch (err) {

    }

  }

  useEffect(() => {
    syncNotifications();
    const clear = setInterval(syncNotifications, 10000);
  }, []);


  return (
    <div className={"header__container"}>
      <div className={"header__title-and-profile"}>
        <div className={"header__title-profile"}>
          <div className="company-details">
            {/* <h3>Sentryfenz</h3>
            <p style={{ color: '#fcd62c' }}>Intelligent Intrusion Detection System</p> */}
          </div>

          {/* <h3 className={'header__title-heading'}> {user.company_name}</h3> */}
          <h3 className={'header__title-heading'}> Prep Knots</h3>
          {

          }
          <div style={{ display: 'flex', gap: '2rem' }}>
            {/* <div className="network-status">{props.networkStatus ? <div className="on">network ok</div> : <div className="out">network is out</div>}</div> */}

            <div style={{
              margin: 'auto',
              padding: 'auto',
              cursor: 'pointer',
              display: 'flex'
            }}
            >
              {user.role == "Guard" ? <h4 className={'header__title-heading'}>Gate Number {user.gateNumber}</h4> : <h4>{user.username}</h4>}


            </div>

            <div className={"header__profile-container"}
              onClick={() => props.appsClicked()}
            >
              <div className={"header__profile-image"}>
                <p>Dashboards</p>
              </div>
              <i className="fa-solid fa-angle-down"></i>
            </div>

            <div className={"header__profile-container"}
              onClick={() => props.profileClicked()}
            >
              <div className={"header__profile-image"}>
                <img src="/images/side_bar/profileLogo.svg" alt="profile" />
              </div>
              <i className="fa-solid fa-angle-down"></i>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};


