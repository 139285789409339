import * as React from 'react';
import { SearchBar } from '../../../Generic/SearchBar/SearchBar';
import { GenericTable } from '../../../Generic/Table';
import { Button } from '../../../Generic/Button/Button';
import './user-list.scss';
import { useEffect } from 'react';
import { useState } from 'react';
import { CameraDetails } from './UserDetailsSidePop/UnitDetailsPopUp';
import { toast } from 'sonner';
import { deleteUnit, getunits, getusers } from '../../../../GPI/API/Configs';

export interface ICameraListProps {
}

export const UserList = (props: ICameraListProps) => {
  const [pageVars, setPageVars] = useState({
    tableData: [],
    selectedCamera: null,
    openPopUp: false,
    loading: false
  });

    const tableDemoData = {
        columnDetails: [
          {
            title: 'Username',
            key: 'username'
          },
          {
            title: 'Role',
            key: 'role'
          },
          {
            title: 'Gate number',
            key: 'gate_number'
          },
          {
            title: 'Notification Enable',
            key: 'is_notification_enabled'
          }
        ]
      }
      
    const fetchUnits = async () => {
        try {
            setPageVars({...pageVars, loading: true, openPopUp: false});
            let options = {
              page: 1
            }
            const response = await getusers(options);
            let dataRows = [];
            response.response?.forEach( data => {
              const {

                user_id, username, role,
                  gate_number, is_notification_enabled} = data;
    
              dataRows = [...dataRows, 
                {
                  "id": user_id,
                  "username": username,
                  "role": role,
                  "gate_number": gate_number,
                  "is_notification_enabled": is_notification_enabled? "True": "False"
                }];
            });
            setPageVars({...pageVars, tableData: dataRows, loading: false, openPopUp: false});
        }
        catch(err) {
          setPageVars({...pageVars, loading: false, openPopUp: false});
        }
      }

      const handleDelete = async (camera_id) => {
        try {
          const response = await deleteUnit(camera_id);
          if(response && response.statusCode === 200) {
            toast.success("Success: Unit deleted!");
            fetchUnits();
          }
          else throw new Error(response.error);
          
        }
        catch(err) {
          toast.error('Error: failed to delete Unit!');
        }
      }
    useEffect(()=>{
      fetchUnits();
    },[]);


    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const totalItems = 100; 
  
    const handlePageChange = (newPage) => {
      setPage(newPage);
     
    };
  
    const handleLimitChange = (newLimit) => {
      setLimit(newLimit);
    };
    
  return (
    <div className={'camera-list-container'}>
      <div className={'visitors-list-header'}>
        <div className={'visitors-list-header-left'}>
          <div className={'title'}>
            Users List
          </div>
      
        </div>
  
      </div>
      <GenericTable
        columnDetails={tableDemoData.columnDetails}
        dataRows={pageVars.tableData}
        selectedRow={(row)=>setPageVars({...pageVars, selectedCamera: row, openPopUp: true})}
        loading={pageVars.loading}
        totalItems={totalItems}
        initialLimit={limit}
        pagination={true}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />
      
      {pageVars.selectedCamera && 
      <CameraDetails
        camera={pageVars.selectedCamera}
        open={pageVars.openPopUp}
        close={()=>setPageVars({...pageVars, openPopUp: false})}
        onDelete={handleDelete}
      />}
    </div>
  );
}
