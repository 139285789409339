import React, { useContext, useEffect, useState } from "react";
import "./QPExamDetailsManagement.scss"; // Optional CSS for styling
import { QPExamSubjectsDetails } from "./ExamOverview/SubjectDetails";
import SubscriptionManagement from "./SubscriptionManagement/SubscriptionManagement";
import { QPExamSubscribers } from "./ExamSubscribers/CameraDetails";
import { TopicwiseDetails } from "./TopicswiseDetails/TopicwiseDetails";
import { GlobalStateContext } from "../../../../../GlobalContext/GlobalContextProvider";

export default function QPExamDetailsTabs() {


  // Handler to change the active tab
  const handleTabClick = (route) => {
    setPageVariables({selectedRoute: route})
    
  };


  const { globalState: { permissions } } = useContext(GlobalStateContext);

      const sidePanelRoutes = [
        {
          id: '#1',
          title: 'Overview',
          component: <QPExamSubjectsDetails />,
          accessPermission: 'CAMERA_ALL'
        },
        {
          id: '#2',
          title: 'TopicsDetails',
          component: <TopicwiseDetails/>,
          accessPermission: 'CAMERA_ADD'
        },
        {
          id: '#3',
          title: 'Subscriptions Managment',
          component: <SubscriptionManagement/>,
          accessPermission: 'CAMERA_ADD'
        }
      ];
  
    
      const [pageVariables, setPageVariables] = React.useState({
        selectedRoute: sidePanelRoutes[0]
      })

    useEffect(() => {
      document.title = 'Cameras'
    }, []);

  return (
    <div className="tabs-container">
    {/* Header Tab Bar */}
    <div className="tabs-header">

      {sidePanelRoutes.map((route,index) => {
        return [...permissions].includes(route.accessPermission) &&  (
          <div
          className={`tab-item ${pageVariables.selectedRoute.id === route.id ? "active" : ""}`}
          onClick={() => handleTabClick(route)}
          >
            {route.title}
          </div>
        )
      }

      )}
    </div>


    {pageVariables.selectedRoute.component}


  </div>
  );
}


