import React, { useEffect, useState } from "react";
import "./AddPlan.scss";
// import { CgClose } from "react-icons/cg";
// import { FaPlus, FaToggleOff, FaToggleOn, FaTrash } from "react-icons/fa";
import { addExamPlan } from "../../../../../../APIServices/QP/ExamPlans";
import TextInput from "../../../../../../../components/Generic/TextInput/TextInput";
import { Button } from "../../../../../../../components/Generic/Button/Button";
import { toast } from "sonner";
import { addTopic } from "../../../../../../APIServices/QP/Topics";
import { getSubjects } from "../../../../../../APIServices/QP/Subjects";
import ObjectsDropdown from "../../../../../../../components/Generic/ObjectsDropdown/ObjectsDropdown";


function AddTopic(props: { examId: any, setShowAddPopup: any }) {


  const form = {
    subject_id: null,
    topic_name: "",
    topic_image: "",
    is_unlocked: false
  }

  interface Item {
    id: number;      // or string, depending on your needs
    option: string;    // or any other property you want
  }


  const [formData, setFormData] = useState<any>(form);

  const [subjects, setSubjects] = useState([]);
  const [items, setItems] = useState<Item[]>([]);

  

  const [invalidFields, setInvalidFields] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);

  const validateFields = React.useCallback(async (valid: boolean, field: string) => {
    if (valid) {
      if (invalidFields.includes(field))
        setInvalidFields(Fields => {
          let filtered = Fields.filter(value => value !== field);
          return filtered;
        });
    }
    else {
      if (!invalidFields.includes(field))
        setInvalidFields(Fields => {
          Fields.push(field);
          return Fields;
        });
    }
  }, []);



  const saveChanges = () => {

    if (invalidFields.length) {
      toast.warning('Please fill all the required fields!');
      setSubmitClicked(true);
      return;
  }
    const payload = {
      topic_name: formData.topic_name,
      topic_image: "https://www.freepik.com/free-vector/classic-variety-colorful-molecules_1312422.htm#fromView=search&page=1&position=0&uuid=1bd513df-3e4b-42df-a61e-c1d9e8f607b9",
      is_unlocked: false
    }
  
    addTopic(formData.subject_id, payload).then(res=>{
      console.log(res)
      props.setShowAddPopup()
    })
  }


  useEffect(() => {
    getSubjects(props.examId, null).then(resp => resp.results).then(result=>{
      setSubjects(result)
    })


  }, [])


  useEffect(() => {
    const items: Item[] = subjects.map(subject => ({
      id: subject.id,
      option: subject.title
    }));
    setItems(items)
  }, [subjects])



  return (
    <div className="add-plan-container">
      <div className="heading">
        <h3>Add Topic</h3>
        <div className="close" onClick={() => props.setShowAddPopup()} > X </div>
      </div>
          
      <div className="add-camera-form-row-2">
          <ObjectsDropdown
            label={'Select exam'}
            placeholder={'Select Exam'}
            width={'80%'}
            options={items}
            onSelect={(selection) => setFormData({...formData, subject_id: selection})}
            selection={formData.plan_name}
            // isRequired={true}
            // isValid={validateFields}
            // showWarning={submitClicked}
          />
      </div>

   

      <div className="add-camera-form-row-2">
        <TextInput
              label={'Topic '}
              placeholder={'Enter topic title'}
              width={'90%'}
              onChange={(value) => setFormData({ ...formData, topic_name: value })}
              value={formData.topic_name}
              isRequired={true}
              isValid={validateFields}
              showWarning={submitClicked}
            />
      </div>

      <div className="add-camera-form-row-3">
          <Button
                class='btn-container'
                bgcolor={'#02720d'}
                text={'Add Topic'}
                textcolor={'white'}
                width={''}
                fontSize={'1rem'}
                onClick={() => {
                    saveChanges()
                }}
          
          ></Button>
      </div>

    
     
    </div>
  );
}

export default AddTopic;
