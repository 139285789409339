import React, { useContext, useEffect } from "react";
import "./QPPaperDetailsManagement.scss";
import QuestionsList from "./QuestionManagement/QuestionsList";
import { QPPaperOverview } from "./PaperOverview/CameraDetails";
import AddQuestion from "./QuestionManagement/AddQuestion/AddQuestion";
import { GlobalStateContext } from "../../../../../GlobalContext/GlobalContextProvider";

export default function QPPaperDetailsTabs() {


  // Handler to change the active tab
  const handleTabClick = (route) => {
    setPageVariables({selectedRoute: route})
    
  };


  const { globalState: { permissions } } = useContext(GlobalStateContext);

      const sidePanelRoutes = [
        {
          id: '#1',
          title: 'Questions list',
          component: <QuestionsList />,
          accessPermission: 'CAMERA_ALL'
        },
        {
          id: '#2',
          title: '+ Add question',
          component: <AddQuestion/>,
          accessPermission: 'CAMERA_ADD'
        }
      ];
  
    
      const [pageVariables, setPageVariables] = React.useState({
        selectedRoute: sidePanelRoutes[0]
      })

    useEffect(() => {
      document.title = 'Cameras'
    }, []);


  return (
    <div className="tabs-container">
      {/* Header Tab Bar */}
      <div className="tabs-header">

        {sidePanelRoutes.map((route,index) => {
          return [...permissions].includes(route.accessPermission) &&  (
            <div
            className={`tab-item ${pageVariables.selectedRoute.id === route.id ? "active" : ""}`}
            onClick={() => handleTabClick(route)}
            >
              {route.title}
            </div>
          )
        }

        )}
      </div>
  
  
      {pageVariables.selectedRoute.component}


    </div>
  );
}


