import * as React from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllSubscriptionsOfUser, getAllUsersSubscriptions } from '../../../../APIServices/UsersApi';
import { GenericTable } from '../../../../../components/Generic/Table';
import { Filters } from '../../../../../components/Generic/Filters/Filters';
import { SearchBar } from '../../../../../components/Generic/SearchBar/SearchBar';

export interface IVisitorDetailsProps {
}

export function QPExamSubscriptions(props: IVisitorDetailsProps) {
  const [query, setQuery] = useState("");
  const navigate = useNavigate()
  const {user_id} = useParams();


  const [pageVariables, setPageVariables] = useState({
    selectedProfile: null,
    tableData: [],
    loading: false
  });

  const tableDemoData = {
    columnDetails: [
      {
        title: 'Exam name',
        key: 'exam_name'
      },
      {
        title: 'Plan name',
        key: 'plan_name'
      },
      {
        title: 'End date',
        key: 'end_date'
      },
      {
        title: 'Enable',
        key: 'is_active'
      }
    ]
  }




  const fetchSubscribersList = async (filters, page, query) => {
    let options = {
    }
    if (filters) {
      options = {
        ...options,
        start_date: filters.start_date,
        end_date: filters.end_date,
        unit: filters.unit,
        category: filters.category,
      }
    }

    options = {
      ...options,
      page: page,
      query: query
    }
    setPageVariables({ ...pageVariables, loading: true });
    try {
      const response = await getAllSubscriptionsOfUser(user_id);
      // prepare rows
      // setTotalVisitors(response.response.total_visitors)
      
      let dataRows = [];
      
      response.results.forEach(data => {
        
        const { id, user_id, plan_id, plan_name, exam_details, start_date, end_date, is_active } = data;
        console.log(data)
        
        dataRows = [...dataRows,
        {
          "id": id,
          "plan_id": plan_id,
          "user_id": user_id,
          "plan_name": plan_name,
          "end_date": end_date,
          "exam_name": exam_details.title,
          "is_active": is_active ? "Yes" : "No",

        }
        ];

      })
      
      setPageVariables({ ...pageVariables, tableData: dataRows, loading: false });
    }
    catch (err) {
      setPageVariables({ ...pageVariables, loading: false });
    }
  }

  React.useEffect(() => {

    fetchSubscribersList(null, 1, null)
  }, [])



  return (
    <div>
      <div className={'visitor-details-container'}>

        <div className={'overview-container'}>

     
          <div className={'activities-list'}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Subscriptions</div>
              <div className={'visitors-profile-header-left'}>

              </div>
              <div>
                <div style={{ display: 'flex' }}>
                  {/* <div>
                    <SearchBar
                      placeholder={'Search camera by name'}
                      width={'20rem'}
                      onSearch={query => setQuery(query)}
                    />
                  </div>
                  <Filters
                    activityFilter={true}
                    gateFilter={true}
                    dateFilter={true}
                    apply={(filters) => fetchSubscribersList(filters, null, null)}
                  /> */}
                </div>
              </div>
            </div>
            <div className={'activity-card'} >
              <GenericTable
                columnDetails={tableDemoData.columnDetails}
                dataRows={pageVariables.tableData}
                selectedRow={(row) => console.log(row)}
                loading={pageVariables.loading}
                totalItems={100}
                initialLimit={100}
                pagination={true}
                onPageChange={null}
                onLimitChange={null}
              />
            </div>
          </div>



        </div>



      </div>

    </div>
  );
}
