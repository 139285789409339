import React, { useState } from "react";
import "./DetailsTab.scss"; // Optional CSS for styling
import { QPExamSubscriptions } from "./ExamSubscribers/CameraDetails";

export default function CandidateDetailsTabs() {
  // State to track the active tab
  const [activeTab, setActiveTab] = useState("Subscriptions");

  // Handler to change the active tab
  const handleTabClick = (tab) => {
    
    setActiveTab(tab);
    console.log(activeTab)
  };

  const tabs = [
    { title: "Subscriptions", component: <QPExamSubscriptions/>, nav: "Subscriptions" },

  ]

  return (
    <div className="tabs-container">
      {/* Header Tab Bar */}
      <div className="tabs-header">

        {tabs.map((tab,index) => {
          return (
            <div
            className={`tab-item ${activeTab === tab.nav ? "active" : ""}`}
            onClick={() => handleTabClick(tab.nav)}
            >
              {tab.title}
            </div>
          )
        }

        )}
      </div>

      {activeTab == 'Subscriptions' &&<QPExamSubscriptions/>}


    </div>
  );
}


