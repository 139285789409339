import * as Congs from "../Configurations"


// exams crud apis

export function getExams(fetchOptions) {

  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: Congs.getHeaders()

  };

  const url = `${Congs.BASE_URL}/api/v2/exams?page=${fetchOptions.page}`
  const fetchUrl = Congs.prepareUrl(url, fetchOptions);

  return fetch(fetchUrl, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));

}



export function getExamsById(examId) {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: Congs.getHeaders()

  };

  
  return fetch(`${Congs.BASE_URL}/api/v2/exams/${examId}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}


export function addExam(payload) {
  const requestOptions = {
    method: "POST",
    redirect: "follow",
    body: JSON.stringify(payload),
    headers: Congs.getHeaders()

  };

  
  return fetch(`${Congs.BASE_URL}/api/v2/exams`, requestOptions)
    .then((response) => response)
    .catch((error) => console.error(error));
}



export function deleteExam(exam_id) {
  const requestOptions = {
    method: "DELETE",
    redirect: "follow",
    headers: Congs.getHeaders()

  };

  
  return fetch(`${Congs.BASE_URL}/api/v2/exams/${exam_id}`, requestOptions)
    .then((response) => response)
    .catch((error) => console.error(error));
}


export function updateExam(exam_id, payload) {
  const requestOptions = {
    method: "PUT",
    redirect: "follow",
    body: JSON.stringify(payload),
    headers: Congs.getHeaders()

  };

  
  return fetch(`${Congs.BASE_URL}/api/v2/exams/${exam_id}`, requestOptions)
    .then((response) => response)
    .catch((error) => console.error(error));
}




export function getExamStats(examId) {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: Congs.getHeaders()
  
    };
  
    
    return fetch(`${Congs.BASE_URL}/api/v2/exams/${examId}/plan-stats?limit=10&page=1`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.error(error));
  }
  
  
  