import "./App.scss";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Link,
  Navigate,
} from "react-router-dom";
import { SideBar } from "./components/SideBar/SideBar";
import { Header } from "./components/Header/Header";
import { ecommerceSideNavBarRoutes, edtechSideNavBarRoutes, iotSideNavBarRoutes, kaleidoViewSideNavBarRoutes, ROUTES, serviceProvidorSideNavBarRoutes, sideNavBarRoutes } from "./components/Routes/SideBarRoutes";

import { CameraManagement } from './GPI/CameraManagement/CameraManagenet';
import { EditCamera } from './GPI/CameraManagement/EditCamera/EditCamera';
import { CameraDetails } from "./GPI/CameraManagement/CameraDetails/CameraDetails";
import { EditGate } from "./GPI/GateManagement/EditGate/EditGate";
import { GateDetails } from "./GPI/GateManagement/GateDetails/GateDetails";
import { GateManagement } from "./GPI/GateManagement/GateManagement";
import { getAllGates } from "./GPI/API/Gates";
import { getCompany } from "./GPI/API/Company";


import { CandidateDetails } from './Edtech/CandidateManagement/CandidateDetails/CandidateDetails';
import { LOCAL_STORAGE_KEYS, Login } from './Edtech/Login/Login';
import { CandidateManagement } from "./Edtech/CandidateManagement/CandidateManagement";
import { EditCandidate } from './Edtech/CandidateManagement/EditCandidate/EditCandidate';
import { QPManagement } from "./Edtech/QPManagement/QPManagenet";
import { QPExamDetails } from "./Edtech/QPManagement/ExamsManagement/QPExamDetails/QPExamDetails";
import { QPPaperDetails } from "./Edtech/QPManagement/QPPaperManagement/QPPaperDetails/QPPaperDetails";
import { EditQPExam } from "./Edtech/QPManagement/ExamsManagement/EditQPExam/EditQPExam";
import { EditQPPaper } from "./Edtech/QPManagement/QPPaperManagement/EditQPPaper/EditQPPaper";
import QuestionViewer from "./Edtech/QPManagement/QPPaperManagement/QPPaperDetails/QPPaperDetailsTab/QuestionManagement/QuestionViewer/QuestionViewer";
import { FormManagement } from "./Edtech/FormManagement/FormManagenet";
import { EdtechConfigsManagement } from "./Edtech/EdtechConfigManagement/EdtechConfigsManagenet";
import { EdtechDashboardManagement } from "./Edtech/EdTechDashboardManagement/DashboardManagenet";



// import { CandidateDetails } from './KaleidoView/CustomersManagement/CandidateDetails/CandidateDetails';
// import { LOCAL_STORAGE_KEYS, Login } from './KaleidoView/Login/Login';
// import { CandidateManagement } from "./KaleidoView/CustomersManagement/CandidateManagement";
// import { EditCandidate } from './KaleidoView/CustomersManagement/EditCandidate/EditCandidate';
// import { QPManagement } from "./KaleidoView/KaleidoViewManagement/QPManagenet";
// import { QPExamDetails } from "./KaleidoView/KaleidoViewManagement/ExamsManagement/QPExamDetails/QPExamDetails";
// import { QPPaperDetails } from "./KaleidoView/KaleidoViewManagement/QPPaperManagement/QPPaperDetails/QPPaperDetails";
// import { EditQPExam } from "./KaleidoView/KaleidoViewManagement/ExamsManagement/EditQPExam/EditQPExam";
// import { EditQPPaper } from "./KaleidoView/KaleidoViewManagement/QPPaperManagement/EditQPPaper/EditQPPaper";
// import QuestionViewer from "./KaleidoView/KaleidoViewManagement/QPPaperManagement/QPPaperDetails/QPPaperDetailsTab/QuestionManagement/QuestionViewer/QuestionViewer";
// import { EdtechConfigsManagement } from "./KaleidoView/KviewConfigManagement/EdtechConfigsManagenet";
// import { EdtechDashboardManagement } from "./KaleidoView/KviewDashboardManagement/DashboardManagenet";




import { GlobalDispatchContext, GlobalStateContext } from './GlobalContext/GlobalContextProvider';
import { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { ACTIONS } from "./GlobalContext/GlobalReducer";
import { toast, Toaster } from 'sonner';
import { UserCompany } from './components/UserCompany/UserCompany';
import { ConfigsManagement } from "./components/ConfigManagement/ConfigsManagenet";
import { NotificationAcknowledgement } from "./components/NotificationAck/NotificationAcknowledgementPop";
import { EditCategory } from "./components/ConfigManagement/Category/EditCategory/EditCategory";
import { EditUnit } from "./components/ConfigManagement/Unit/EditUnit/EditUnit";
import { EditUser } from "./components/ConfigManagement/Users/EditUser/EditUser";
import { DashboardManagement } from "./components/DashboardManagement/DashboardManagenet";
import { NotificationManagement } from './components/NotificationManagement/NotificationsManagement';
import ModifyQuestion from "./Edtech/QPManagement/QPPaperManagement/QPPaperDetails/QPPaperDetailsTab/QuestionManagement/EditQuestion/ModifyQuestion";
import { QuestionsTaggingManagement } from "./Edtech/QuestionTaggingManagement/QuestionsTaggingManagement";
import { BlogsManagement } from "./Edtech/BlogsManagement/BlogsManagement";





const apps = [
  {
    name: 'IOT Admin',
    navbar: iotSideNavBarRoutes
  },
  {
    name: 'GPI Admin',
    navbar: sideNavBarRoutes
  },
  {
    name: 'E-coomerce Admin',
    navbar: ecommerceSideNavBarRoutes
  },
  {
    name: 'EdTech Admin',
    navbar: edtechSideNavBarRoutes
  },
  {
    name: 'Service Provide Admin',
    navbar: serviceProvidorSideNavBarRoutes
  },
  {
    name: 'KaleidoView Admin',
    navbar: kaleidoViewSideNavBarRoutes
  }
]

function App() {
  const { globalState: { loggedIn, user, popNotificationAlert } } = useContext(GlobalStateContext);
  const globalDispatcher = useContext(GlobalDispatchContext);
  const [pageVariables, setPageVariables] = useState({
    showLogOutMenu: false,
    showDashboardMenu: false,
    showNotificationPop: false,
    showAppsPop: false,
  });

  const [sidenav, setSideNav] = useState(apps[3])


  const userDropdown = useRef(null);

  const [networkStatus, setNetworkStatus] = useState(false)


  const findOutsideClick = (event) => {
    if (userDropdown.current && !userDropdown.current.contains(event.target)) {
      setPageVariables({ ...pageVariables, showLogOutMenu: false });
    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", findOutsideClick);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", findOutsideClick);
    };
  }, [userDropdown]);

  useEffect(() => {
    if (loggedIn && popNotificationAlert) {
      setPageVariables({ ...pageVariables, showNotificationPop: true });
    }
    else setPageVariables({ ...pageVariables, showNotificationPop: false });
  }, [popNotificationAlert]);

  const logout = async () => {
    setPageVariables({ ...pageVariables, showLogOutMenu: !pageVariables.showLogOutMenu });
    globalDispatcher({ type: ACTIONS.setLoggedIn, booleanPayload: false });
    localStorage.removeItem(LOCAL_STORAGE_KEYS.is_logged);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.user);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.permissions);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.stored_token);
  }

  useEffect(() => {
    let logged_in = localStorage.getItem(LOCAL_STORAGE_KEYS.is_logged);


    if (logged_in === 'true') {
      const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.user));
      const permissions = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.permissions))
      globalDispatcher({ type: ACTIONS.setLoggedIn, booleanPayload: true });
      globalDispatcher({ type: ACTIONS.setLoggedInUser, payload: user });
      globalDispatcher({ type: ACTIONS.setPermissions, payload: permissions });
    }
  }, []);
  const setGates = async () => {
    try {
      const response = await getAllGates({ page: 1 });
      if (response && response.statusCode === 200) {
        globalDispatcher({ type: ACTIONS.setGates, payload: response.response.gateDtos });
      }

      const responseCompany = await getCompany(user.company_id);
      if (responseCompany && responseCompany.statusCode === 200) {
        globalDispatcher({ type: ACTIONS.setCompany, payload: responseCompany.response });
      }
    }
    catch (err) {
      toast.error("Error: failed to fetch gates!");
    }
  }

  const navigateToCompanyDetails = () => {
    setPageVariables({ ...pageVariables, showLogOutMenu: !pageVariables.showLogOutMenu });
  }

  useEffect(() => {
    if (loggedIn) setGates();
  }, [loggedIn]);

  return (
    <div className="App">
      <Toaster position="top-right" duration={3000} />
      {
        loggedIn ?
          <Router>
            <SideBar sideNavBarRoutes={sidenav.navbar} />


            <div style={{ paddingLeft: '6rem', width: '100%' }}>
              <Header sidenav = {sidenav} networkStatus={networkStatus} setNetworkStatus={(status) => setNetworkStatus(status)} profileClicked={() => setPageVariables({ ...pageVariables, showLogOutMenu: !pageVariables.showLogOutMenu })} appsClicked={() => setPageVariables({ ...pageVariables, showAppsPop: !pageVariables.showAppsPop })} />


              {/* {pageVariables.showAppsPop &&
                <div className={'drop-down-menus'} ref={userDropdown}>
                    {
                      apps.map((app,index)=>{
                        return (<div onClick={() => {
                          setSideNav(app)
                          setPageVariables({ ...pageVariables, showAppsPop: !pageVariables.showAppsPop })
                          }}>
                          {app.name}
                        </div>)
                      })
                    }
                </div>} */}

              {pageVariables.showLogOutMenu &&
                <div className={'drop-down-menus'} ref={userDropdown}>
                  <div>{user?.username}</div>

                  {user && (user.role === "Admin") && <Link to={ROUTES.user} style={{ textDecoration: 'none' }}>
                    <div onClick={navigateToCompanyDetails}>{'Company'}</div>
                  </Link>}
                  <Link to={'/'} style={{ textDecoration: 'none', color: '#f77f7f' }}>
                    <div
                      style={{ color: '#f77f7f' }}
                      onClick={logout}
                    >
                      Logout
                    </div>
                  </Link>
                </div>}

              <div style={{ paddingTop: '4.5rem', width: '100%' }}>

                  {sidenav.name == "GPI" &&
                  <Routes>
                  <Route path={ROUTES.home} element={<DashboardManagement />} /> 
                  <Route path={ROUTES.visitor} element={<CandidateManagement />} />
                  <Route path={ROUTES.gates} element={<GateManagement />} />
                  <Route path={ROUTES.cameras} element={<CameraManagement />} />
                  <Route path={ROUTES.configs} element={<ConfigsManagement />} />
                  <Route path={ROUTES.notifications} element={<NotificationManagement />} />
                  <Route path={ROUTES.visitorDetails + '/:visitor_id'} element={<CandidateDetails />} />
                  <Route path={ROUTES.gateDetails + '/:gate_number'} element={<GateDetails />} />
                  <Route path={ROUTES.updateVisitor + '/:visitor_id'} element={<EditCandidate />} />
                  <Route path={ROUTES.updateGate + '/:gate_id'} element={<EditGate />} />
                  <Route path={ROUTES.updateCamera + '/:camera_id'} element={<EditCamera />} />
                  <Route path={ROUTES.cameraDetails + '/:camera_id'} element={<CameraDetails />} />
                  <Route path={ROUTES.updateCategory + '/:id'} element={<EditCategory />} />
                  <Route path={ROUTES.updateUnit + '/:id'} element={<EditUnit />} />
                  <Route path={ROUTES.updateUser + '/:id'} element={<EditUser />} />
                  <Route path={ROUTES.user} element={<UserCompany />} />
                  <Route path="/" element={<Navigate to={ROUTES.visitor} />} /> 
                  </Routes>
                  }


{/* Edtech portal */}
                  {true && 
                    <Routes>
                    <Route path={ROUTES.home} element={<EdtechDashboardManagement />} />
                    <Route path={ROUTES.edtechDashboard} element={<EdtechDashboardManagement />} />
                    <Route path={ROUTES.candidate} element={<CandidateManagement />} />
                    <Route path={ROUTES.candidateDetails + '/:user_id'} element={<CandidateDetails />} />
                    <Route path={ROUTES.qp} element={<QPManagement />} />
                    <Route path={ROUTES.updateQPExam + '/:exam_id'} element={<EditQPExam />} />
                    <Route path={ROUTES.qpDetails + '/:exam_id'} element={<QPExamDetails />} />
                    <Route path={ROUTES.qpPaperDetails + '/:paper_id'} element={<QPPaperDetails />} />
                    <Route path={ROUTES.updatePaper + '/:paper_id'} element={<EditQPPaper />} />
                    <Route path={ROUTES.qpPaperDetails + '/:paper_id' + ROUTES.questionDetails + '/:question_id'} element={<QuestionViewer />} />
                    <Route path={ROUTES.qpPaperDetails + '/:paper_id' + "/modify-question" + '/:question_id'} element={<ModifyQuestion />} />
                    <Route path={ROUTES.edtechConfigs} element={<EdtechConfigsManagement />} />
                    <Route path={ROUTES.enquiryForms} element={<FormManagement />} />
                    <Route path={ROUTES.user} element={<UserCompany />} />

                    <Route path={ROUTES.blogs} element={<BlogsManagement />} />
                    {/* <Route path={ROUTES.notes} element={< />} /> */}
                    <Route path={ROUTES.questionTagging} element={<QuestionsTaggingManagement />} />


                  </Routes>

                  }
                  {/* #097844 */}


{/* Kaleido view  */}
              { false && 
                <Routes>
                    <Route path={ROUTES.home} element={<EdtechDashboardManagement />} />
                    <Route path={ROUTES.edtechDashboard} element={<EdtechDashboardManagement />} />
                    <Route path={ROUTES.candidate} element={<CandidateManagement />} />
                    <Route path={ROUTES.candidateDetails + '/:user_id'} element={<CandidateDetails />} />
                    <Route path={ROUTES.qp} element={<QPManagement />} />
                    <Route path={ROUTES.updateQPExam + '/:exam_id'} element={<EditQPExam />} />
                    <Route path={ROUTES.qpDetails + '/:exam_id'} element={<QPExamDetails />} />
                    <Route path={ROUTES.qpPaperDetails + '/:paper_id'} element={<QPPaperDetails />} />
                    <Route path={ROUTES.updatePaper + '/:paper_id'} element={<EditQPPaper />} />
                    <Route path={ROUTES.questionDetails + '/:question_id'} element={<QuestionViewer />} />
                    <Route path={ROUTES.edtechConfigs} element={<EdtechConfigsManagement />} />
                    <Route path={ROUTES.user} element={<UserCompany />} />

                  </Routes>
                }
             



              </div>
            </div>
          </Router>
          :
          <Login />
      }
      {(loggedIn && pageVariables.showNotificationPop && user && user.is_notification_enabled && networkStatus) && <NotificationAcknowledgement />}
    </div>
  );
}

export default App;
