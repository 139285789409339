import * as Congs from "./Configurations"


// exams crud apis

export function getCandidates(fetchOptions) {

  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: Congs.getHeaders()

  };

  const url =`${Congs.BASE_URL}/api/v1/users?page=${fetchOptions.page}`
  const fetchUrl = Congs.prepareUrl(url, fetchOptions);

  
  return fetch(fetchUrl, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));

}


export function getAdminUsers(fetchOptions) {

  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: Congs.getHeaders()

  };

  const url =`${Congs.BASE_URL}/api/v1/users/admin-users?page=1`
  const fetchUrl = Congs.prepareUrl(url, fetchOptions);

  
  return fetch(fetchUrl, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));

}



export function getAdminUserRoles(fetchOptions) {

  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: Congs.getHeaders()

  };

  const url =`${Congs.BASE_URL}/api/v1/users/roles?page=1`
  const fetchUrl = Congs.prepareUrl(url, fetchOptions);

  
  return fetch(fetchUrl, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));

}



export function addAdminUser(payload) {

  const requestOptions = {
    method: "POST",
    redirect: "follow",
    body: JSON.stringify(payload),
    headers: Congs.getHeaders()

  };


  

  
  return fetch(`${Congs.BASE_URL}/api/v1/users/register`, requestOptions)
    .then((response) => response)
    .catch((error) => console.error(error));

}




export function getUserById(user_id) {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: Congs.getHeaders()

  };

  
  return fetch(`${Congs.BASE_URL}/api/v1/users/${user_id}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}


export function addUser(payload) {
  const requestOptions = {
    method: "POST",
    redirect: "follow",
    body: JSON.stringify(payload),
    headers: Congs.getHeaders()

  };

  
  return fetch(`${Congs.BASE_URL}/api/v2/users`, requestOptions)
    .then((response) => response)
    .catch((error) => console.error(error));
}



export function deleteUser(user_id) {
  const requestOptions = {
    method: "DELETE",
    redirect: "follow",
    headers: Congs.getHeaders()

  };

  
  return fetch(`${Congs.BASE_URL}/api/v2/users/${user_id}`, requestOptions)
    .then((response) => response)
    .catch((error) => console.error(error));
}


export function updateUser(user_id, payload) {
  const requestOptions = {
    method: "PUT",
    redirect: "follow",
    body: JSON.stringify(payload),
    headers: Congs.getHeaders()

  };

  
  return fetch(`${Congs.BASE_URL}/api/v2/exams/${user_id}`, requestOptions)
    .then((response) => response)
    .catch((error) => console.error(error));
}




// exams crud apis

export function getAllUsersSubscriptions(page = 1, limit = 200) {

    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: Congs.getHeaders()
  
    };
  
    
    return fetch(`${Congs.BASE_URL}/api/v2/admin/subscriptions?page=${page}&limit=${limit}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.error(error));
  
  }
  



export function getAllSubscriptionsOfUser(user_id, page = 1, limit = 200) {

  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: Congs.getHeaders()

  };

  
  return fetch(`${Congs.BASE_URL}/api/v2/users/${user_id}/exam-subscriptions?is_active=true&page=${page}&limit=${limit}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));

}


      

  