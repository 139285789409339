
import './company-component.scss'
export default function CompanyComponent() {

    return (
        <div className='company-component'>

            <div>
                <img src="/images/edtech/logo.svg" />
            </div>
            <div>
            </div>


        </div>
    )
}