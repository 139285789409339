import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import parse from "html-react-parser";
import React from "react";
import "./RichTextEditor.scss";
import theme from "quill/core/theme";

Quill.register("modules/imageResize", ImageResize);

const modules = {
  toolbar: [
    [{ header: ['p'] },  { font: [] }],
    [{ color: [] }, { background: [] }],

    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],

  ],
  clipboard: {
    matchVisual: false,
  },
  imageResize: {
    modules: ["Resize", "DisplaySize"],
  },
};

const RichTextEditor = (props:{setContent:any,placeholder:any,preview: any, content: any }) => {
  return (
    <div className="text-editor">
      {!props.preview ? (
        <div className="editor">
          <ReactQuill
            // theme= "snow"

            value={props.content}
            onChange={props.setContent}
            modules={modules}
            placeholder={"start writing"}
          />
        </div>
      ) : (
        <div className="Preview">
          {parse(props.content)}
        </div>
      )}
    </div>
  );
};

export default RichTextEditor;
