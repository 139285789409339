
import { useEffect, useState } from "react";
import "./Questions.scss";
// import { BiArrowToRight } from "react-icons/bi";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import RichTextEditor from "../../../../../../components/TextEditor/RichTextEditor";
import { getQuestions } from "../../../../../APIServices/QP/QuestionPaper";
import { getPaperById } from "../../../../../APIServices/QP/PapersApi";
// import { useSelector, useDispatch } from "react-redux";
// import { updateCurrentQuestion, addQuestions } from "../../../Redux/slices/QPSlice";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function QuestionsList() {


  const [timestamp, setTimestamp] = useState(null);
  const [allQuestions, setAllQuestions] = useState([]);
  const [message, setMessage] = useState("Loading");

  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const {  paper_id } = useParams();
  let query = useQuery();

  const [filters, setFilters] = useState(null);


  const tagsBgColor = ["#E3DAEB", "#FFD6B0", "#DAF3FA", "#B0E4DD"];
  
  useEffect(() => {
    const options = {
      limit: 200,
      paperId: paper_id,
    }

    getPaperById(paper_id).then((response)=>{

    getQuestions(response.exam_id, options).then(questions => {
      setAllQuestions(questions.results)
      if(questions.results.length ==0){
        setMessage("Questions not available")
      }
    })
  })
  
  }, [])

  const openSolution = (id, index) => {
    navigate(`/papers/${paper_id}/question-details/${id}`)
  }

  return (
    <div className="quespaper-page">
      <div className="heading-info">
        <div className="title-time">
          <h3>Questions List</h3>
            {/* <p className="time">Last update on {timestamp}</p> */}
          
        </div>
        <div className="filter-addButton">
         
        </div>
      </div>
      {allQuestions.length !=0 ?
      <div className="question-list-container">
        {allQuestions.map((question, index) => (
          <div key={question.id} className="question-container">
            <div className="content">
              <div className="tags">
                {question.related_topics.map((topic, index) => (
                  <span
                    key={index}
                    style={{
                      backgroundColor: tagsBgColor[index % tagsBgColor.length],
                      padding: "0.2rem 0.8rem",
                      borderRadius: "4px",
                    }}
                  >
                    {topic.topic_name}
                  </span>
                ))}
              </div>
              <div className="question">
                <RichTextEditor setContent= {"any"} placeholder={""} preview={true} content={question.question_text.substring(0,200)} />
              </div>
            </div>
            <div
              className="view-button"
              onClick={() => openSolution(question.id, index)}
            >
              View 
            </div>
          </div>
        ))}
        
      </div>
      :<div>
        <h3>{message}</h3>
      </div>}
    </div>
  );


}

export default QuestionsList;
