import * as React from 'react';
import { useContext } from 'react';
import { GlobalDispatchContext, GlobalStateContext } from '../../../../GlobalContext/GlobalContextProvider';
import { ACTIONS } from '../../../../GlobalContext/GlobalReducer';
import './delete-side-card.scss';
import { MASTER_BASE_URL } from '../../../API/FetchMaster';
import { Button } from '../../../../components/Generic/Button/Button';

export interface IProfileDetailsSideCardProps {
    gate: any;
    open: boolean;
    close: ()=>void;
    confirm: ()=>void;
    updating: boolean
    block: boolean;
}



export const  DeleteSideCard = (props: IProfileDetailsSideCardProps) => {
    const [show, setShow] = React.useState(false);
    const globalDispatcher = useContext(GlobalDispatchContext);
    const {globalState:{permissions}} = useContext(GlobalStateContext);

    React.useEffect(()=>{
        
        if(props.open) setShow(true);
        else setShow(false);
    },[props.open]);

  return (
    <div>{show&&
      <div className={'delete-pop-up-container'}>
          <div className={'close-btn'}>
              <img
                src={'/images/notifications/close.svg'}
                alt={'close.svg'}
                onClick={()=>props.close()}
              />
            </div>
          <div className={'pop-up-title'}>
            {` ${props.block ? 'Disable' : 'Enable'}  Gate`}
          </div>
          <div style={{display:'flex'}}>
            <div className={'visitor-details-profile-image'}>
                {<img 
                    src={MASTER_BASE_URL + props.gate.photo || '/images/side_bar/gateblack.svg'}
                />}
            </div>
            <div className={'visitor-details-profile-title'}>
                <div className={'visitor-details-profile-title-fullname'}>{props.gate.name}</div>
                <div className={'visitor-details-profile-title-fullname'}> Gate - [ {props.gate.gate_number} ]</div>
            </div>
          </div>
    
          {[...permissions].includes("GATE_DELETE") &&

          <div className={'entry-exit-button'}>
            <div className={'entry-button'} style={{marginTop:'3rem'}}>
                <Button
                class='medium-btn-container'
                    bgcolor={props.updating ? 'grey' : '#7B0323'}
                    textcolor={'white'}
                    text={ props.updating ? 'Updating' : 'Confirm'}
                    width={'10rem'}
                    onClick={()=>{ !props.updating && props.confirm()}}
                />
            </div>
          </div>}
          
      </div>
      
      }
      </div>)
}
