
import { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import {
  MdCoPresent,MdEdit
} from "react-icons/md";
import "./QuestionViewer.scss";
import { HiOutlineLightBulb } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { getQuestionById } from "../../../../../../APIServices/QP/QuestionPaper";
import RichTextEditor from "../../../../../../../components/TextEditor/RichTextEditor";
import { getPaperById } from "../../../../../../APIServices/QP/PapersApi";




function QuestionViewer() {

  const navigate = useNavigate()

  const [paper, setPaper] = useState({exam_id:null})


  const [currentQuestion, setCurrentQuestion] = useState(
    {
      "id": 7,
      "question_text": "physics question one",
      "paper_id": 2,
      "hint": null,
      "solution": "solution",
      "difficulty_level": null,
      explanation_videos: [

      ],
      related_tags: [

      ],
      related_topics: [
      ]
    }
  );

  const { paper_id, question_id } = useParams();



  useEffect(() => {
    getQuestionById(paper.exam_id, question_id, {}).then(res => setCurrentQuestion(res))
  }, [paper])

  useEffect(() => {
    getPaperById(paper_id).then((response)=>setPaper(response))
  }, [])





  return (
    <div className="question-viewer">
      <div className="header">
        <div className="back" onClick={() => navigate(-1)}>
          <IoIosArrowBack /> <span>Back</span>
        </div>
        <div className="back" onClick={() => navigate(`/papers/${paper_id}/modify-question/${question_id}`)}>
          <MdEdit /> <span>Edit</span>
        </div>
      </div>
      <div className="questionViewer-container">
        {/* Tags sections */}
        {/* <div className="tags-section">
          <div className="accordion">
            <div className="tags-title">
              Tags
            </div>
            <div className="explanation">
              {currentQuestion.related_tags?.map((tag, index) => (
                <span key={index} >
                  {tag.name}

                </span>
              ))}
            </div>
          </div>
        </div> */}


          {/* Topics secrion */}
          <div className="topics-section">
          <div className="accordion">
            <div className="topic-title" >
              Topics
            </div>
            <div className="explanation">
              {currentQuestion.related_topics?.map((topic, index) => (
                <span >
                  {topic.topic_name}
                </span>
              ))}
            </div>
          </div>
        </div>

        {/* Questions sections */}
        <div className="hint-explanation-section">
          <div className="accordion">
            <div className="hints-title" >
              <HiOutlineLightBulb /> Question
            </div>

            <div className="accordion">
              <div className="hints-title" >
                Question
              </div>
              <RichTextEditor
                content={currentQuestion.question_text ? currentQuestion.question_text : ""}
                preview={true}
                setContent={""} placeholder={undefined} />
            </div>

          </div>

        </div>


        {/* hint sections */}
        <div className="hint-explanation-section">
          <div className="accordion">
            <div className="hints-title" >
              <HiOutlineLightBulb /> Hint for the Solution
            </div>

            <RichTextEditor
              content={currentQuestion.hint ? currentQuestion.hint : ""}
              preview={true} setContent={undefined} placeholder={undefined}
            />

          </div>
        </div>

        {/* explaination sections */}

        <div className="hint-explanation-section">

          <div className="accordion">
            <div className="hints-title" >
              <MdCoPresent /> Explanation
            </div>


            <RichTextEditor
              content={currentQuestion.solution ? currentQuestion.solution : ""}
              preview={true} setContent={undefined} placeholder={undefined} />

          </div>
        </div>


        {/* video explaination
        <div className="video-explanation">
          <div className="accordion">
            <div className="video-title">
              Video Explanation
            </div>

            <div className="explanation">
              {currentQuestion.explanation_videos?.map((v, idx) => (
                <div key={idx} className="video">
                  <video src={v} ></video>
                  <FaPlay onClick={() => window.open(v, "_blank")} />
                </div>
              ))}
            </div>

          </div>
        </div> */}

      


      </div>
    </div>
  );
}

export default QuestionViewer;
