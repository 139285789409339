
import React, { useEffect, useRef, useState } from "react";
import { FaPlay, FaRegEdit } from "react-icons/fa";
import { IoIosArrowBack, IoIosRemoveCircle } from "react-icons/io";
import {
  MdCancel,
  MdCoPresent,

} from "react-icons/md";
import "./AddQuestion.scss";
import { HiOutlineLightBulb } from "react-icons/hi";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { BiPlus } from "react-icons/bi";
import { TiDelete } from "react-icons/ti";
import RichTextEditor from "../../../components/TextEditor/RichTextEditor";

import { toast } from "sonner";
import { addNewQuestion, getTags } from "../../APIServices/QP/QuestionPaper";
import { getPaperById } from "../../APIServices/QP/PapersApi";
import { getTopics } from "../../APIServices/QP/ExamPlans";
import { Button } from "../../../components/Generic/Button/Button";
import { AddSuccess } from "../../../components/SuccessComponent/AddSuccess";




function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const defaultQuestion = {
  no_of_times_appeared: 1,
  question_text: "", hint: "", related_tags: [], videoExplanation: [], related_topics: [], solution: "",   
   paper_id : 1
}


function AddBlog({showVisitorListPage}) {

  const[pageVar, setPageVar] = useState({
    successMessage: "Added sucessfully",
    open: false
  })

  const { paper_id } = useParams();

  // const [edit, setEdit] = useState(true);

  const [subjectPaper, setSubjectPaper] = useState({exam_id: 0, id: 0, subject_id: 0});


  const [allTopics, setAllTopics] = useState([]);


  const [question, setQuestion] = useState(defaultQuestion);


  const uploadQuestion = () => {

    if(question.question_text == "" || question.hint == ""  || question.solution == ""  ){
      toast.info("Added question and details")
      return
    }
   
    addNewQuestion(subjectPaper.exam_id, question).then(res => {
      console.log(res)
      setQuestion(defaultQuestion)
      window.scrollTo(0,0);
      setPageVar({...pageVar, open: true})
    })


  }

 





  const addTopic = (topic) => {
    setQuestion({

      ...question,
      related_topics: [...question.related_topics, topic]
    }
    )
  };

  const removeTopic = (topicToRemove) => {
    const new_topics = question.related_topics.filter(topic => topic != topicToRemove)
    setQuestion({

      ...question,
      related_topics: [...new_topics]
    })
  };


  const updateQuestion = (content) => {
    if (content != question.question_text) {
      setQuestion({
        ...question,
        question_text: content
      })
    }

  };



 



  useEffect(() => {
    getPaperById(paper_id).then(res => setSubjectPaper(res))

  }, [])

  useEffect(() => {
    console.log(subjectPaper)
    getTopics(subjectPaper.subject_id).then(res => {
      setAllTopics([
        {
          "id": 12,
          "topic_name": "IIT",
          "topic_image": "https://www.freepik.com/free-vector/classic-variety-colorful-molecules_1312422.htm#fromView=search&page=1&position=0&uuid=1bd513df-3e4b-42df-a61e-c1d9e8f607b9",
          "is_unlocked": true,
          "subject_id": 2,
          "total_questions": 2,
          "solutions_added": 2
      },
      {
        "id": 12,
        "topic_name": "NEET",
        "topic_image": "https://www.freepik.com/free-vector/classic-variety-colorful-molecules_1312422.htm#fromView=search&page=1&position=0&uuid=1bd513df-3e4b-42df-a61e-c1d9e8f607b9",
        "is_unlocked": true,
        "subject_id": 2,
        "total_questions": 2,
        "solutions_added": 2
    },
      ])
    })
    setQuestion({ ...question, paper_id: parseInt(paper_id)})


  }, [subjectPaper])

  return (
    <div className="question-viewer">
   



      <div className="questionViewer-container">

       

          {/* Topics secrion */}
          <div className="topics-section">
          <div className="accordion">
            <div className="topic-title" >
              Tags
            </div>
            <div className="explanation">
              {question.related_topics.map((topic, index) => (
                <span key={index} onClick={() => removeTopic(topic)}>
                  {topic.topic_name} <MdCancel />
                </span>
              ))}

              {
                allTopics?.filter((topic) => !question.related_topics.includes(topic))
                  ?.map((topic) => (
                    <div className="all-tags">
                      <span
                        className="unselected"
                        onClick={() => addTopic(topic)}
                      >
                      {topic.topic_name} <BiPlus />
                      </span>
                    </div>
                  ))
              }
            </div>
          </div>
        </div>

        {/* Questions sections */}
        <div className="hint-explanation-section">
          <div className="accordion">
            <div className="hints-title" >
              <HiOutlineLightBulb /> Question
            </div>
            <RichTextEditor
                content={question.question_text ? question.question_text : ""}
                preview={false}
                setContent={updateQuestion}
                placeholder ="" 
              />
          </div>

        </div>



      

        <Button
            class='medium-btn-container'
            text={'Upload Question'}
            textcolor={'dark-green'}
            bgcolor={'#09784433'}
            width={'fit-content'}
            onClick={uploadQuestion}
          />
      </div>

      <AddSuccess open={pageVar.open} message={pageVar.successMessage} close={()=> setPageVar({...pageVar, open:false})}/>
    </div>
  );
}

export default AddBlog;
