import * as Congs from "../Configurations"



export function addTopic(subject_id, payload) {
    const requestOptions = {
      method: "POST",
      redirect: "follow",
      body: JSON.stringify(payload),
      headers: Congs.getHeaders()
  
    };
  
    
    return fetch(`${Congs.BASE_URL}/api/v2/subjects/${subject_id}/topics`, requestOptions)
      .then((response) => response)
      .catch((error) => console.error(error));
  }
  

  export function updateTopic(subject_id,topic_id,  payload) {
    const requestOptions = {
      method: "PUT",
      redirect: "follow",
      body: JSON.stringify(payload),
      headers: Congs.getHeaders()
  
    };
  
    
    return fetch(`${Congs.BASE_URL}/api/v2/subjects/${subject_id}/topics/${topic_id}`, requestOptions)
      .then((response) => response)
      .catch((error) => console.error(error));
  }
  
   
  export function getTopicById(subject_id,topic_id) {

    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: Congs.getHeaders()
  
    };
  
   
    return fetch(`${Congs.BASE_URL}/api/v2/subjects/${subject_id}/topics/${topic_id}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.error(error));
  
  }


export function getTopics(subjectId, page = 1, limit = 200) {

    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: Congs.getHeaders()
  
    };
  
    
    return fetch(`${Congs.BASE_URL}/api/v2/subjects/${subjectId}/topics?page=${page}&limit=${limit}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.error(error));
  
  }
  

  
  
