import * as React from 'react';
import { useState } from 'react';

import './add-access.scss';
import {toast} from 'sonner';
import { addGateCred, addNewCred } from '../../../../GPI/API/Gates';
import { useContext } from 'react';
import { GlobalStateContext } from '../../../../GlobalContext/GlobalContextProvider';
import { Switch } from 'antd';
import Dropdown from '../../../../components/Generic/Dropdown/Dropdown';
import TextInput from '../../../../components/Generic/TextInput/TextInput';
import { Button } from '../../../../components/Generic/Button/Button';
import { addAdminUser, getAdminUserRoles, getAdminUsers } from '../../../APIServices/UsersApi';

export interface IAddNewCredentialProps {
}

export const AddNewCredential = (props: IAddNewCredentialProps) => {
    const {globalState:{user, gates}} = useContext(GlobalStateContext);

    const form = {
        role: '',
        name: '',
        phone: '',
        email: '',
        password: '',
        exam_preference : '',
        gender: '',
        is_enabled: true,
      };
    const [formData, setFormData] = React.useState(form);
    const [invalidFields, setInvalidFields] = useState([]);
    const [roles, setRoles] = useState([]);
    const [submitClicked, setSubmitClicked] = useState(false);

    const validateFields = React.useCallback(async (valid: boolean, field: string) => {

        if(valid) {
            if(invalidFields.includes(field))
            setInvalidFields(Fields => { 
                let filtered = Fields.filter( value => value !== field );
                return filtered;
            });
        }
        else {
            if(!invalidFields.includes(field))
                setInvalidFields(Fields => { 
                    Fields.push(field);
                    return Fields;
                });
        }
      },[]);

      const submitForm = async () => {
        try {
        //   if(invalidFields.length) {
        //     toast.warning('Please fill all the required fields!');
        //     setSubmitClicked(true);
        //     return;
        // }
          const payload = {
            name: formData.name,
            phone: formData.phone,
            email: formData.email,
            password: formData.password,
            role: formData.role,
            exam_preference : '',
            gender: '',
          }
          const response = await addAdminUser(payload);
          
          if (response && response.status ==200) {
            toast.success("Successfully added")
            setFormData(form);
          }
          else throw new Error("Failed to added");
          
        }
        catch(err) {
          toast.error(err.message)
        }
      }

   React.useEffect(()=>{
    const temp = []
    
    getAdminUserRoles(null).then(roles => {
      roles.map(role=> temp.push(role.role))
      setRoles(temp)
    })
    

   },[])

  return (
    <div className={'new-access-form-container'}>
        <div className={'title-left'}>
            Add User Credential
        </div>
        <div style={{marginTop:'2rem', display:'flex', gap:'2rem'}}>
            <Dropdown
                label={'Select Role'}
                placeholder={'Select Role'}
                width={'30%'}
                options={roles}
                onSelect={(value)=>setFormData({...formData, role: value})}
                selection={formData.role}
                isRequired={true}
                isValid={validateFields}
                showWarning={submitClicked}
            />
 
            
        </div>
        <div style={{marginTop:'2rem', display:'flex', gap:'2rem'}}>
            <TextInput
                label={'User fullname'}
                placeholder={'Enter fullname'}
                width={'30%'}
                isRequired={true}
                isValid={validateFields}
                showWarning={submitClicked}
                onChange={(value) => setFormData({...formData, name: value})}
                value={formData.name}
            />
            <TextInput
                label={'Contact number'}
                placeholder={'Enter contact number'}
                width={'30%'}
                isRequired={true}
                isValid={validateFields}
                showWarning={submitClicked}
                onChange={(value) => setFormData({...formData, phone: value})}
                value={formData.phone}
                maxLength={10}
                isNumber={true}
                />
          
        </div>

        <div style={{marginTop:'2rem', display:'flex', gap:'2rem'}}>
            <TextInput
                label={'emailId'}
                placeholder={'Enter emailId'}
                width={'30%'}
                isRequired={true}
                isValid={validateFields}
                showWarning={submitClicked}
                onChange={(value) => setFormData({...formData, email: value})}
                value={formData.email}
            />
            <TextInput
                label={'Password'}
                placeholder={'Enter password'}
                width={'30%'}
                isRequired={true}
                isValid={validateFields}
                showWarning={submitClicked}
                onChange={(value) => setFormData({...formData, password: value})}
                value={formData.password}
            />
          
        </div>

        <div>
              <Switch
                style={{ margin: '1rem' }}
                value={formData.is_enabled}
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    is_enabled: value
                  });
                }}
              />
              Enable Notifications
        </div>
        <div style={{justifyContent:'center', display:'flex', margin:'2rem 2rem 0 30rem'}}>
            <Button
            class='btn-container'
                bgcolor={'#4D4D33'}
                textcolor={'white'}
                text={'Add User'}
                width={'fit-content'}
                onClick={submitForm}
            />
        </div>
    </div>
  );
}
