import * as React from 'react';
import { useContext } from 'react';
import { GlobalDispatchContext } from '../../GlobalContext/GlobalContextProvider';
import { Button } from '../../components/Generic/Button/Button';
import {ACTIONS} from '../../GlobalContext/GlobalReducer';
import './login.scss';
import { useState } from 'react';
import {toast} from 'sonner';
import { useEffect } from 'react';
import { loginUser } from '../APIServices/LoginRegistrationApi';

export enum LOCAL_STORAGE_KEYS {
  user = 'user',
  permissions = 'permissions',
  is_logged = 'is_logged',
  stored_token = 'stored_token',
  notification_count = 'notification_count'
}

export interface ILoginProps {
}

export const  Login = (props: ILoginProps) => {
  const globalDispatcher = useContext(GlobalDispatchContext);
  const [loginVars, setLoginVars] = useState({
    username: '',
    password: ''
  })

  const logIn = async () => {
    try {
      if(!loginVars.username || !loginVars.password) {
        toast.warning("Please enter username & password!");
        return;
      }
      const response = await loginUser(loginVars);
      if(response && response.statusCode === 200 && response.response && response.response?.token) {
        toast.success("Login success!");
        
        localStorage.setItem(LOCAL_STORAGE_KEYS.is_logged, 'true');
        localStorage.setItem(LOCAL_STORAGE_KEYS.user, JSON.stringify(response.response));
        localStorage.setItem(LOCAL_STORAGE_KEYS.permissions, JSON.stringify(response.response?.permissions));
        localStorage.setItem(LOCAL_STORAGE_KEYS.stored_token, response.response.token);
        
        globalDispatcher({type: ACTIONS.setLoggedInUser, payload: response.response});
        globalDispatcher({type: ACTIONS.setPermissions, payload: response.response?.permissions });
        globalDispatcher({type: ACTIONS.setLoggedIn, booleanPayload: true});


      }
      else throw new Error("Error");
      
    }
    catch(err) {
      toast.error("Invalid username/password");
    }
  } 

  useEffect(()=>{
    let input = document.getElementById("password-enter");
    input.addEventListener("keypress", function(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        document.getElementById("login-button").click();
      }
    });
  },[]);

  return (
    <div className={'login-container'}>
      <div className={'login-banner-section'}>
        <img
            className={'pink-smoke'}
            src={'/images/login/pinksmoke.svg'}
            alt={'pink-smoke'}
          />
      </div>
      <div className={'login-form-section'}>
          {/* <img
            className={'green-smoke'}
            src={'/images/login/greensmoke.svg'}
            alt={'green-smoke'}
          /> */}
          <div className={'login-content-section'}>
          <div>
              <div style={{fontWeight:'bold', fontSize:'1.5rem'}}>Log In</div>
              <div style={{padding:'1rem 2rem 1rem 0', borderBottom:'0.01rem green solid', width:'fit-content'}}>Securely access your account</div>
          </div>
          <div className={'login-input-box'}>
              <div className={'login'}>
                  Username
              </div>
              <div className={'login-font-input'}>
                <div className={'input-icon'}>
                    <img
                        src={'/images/login/profileLogo.svg'}
                    />
                </div> 
                <div className={'line-bar'}>
                </div> 
                <div>
                    <input 
                      type={'text'} 
                      placeholder={'Enter username'}
                      onChange={(event) => setLoginVars({...loginVars, username: event.target.value})}
                    />
                </div> 
              </div>
          </div>
          <div className={'login-input-box'}>
            <div className={'pass'}>
                  Password
            </div>
            <div className={'login-font-input'}>
                <div className={'input-icon'}>
                    <img
                        src={'/images/login/ph_key.svg'}
                    />
                </div> 
                <div className={'line-bar'}>
                </div> 
                <div>
                    <input type={'password'} 
                      placeholder={'Enter password'} 
                      onChange={(event) => setLoginVars({...loginVars, password: event.target.value})}
                      id={'password-enter'}
                    />
                </div> 
            </div>
          </div>
          </div>
          <div className={'login-button'}>
              <Button
                class='medium-btn-container'
                bgcolor={'#4D4D33'}
                text={'Login'}
                textcolor={'white'}
                width={'6rem'}
                onClick={logIn}
                id={'login-button'}
              />
          </div>
      </div>
    </div>
  );
}
