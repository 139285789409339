import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  MdCoPresent,MdEdit
} from "react-icons/md";
import "./TopicwiseDetails.scss"
import ModifyTopic from "./EditTopic/ModifyTopic";
import AddTopic from "./AddTopic/AddTopic";
import { Button } from "../../../../../../components/Generic/Button/Button";
import { getSubjects } from "../../../../../APIServices/QP/Subjects";
import { getTopics } from "../../../../../APIServices/QP/Topics";


export function TopicwiseDetails() {

  const form = {
    id: null,
    exam_id: 0,
    subject_id: 0,
    topic_id: 0,

    showModifyPopup: false,
    showAddPopup: false,
  }



  const [formData, setFormData] = useState<any>(form);

  const { exam_id } = useParams();

  const [subjects, setSubjects] = useState([]);



  const [topics, setTopics] = useState([]);



 

  useEffect(() => {
    getTopics(formData.subject_id).then((examtopics) => setTopics(examtopics.topics))

  }, [formData.subject_id])

  useEffect(() => {
    getTopics(formData.subject_id).then((examtopics) => setTopics(examtopics.results))
    getSubjects(exam_id, null).then(resp => setSubjects(resp.results))

  }, [])




  useEffect(() => {
    getTopics(formData.subject_id).then((examtopics) => setTopics(examtopics.results))

  }, [formData.subject_id, formData.showAddPopup, formData.showModifyPopup])


  useEffect(() => {
    if (subjects?.length > 0) {
      setFormData({...formData, subject_id: subjects[0].id})
    }
  }, [subjects])


  return (

    <div className="topics-container">

    <div className={'visitors-list-header'}>
            <div className={'visitors-list-header-left'}>
              <div className={'title'}>
                Topics List
              </div>
              <div style={{ display: 'flex', marginRight: '2rem' }}>
              
                <Button
                    class='btn-container'
                    bgcolor={'#02720d'}
                    text={'Add Topic'}
                    textcolor={'white'}
                    width={''}
                    fontSize={'1rem'}

                    onClick={() => setFormData({ ...formData, showModifyPopup: false, showAddPopup: true })}
                  />
              </div>
          
            </div>
        
    </div>



      <div className="subject-filter">
        <span>Subjects
          :</span>
        <div className="programs
                  ">
          {subjects?.map((subject) => (
            <span
              key={subject.title}
              className={`subject-tag ${formData.subject_id === subject.id ? "active" : ""
                }`}
              onClick={() => setFormData({ ...formData,subject_id: subject.id})}
            >
              {subject.title} 
            </span>
          ))}
        </div>
      </div>

     

      <div className="topicContainer">
        <ul>
          {topics?.map((topic, index) => (
              <li key={index}>
                <div className="band"></div>
                <div className="topic-name">
                  <p>{topic.topic_name}</p>
                  <MdEdit onClick={()=>setFormData({ ...formData, showModifyPopup: true, topic_id: topic.id })}/>
                </div>
              </li>
          ))}
        </ul>

       
      {formData.showModifyPopup && (
        <ModifyTopic
          setShowModifyPopup={() => setFormData({ ...formData, showModifyPopup: false })}
          topic_id={formData.topic_id}
          examId={exam_id}
          subject_id={formData.subject_id}
        />
      )}

      {formData.showAddPopup && (
        <AddTopic
          setShowAddPopup={() => setFormData({ ...formData, showModifyPopup: false, showAddPopup: false })}
          examId={exam_id}
        />
      )}


          
      </div>
    </div>
  );
}