import * as React from 'react';
import { useState } from 'react';
import './visitor-management.scss';
import { useContext } from 'react';
import { GlobalStateContext } from '../../GlobalContext/GlobalContextProvider';
import CompanyComponent from '../../components/Generic/CompanyLogo/CompanyComponents';
import BlogsList from './BlogsList/BlogsList';
import AddBlog from './AddQuestion/AddBlog';

export interface IVisitorManagementProps {
}

export function BlogsManagement (props: IVisitorManagementProps) {
  const {globalState:{permissions}} = useContext(GlobalStateContext);
  

const sidePanelRoutes = [
  {
    id: '#1',
    title:'Candidates list',
    visible: true,
    component: <BlogsList />,
    accessPermission: 'VISITOR_ALL',
  },
  {
    id: '#2',
    title:'+ Add new candidate',
    visible: true,
    component: <AddBlog showVisitorListPage={()=>setPageVariables({...pageVariables, selectedRoute:  sidePanelRoutes[0]})}/>,
    accessPermission: 'VISITOR_ADD', 
  }
];

const [pageVariables, setPageVariables] = useState({
  selectedRoute: sidePanelRoutes[0]
})

  React.useEffect(()=>{
    document.title='Visitors'
  },[]);
  
  return (
    <div className={'visitor-management-container'}>
      <div className={'visitor-side-panel-container'}>
        <div className={'visitor-side-panel-routes-container'}>
          <div>        {
            sidePanelRoutes.map((route, index) => {
              return ( (route.visible && [...permissions].includes(route.accessPermission)) &&
              <div 
                className={`visitor-side-panel-route-box 
                  ${pageVariables.selectedRoute.id === route.id ? 
                    'selected-visitor-side-panel-route-box' : ''}`}
                onClick={()=>setPageVariables({...pageVariables, selectedRoute: route})}
              >
                <div 
                  className={pageVariables.selectedRoute.id === route.id ? 'selected-side-route' : 'not-selected-side-route'}
                >
                </div>
                <div className={pageVariables.selectedRoute.id === route.id ? 'selected-visitor-side-panel-route' : 'visitor-side-panel-route'}>
                  {route.title}
                </div>
              </div>)
            })
          }
          </div>
  

        </div>
        <div className={'critical-ai-log-bottom'}>
           <CompanyComponent/>
        </div>
      </div>
      <div className={'visitor-content-container'}>
        {pageVariables.selectedRoute.component}
      </div>
    </div>
  );
}
