import * as React from 'react';
import { useContext } from 'react';
import { GlobalStateContext } from '../../../../GlobalContext/GlobalContextProvider';
import { Button } from '../../../../components/Generic/Button/Button';
import './block-visitor.scss';
import { MASTER_BASE_URL } from '../../../../GPI/API/FetchMaster';

export interface IProfileDetailsSideCardProps {
    visitor: any;
    open: boolean;
    close: ()=>void;
    confirm: ()=>void;
    updating: boolean;
    block: boolean;
}

//profile object
{/*
    "visitor_id": visitor_id,
    "fullname" : fullname,
    "phone_number" : phone_number,
    "gender" : gender,
    "unit" : unit,
    "category" :  category,
    "vehicle_number" : vehicle_number,
    "View_details" :  'View Details',
    "is_primary": is_primary,
    "profile_image": profile_image,
    "id_card": id_card
*/}

export const  BlockVisitorCard = (props: IProfileDetailsSideCardProps) => {
    const [show, setShow] = React.useState(false);
    const {globalState:{permissions}} = useContext(GlobalStateContext);

    React.useEffect(()=>{
        
        if(props.open) setShow(true);
        else setShow(false);
    },[props.open]);

  return (
    <div>{show&&
      <div className={'delete-pop-up-container'}>
          <div className={'close-btn'}>
              <img
                src={'/images/notifications/close.svg'}
                alt={'close.svg'}
                onClick={()=>props.close()}
              />
            </div>
          <div className={'pop-up-title'}>
            {`${props.block ? 'Block' : 'Unblock'} Visitor`}
          </div>
          <div style={{display:'flex'}}>
            <div className={'visitor-details-profile-image'}>
                {<img 
                    src={MASTER_BASE_URL +props.visitor.profile_image }
                />}
            </div>
            <div className={'visitor-details-profile-title'}>
                <div className={'visitor-details-profile-title-fullname'}>{props.visitor.name}</div>
                <div className={'visitor-details-profile-title-fullname'}>  {props.visitor.fullname} </div>
            </div>
          </div>
            
          {/* <div className={'pop-up-overview'}>
            <div className={'pop-up-overview-message'}>
                <p>Details:</p>
                <p>{'No. of passengers : 1'}</p>
                <p>{'Date & Time : '+ props.profile[2]}</p>
            </div>
            <div className={'pop-up-details'}>
                <span>Approved by:</span>
                <span>{props.profile[0]}</span>
            </div>
            <div className={'pop-up-details'}>
                <span>Remarks:</span>
                <span>{props.profile[1]}</span>
            </div>
          </div> */}
          {[...permissions].includes("VISITOR_ADD") &&

          <div className={'entry-exit-button'}>
            <div className={'entry-button'} style={{marginTop:'3rem'}}>
                <Button
                class='medium-btn-container'
                    bgcolor={props.updating ? '#4D4D33' : '#7B0323'}
                    textcolor={'white'}
                    text={ props.updating ? 'Updating' : 'Confirm'}
                    width={'10rem'}
                    onClick={()=> {!props.updating && props.confirm()}}
                />
            </div>
          </div>}
          
      </div>
      
      }
      </div>)
}
